import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { GoBack, SectionToggle } from "../../components";
import { MyLink } from "../../../../../components";
import Loading from "../../../../../components/Loading/Loading";
import { images } from "../../constants";
import "./Community.css";

const Community = () => {
  const [genderFilter, setGenderFilter] = useState("all");
  const [classFilter, setClassFilter] = useState("all");
  const [studentMembers, setStudentMembers] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);
  const [uniqueClassCombos, setUniqueClassCombos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const db = getFirestore();

        // 1. Fetch Students
        const studentSnapshot = await getDocs(collection(db, "students"));
        const rawStudents = studentSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Filter out exmatriculated + missing class + missing image
        const filtered = rawStudents.filter((s) => {
          if (s.grade === "Exmatriculated") return false;
          if (!s.current_class) return false;
          if (!s.image2 && !s.img) return false;
          return true;
        });

        filtered.sort((a, b) => a.name.localeCompare(b.name));

        // Build unique combos (grade-year)
        const combos = Array.from(
          new Set(
            filtered
              .filter((s) => s.grade && s.current_year)
              .map((s) => `${s.grade}-${s.current_year}`)
          )
        );
        setStudentMembers(filtered);
        setUniqueClassCombos(combos);

        // 2. Fetch Staff
        const staffSnap = await getDocs(collection(db, "staff"));
        const staffData = staffSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const subsSnap = await getDocs(collection(db, "subjects"));
        const subsData = subsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // teacherId => [subjects...]
        const teacherSubjectsMap = {};
        subsData.forEach((subject) => {
          if (subject.teacherId) {
            if (!teacherSubjectsMap[subject.teacherId]) {
              teacherSubjectsMap[subject.teacherId] = [];
            }
            teacherSubjectsMap[subject.teacherId].push(
              subject.name || "Unnamed Subject"
            );
          }
        });

        // Attach subjects to staff
        const staffWithSubs = staffData
          .filter((st) => teacherSubjectsMap[st.email])
          .map((st) => ({
            ...st,
            subjects: teacherSubjectsMap[st.email],
          }));

        // Sort by # of subjects
        staffWithSubs.sort((a, b) => b.subjects.length - a.subjects.length);
        setStaffMembers(staffWithSubs);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // <--- done loading
      }
    };

    fetchAllData();
  }, []);
  const renderMember = (member, isStaff = false) => {
    // Gender filter for students
    if (!isStaff && genderFilter !== "all" && member.gender !== genderFilter) {
      return null;
    }

    // Class filter
    if (!isStaff && classFilter !== "all") {
      const combo = `${member.grade || ""}-${member.current_year || ""}`;
      if (combo !== classFilter) {
        return null;
      }
    }

    const firstName = member.name?.split(" ")[0]?.toLowerCase();
    return (
      <div className="column" key={member.id}>
        <div className="card" id={firstName}>
          <img
            src={member.image2 || member.img || images.noImage}
            alt={member.name}
            style={{ width: "100%" }}
          />
          <div className="container">
            <h2>{member.name}</h2>
            <p className="title">{member.description_1}</p>

            {/* Staff subjects */}
            {isStaff && member.subjects?.length > 0 && (
              <>
                <br />
                <p className="title">
                  <u>{member.name.split(" ")[0]} teaches</u> <br />
                  {member.subjects.join(", ")}
                </p>
              </>
            )}

            {/* Team role */}
            {member.role === "Team" && (
              <p className="title">
                {member.description_1}
                <br />
                {member.description_2 || ""}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="community" id="page-container">
      <div className="bodyMargin left content">
        <GoBack />
        <h2>Community</h2>
        <p>
          We are a school of great minds, changing the way education has been
          perceived for years in Kibera and in Kenya.
        </p>

        <SectionToggle
          sections={[
            { id: "students", label: "Students" },
            { id: "staff", label: "Staff" },
          ]}
        >
          {(activeSection) => (
            <>
              {activeSection === "students" && (
                <div className="article blueBackground">
                  <h3>Students</h3>

                  <div className="filterRow">
                    <label htmlFor="classSelect">Show students in: </label>
                    <select
                      id="classSelect"
                      value={classFilter}
                      onChange={(e) => setClassFilter(e.target.value)}
                    >
                      <option value="all">All Classes</option>
                      {uniqueClassCombos.map((combo) => {
                        const [grade, year] = combo.split("-");
                        return (
                          <option key={combo} value={combo}>
                            Grade {grade} - {year}
                          </option>
                        );
                      })}
                    </select>{" "}
                    <label htmlFor="genderSelect">Gender: </label>
                    <select
                      id="genderSelect"
                      value={genderFilter}
                      onChange={(e) => setGenderFilter(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="Boy">Boys</option>
                      <option value="Girl">Girls</option>
                    </select>
                    <p className="sponsor-info">
                      <i class="fa-solid fa-child"></i> Get to know our students
                      and read their individual stories{" "}
                      <MyLink to="/sponsorship">
                        <u>HERE</u>
                      </MyLink>
                      .
                    </p>
                  </div>

                  <br />

                  <div className="flex-container">
                    {loading ? (
                      <Loading />
                    ) : (
                      <div className="row2 twenty">
                        {studentMembers.map((member) => renderMember(member))}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {activeSection === "staff" && (
                <div className="article blueBackground">
                  <h3>Staff</h3>
                  <div className="flex-container">
                    <div className="row2 twenty">
                      {staffMembers.map((member) => renderMember(member, true))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </SectionToggle>
      </div>
    </div>
  );
};

export default Community;
