import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import Banner from "../../components/Banner/Banner";
import Feature from "../../components/Feature/Feature";
import SplitView from "../../components/SplitView/SplitView";

import "./index.css";

const HomePage = () => {
  return (
    <div className="school-home">
      <Banner />
      <h2 className="statement inline-group">
        <span>We redefine</span> <span>traditional education.</span>
      </h2>
      <div className="features-section">
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.redefining}
          title="Educational opportunities"
          description="The Watoto Academy is formed under the principle of creating education opportunities for children from very vulnerable families, helping them achieve success and change their lives."
        />
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.community}
          title="Fully sponsored students"
          description="We offer a fully sponsored school to bright students in Kibera, providing mentorship to empower them as responsible citizens and future leaders."
        />
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.meaningful}
          title="Values of future leaders"
          description="Our holistic curriculum nourishes responsibility, integrity, and honesty, focusing on the child's physical, mental, emotional, and spiritual growth."
        />
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.together}
          title="Giving back to community"
          description="We believe in the philosophy of Ubuntu: 'I am because we are.' Students participate in community service, including weekly river cleanups."
        />
      </div>

      <div className="bodyMargin paragraph">
        <p>Only 15% of Kibera’s children are able to attend high school.</p>
        <p className="admitted">
          <b>
            The Watoto Academy has in <u className="twl-color">2024</u> admitted{" "}
            <u className="twl-color">20 children</u> aged 14 years to join Grade
            7,
          </b>
          <br />
          <b>
            and in <u className="twl-color">2025</u> admitted another{" "}
            <u className="twl-color">12 children</u> aged 14 years,
          </b>
        </p>
        <p>where they will receive modern, high-quality education.</p>
        <br />
        <br />

        <img src={images.classPhoto_7_2025} alt="" className="img" />

        <div className="initiative-container">
          <p className="initiative">
            Our initiative targets families lacking resources for better
            education but eager to learn and use that knowledge to serve their
            community.
          </p>
          <img
            src={images.TWLlogo}
            alt="Initiative Logo"
            className="initiative-logo"
          />
        </div>
      </div>

      <h2>How do we do it?</h2>
      <div className="features-section how">
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.fees}
          title="Conducive environment"
          description="We cap classes at 20 students and dedicate 40% of lessons to group work."
        />
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.materials}
          title="Fully sponsored"
          description="Students pay no fees and receive uniforms, books, and materials at no cost."
        />
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.food}
          title="Two meals a day"
          description="We provide two nutritious meals daily so students can focus on learning."
        />
        <Feature
          iconClassName="fa-regular fa-star"
          image={images.curriculum}
          title="Holistic curriculum"
          description="We teach real-life skills to empower students to create a better future."
        />
      </div>

      <SplitView />
    </div>
  );
};

export default HomePage;
