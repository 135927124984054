import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Navbar, Footer, MyLink, Loading } from "../../../components";
import { images } from "../../../constants";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAbout } from "./AboutContext"; // Context for about data
import { db } from "../../../firebase";

const About = ({ isAdmin }) => {
  const { aboutItems, aboutLoading, addAbout } = useAbout();
  const [newAboutUrl, setNewAboutUrl] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [orderLoading, setOrderLoading] = useState(true);
  const [showOrderPopup, setShowOrderPopup] = useState(false);

  // Fetch the saved order from Firestore
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderDocRef = doc(db, "settings", "aboutOrder");
        const orderDoc = await getDoc(orderDocRef);
        if (orderDoc.exists()) {
          setOrderList(orderDoc.data().order || []);
        } else {
          const initialOrder = aboutItems.map((item) => item.url);
          setOrderList(initialOrder);
          await setDoc(orderDocRef, { order: initialOrder });
        }
      } catch (error) {
        console.error("Error fetching order list:", error);
      } finally {
        setOrderLoading(false);
      }
    };
    fetchOrder();
  }, [aboutItems]);

  const updateOrderInFirebase = async (newOrder) => {
    try {
      const orderDocRef = doc(db, "settings", "aboutOrder");
      await setDoc(orderDocRef, { order: newOrder });
    } catch (error) {
      console.error("Error updating order list:", error);
    }
  };

  const moveItem = (index, direction) => {
    const newOrder = [...orderList];
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    [newOrder[index], newOrder[targetIndex]] = [
      newOrder[targetIndex],
      newOrder[index],
    ];
    setOrderList(newOrder);
    updateOrderInFirebase(newOrder);
  };

  const handleCreateAbout = () => {
    const url = prompt(
      "Enter the URL for the new about page.\n \n(thewatotoprogram.org/about/theurl)"
    );
    if (url) {
      setNewAboutUrl(url);
      createNewAbout(url);
    }
  };

  const createNewAbout = async (url) => {
    const newAboutData = {
      title: "New About Title",
      description: "Description of this new about page.",
      status: "Draft",
      img: "",
      url: url,
    };

    try {
      await addAbout(newAboutData);
      // Append new URL to the order list and update Firestore
      const newOrder = [...orderList, url];
      setOrderList(newOrder);
      updateOrderInFirebase(newOrder);
    } catch (error) {
      console.error("Error creating new about entry:", error);
    }
  };

  // Sort about items based on the orderList from Firestore.
  const sortedAboutItems =
    orderList.length > 0
      ? aboutItems
          .slice()
          .sort((a, b) => orderList.indexOf(a.url) - orderList.indexOf(b.url))
      : aboutItems;

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />

      {isAdmin && showOrderPopup && (
        <div
          className="modal-overlay"
          onClick={() => setShowOrderPopup(false)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "400px",
            }}
          >
            <h3>Manage About Pages Order</h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {orderList.map((url, index) => (
                <li
                  key={url}
                  style={{
                    margin: "10px 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{url}</span>
                  <div>
                    <button
                      disabled={index === 0}
                      onClick={(e) => {
                        e.stopPropagation();
                        moveItem(index, "up");
                      }}
                    >
                      Up
                    </button>
                    <button
                      disabled={index === orderList.length - 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        moveItem(index, "down");
                      }}
                    >
                      Down
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <button onClick={() => setShowOrderPopup(false)}>Close</button>
          </div>
        </div>
      )}

      <div className="max1600">
        <div className="article programsTxt">
          <div className="flex-image-text">
            <div>
              <h2>About Us</h2>
              <p>
                The Watoto Program works to better the lives of children in
                Kibera by providing free access to a place where kids can come
                in and read books, do their school assignments, or get free
                academic assistance and materials. Our work is solely run by
                private funds and sponsors.
              </p>
              <p>
                Kibera is the largest urban slum in Africa. Around 2,000,000
                people live here in what is defined as extreme poverty, earning
                less than US$2 per day. Unemployment rates are high and most
                people cannot afford education for their children.
              </p>
            </div>
            <img src={images.insideTheLibrary} className="img" alt="" />
          </div>
        </div>
        <div className="flex-container" id="flex">
          <div className="row2">
            {aboutLoading || orderLoading ? (
              <>
                <div className="loading-container">
                  <div className="loading-animation"></div>
                  <div className="loading-text">
                    <Loading />
                  </div>
                </div>
                <br />
                <br />
              </>
            ) : (
              <>
                {sortedAboutItems.map((item) => (
                  <div className="column-2" key={item.id}>
                    {item.status === "Published" || isAdmin ? (
                      item.url === "documents" ? (
                        <MyLink to="/about/documents">
                          <div className="program-card card" id={item.url}>
                            <div className="flex-container-2">
                              <h3>{item.title}</h3>
                            </div>
                            <img
                              src={item.img}
                              className="program-img"
                              alt={item.title}
                            />
                          </div>
                        </MyLink>
                      ) : (
                        <MyLink to={`/about/${item.url}`}>
                          <div className="program-card card" id={item.url}>
                            <div className="flex-container-2">
                              <h3>{item.title}</h3>
                              {item.status === "Draft" && isAdmin && (
                                <span className="coming">coming</span>
                              )}
                            </div>
                            <img
                              src={item.img}
                              className="program-img"
                              alt={item.title}
                            />
                          </div>
                        </MyLink>
                      )
                    ) : (
                      <div className="program-card card" id={item.url}>
                        <div className="flex-container-2">
                          <h3>{item.title}</h3>
                          <span className="coming">coming</span>
                        </div>
                        <img
                          src={item.img}
                          className="program-img"
                          alt={item.title}
                        />
                      </div>
                    )}
                  </div>
                ))}

                {isAdmin && (
                  <div className="column-2">
                    <div
                      className="program-card card"
                      onClick={handleCreateAbout}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex-container-2">
                        <h3>
                          <i className="fa-solid fa-plus"></i> Create New About
                        </h3>
                      </div>
                    </div>
                    <div
                      className="program-card card"
                      onClick={() => setShowOrderPopup(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex-container-2">
                        <h3>
                          <i className="fa-regular fa-pen-to-square"></i> Manage
                          About Order
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default About;
