import React, { useState, useEffect } from "react";
import { db, storage } from "../../../../firebase";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  increment,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useAuth } from "../../../../AuthProvider";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import PropTypes from "prop-types";
import "./Budget.css";
import ProgressBar from "./ProgressBar";
import AdjustmentUploader from "./AdjustmentUploader";

// ============================
// ReceiptUploader Component
// ============================
const ReceiptUploader = ({
  budgetId,
  uploadReceipt,
  setShowReceiptUploader,
  existingReceipt,
}) => {
  const [receiptAmount, setReceiptAmount] = useState(
    existingReceipt ? existingReceipt.amount : ""
  );

  const [receiptFile, setReceiptFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [receiptName, setReceiptName] = useState(
    existingReceipt ? existingReceipt.name : ""
  );
  const [noReceipt, setNoReceipt] = useState(
    existingReceipt ? existingReceipt.noReceipt : false
  );

  const handleUpload = async () => {
    if (!noReceipt && !receiptFile) {
      alert("Please select a receipt file or indicate no receipt.");
      return;
    }

    const amount = parseFloat(receiptAmount);
    if (isNaN(amount) || amount <= 0) {
      alert("Please enter a valid receipt amount.");
      return;
    }

    setUploading(true); // Start uploading

    try {
      let fileUrl = existingReceipt ? existingReceipt.fileUrl : "";
      if (!noReceipt && receiptFile) {
        // If updating and a new file is selected, upload the new file
        const storageRefPath = `receipts/${receiptFile.name}_${Date.now()}`;
        const storageRefObj = ref(storage, storageRefPath);
        const snapshot = await uploadBytes(storageRefObj, receiptFile);
        fileUrl = await getDownloadURL(snapshot.ref);

        console.log("File uploaded successfully, URL:", fileUrl);

        // If editing and there's an existing file, delete the old file
        if (existingReceipt && existingReceipt.fileUrl) {
          const oldFileRef = ref(storage, existingReceipt.fileUrl);
          await deleteObject(oldFileRef);
        }
      }

      // Update Firestore with receipt details
      await uploadReceipt(
        budgetId,
        amount,
        fileUrl,
        receiptName,
        noReceipt,
        existingReceipt ? existingReceipt.id : null // Pass receipt ID if editing
      );

      // Confirm success before alerting
      alert(
        existingReceipt
          ? "Receipt updated successfully."
          : "Receipt uploaded successfully."
      );

      // Clear input fields only if successful
      setReceiptAmount("");
      setReceiptFile(null);
      setReceiptName("");
      setNoReceipt(false);
      setShowReceiptUploader(false);
    } catch (error) {
      console.error("Error uploading receipt:", error);
      alert("Failed to upload receipt.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="receipt-uploader">
      <h3>{existingReceipt ? "Edit Receipt" : "Upload Receipt"}</h3>
      <table>
        <tr>
          <td>Item(s) bought:</td>
          <td>
            <input
              type="text"
              placeholder="Receipt Name"
              value={receiptName}
              onChange={(e) => setReceiptName(e.target.value)}
              className="receipt-input"
            />
          </td>
        </tr>
        <tr>
          <td>Amount on receipt:</td>
          <td>
            <input
              type="number"
              placeholder="Amount (KES)"
              value={receiptAmount}
              onChange={(e) => setReceiptAmount(e.target.value)}
              min="0"
              className="receipt-input"
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <input
              type="file"
              accept=".pdf,image/*"
              capture="environment"
              onChange={(e) => setReceiptFile(e.target.files[0])}
              className="receipt-file-input"
              disabled={noReceipt} // Disable if noReceipt is checked
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <label>
              <input
                type="checkbox"
                checked={noReceipt}
                onChange={(e) => setNoReceipt(e.target.checked)}
              />{" "}
              No receipt
            </label>
          </td>
        </tr>
      </table>
      <br />
      <div className="popup-buttons">
        <button
          className="popup-create-button"
          onClick={handleUpload}
          disabled={uploading}
        >
          {uploading ? "Uploading..." : existingReceipt ? "Update" : "Upload"}
        </button>
        <button
          className="popup-cancel-button"
          onClick={() => {
            setReceiptAmount("");
            setReceiptFile(null);
            setReceiptName("");
            setNoReceipt(false);
            setShowReceiptUploader(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

ReceiptUploader.propTypes = {
  budgetId: PropTypes.string.isRequired,
  uploadReceipt: PropTypes.func.isRequired,
  setShowReceiptUploader: PropTypes.func.isRequired,
};

// ============================
// BudgetCard Component
// ============================
const BudgetCard = ({ budget, monthId, uploadReceipt, uploadAdjustment }) => {
  const [receipts, setReceipts] = useState([]);
  const [adjustments, setAdjustments] = useState([]);
  const { isAdmin } = useAuth();
  const [showReceiptUploader, setShowReceiptUploader] = useState(false);
  const [editingReceipt, setEditingReceipt] = useState(null);
  const [showAdjustmentUploader, setShowAdjustmentUploader] = useState(false);

  useEffect(() => {
    // Fetch receipts
    const receiptsRef = collection(
      db,
      "budgets",
      monthId,
      "budgetItems",
      budget.id,
      "receipts"
    );
    const qReceipts = query(receiptsRef);
    const unsubscribeReceipts = onSnapshot(qReceipts, (snapshot) => {
      const receiptData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReceipts(receiptData);
    });

    // Fetch adjustments
    const adjustmentsRef = collection(
      db,
      "budgets",
      monthId,
      "budgetItems",
      budget.id,
      "adjustments"
    );
    const qAdjustments = query(adjustmentsRef);
    const unsubscribeAdjustments = onSnapshot(qAdjustments, (snapshot) => {
      const adjustmentData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAdjustments(adjustmentData);
    });

    return () => {
      unsubscribeReceipts();
      unsubscribeAdjustments();
    };
  }, [monthId, budget.id]);

  const handleDeleteReceipt = async (receiptId, fileUrl) => {
    if (!isAdmin) return;
    if (!window.confirm("Are you sure you want to delete this receipt?"))
      return;

    try {
      const receiptRef = doc(
        db,
        "budgets",
        monthId,
        "budgetItems",
        budget.id,
        "receipts",
        receiptId
      );
      const receiptSnap = await getDoc(receiptRef);
      const receiptData = receiptSnap.data();
      await deleteDoc(receiptRef);

      if (fileUrl) {
        const parts = fileUrl.split("/o/");
        if (parts.length > 1) {
          const filePath = decodeURIComponent(parts[1].split("?")[0]);
          const fileRef = ref(storage, filePath);
          await deleteObject(fileRef);
        }
      }

      const budgetRef = doc(db, "budgets", monthId, "budgetItems", budget.id);
      await updateDoc(budgetRef, {
        totalReceiptsAmount: increment(-receiptData.amount),
      });

      alert("Receipt deleted successfully.");
    } catch (error) {
      console.error("Error deleting receipt:", error);
      alert("Failed to delete receipt.");
    }
  };

  const handleDeleteAdjustment = async (adjustmentId) => {
    if (!isAdmin) return;
    if (!window.confirm("Are you sure you want to delete this adjustment?"))
      return;

    try {
      const adjustmentRef = doc(
        db,
        "budgets",
        monthId,
        "budgetItems",
        budget.id,
        "adjustments",
        adjustmentId
      );
      const adjustmentSnap = await getDoc(adjustmentRef);
      const adjustmentData = adjustmentSnap.data();

      // Delete adjustment document
      await deleteDoc(adjustmentRef);

      // Optionally delete file from storage if attached
      if (adjustmentData.fileUrl) {
        const fileRef = ref(storage, adjustmentData.fileUrl);
        await deleteObject(fileRef);
      }

      // Update totalAdjustmentsAmount
      const budgetRef = doc(db, "budgets", monthId, "budgetItems", budget.id);
      await updateDoc(budgetRef, {
        totalAdjustmentsAmount:
          (budget.totalAdjustmentsAmount || 0) - (adjustmentData.amount || 0),
      });

      alert("Adjustment deleted successfully.");
    } catch (error) {
      console.error("Error deleting adjustment:", error);
      alert("Failed to delete adjustment.");
    }
  };

  const handleDeleteBudget = async () => {
    if (!isAdmin) return;
    if (!window.confirm("Are you sure you want to delete this budget?")) return;

    try {
      const budgetRef = doc(db, "budgets", monthId, "budgetItems", budget.id);
      await deleteDoc(budgetRef);
    } catch (error) {
      console.error("Error deleting budget:", error);
      alert("Failed to delete budget.");
    }
  };

  const missingReceiptsCount = receipts.filter(
    (receipt) => receipt.noReceipt
  ).length;

  const handleEditReceipt = (receipt) => {
    setEditingReceipt(receipt);
    setShowReceiptUploader(true);
  };

  return (
    <>
      <div
        className="budget-card"
        style={{
          border: "1px solid #ddd",
          borderRadius: 5,
          padding: 15,
          marginBottom: 10,
        }}
      >
        {isAdmin && (
          <span className="delete-budget" onClick={handleDeleteBudget}>
            x
          </span>
        )}
        <h3>{budget.name || "Unnamed Budget"}</h3>
        <p>
          <strong>Project:</strong> {budget.category} |{" "}
          <strong>Remaining Balance:</strong> KES{" "}
          {(
            budget.totalAmount -
            budget.totalReceiptsAmount +
            budget.totalAdjustmentsAmount
          ).toFixed(0)}{" "}
          |{" "}
          {budget.attachmentUrls && budget.attachmentUrls.length > 0 && (
            <span>
              <strong>Budget:</strong>{" "}
              <a
                href={budget.attachmentUrls[0]}
                target="_blank"
                rel="noopener noreferrer"
                className="receipt-link"
              >
                View File
              </a>
            </span>
          )}
        </p>
        <ProgressBar
          totalAmount={budget.totalAmount}
          totalReceiptsAmount={budget.totalReceiptsAmount}
          totalAdjustmentsAmount={budget.totalAdjustmentsAmount || 0}
        />
        {missingReceiptsCount > 0 && (
          <b className="missing-receipts red">
            {missingReceiptsCount} receipts missing
          </b>
        )}
        <br />
        <br />
        <button
          onClick={() => {
            setEditingReceipt(null);
            setShowReceiptUploader(true);
          }}
          className="remark-button"
        >
          Upload Receipt
        </button>{" "}
        <button
          onClick={() => setShowAdjustmentUploader(true)}
          className="remark-button blue"
        >
          Register over/under spending
        </button>
        {showReceiptUploader && (
          <div className="popup-overlay">
            <div className="popup-content">
              <ReceiptUploader
                budgetId={budget.id}
                uploadReceipt={uploadReceipt}
                setShowReceiptUploader={setShowReceiptUploader}
                existingReceipt={editingReceipt}
              />
            </div>
          </div>
        )}
        {showAdjustmentUploader && (
          <div className="popup-overlay">
            <div className="popup-content">
              <AdjustmentUploader
                budgetId={budget.id}
                uploadAdjustment={uploadAdjustment}
                setShowAdjustmentUploader={setShowAdjustmentUploader}
              />
            </div>
          </div>
        )}
        <br />
        {adjustments.length > 0 && (
          <>
            <br />
            {adjustments.map((adjustment) => (
              <div key={adjustment.id} className="adjustment-item">
                <p>
                  <strong>
                    <i class="fa-solid fa-pen-to-square"></i> ADJUSTMENT:
                  </strong>{" "}
                  {adjustment.amount >= 0 ? "+" : ""}
                  KES {adjustment.amount.toFixed(2)} |{" "}
                  <strong>Description:</strong>{" "}
                  {adjustment.description || "No Description"} |{" "}
                  <strong>Registered By:</strong>{" "}
                  {adjustment.registeredBy
                    ? adjustment.registeredBy.email || "Unknown"
                    : "Loading..."}{" "}
                  | <strong>Registered At:</strong>{" "}
                  {adjustment.registeredAt
                    ? adjustment.registeredAt.toDate().toLocaleString()
                    : "Loading..."}{" "}
                  {adjustment.fileUrl && (
                    <>
                      | <strong>File:</strong>{" "}
                      <a
                        href={adjustment.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="receipt-link"
                      >
                        View File
                      </a>
                    </>
                  )}
                  <span
                    className="delete-receipt"
                    onClick={() => handleDeleteAdjustment(adjustment.id)}
                  >
                    x
                  </span>
                </p>
              </div>
            ))}
          </>
        )}
        {receipts.length === 0 && <p>No receipts uploaded for this budget.</p>}
        {receipts.map((receipt) => (
          <>
            <br />
            <div key={receipt.id} className="receipt-item">
              <p>
                <strong>
                  <i className="fa-solid fa-receipt"></i> Receipt Name:
                </strong>{" "}
                {receipt.name || "Unnamed Receipt"} | <strong>Amount:</strong>{" "}
                KES {receipt.amount.toFixed(2)} | <strong>Uploaded By:</strong>{" "}
                {receipt.uploadedBy?.email || "Anonymous"} |{" "}
                <strong>Uploaded At:</strong>{" "}
                {receipt.uploadedAt
                  ? receipt.uploadedAt.toDate().toLocaleString()
                  : "Loading..."}{" "}
                {!receipt.noReceipt && (
                  <>
                    | <strong>File:</strong>{" "}
                    <a
                      href={receipt.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="receipt-link"
                    >
                      View Receipt
                    </a>
                  </>
                )}
                {receipt.noReceipt && (
                  <>
                    | <strong>No receipt attached.</strong>{" "}
                    <u
                      className="upload-existing-receipt-button"
                      onClick={() => handleEditReceipt(receipt)}
                    >
                      Upload Receipt
                    </u>
                  </>
                )}
                {isAdmin && (
                  <span
                    className="delete-receipt"
                    onClick={() =>
                      handleDeleteReceipt(
                        receipt.id,
                        receipt.fileUrl,
                        receipt.noReceipt
                      )
                    }
                  >
                    x
                  </span>
                )}
              </p>
            </div>
          </>
        ))}
      </div>
      <br />
    </>
  );
};

BudgetCard.propTypes = {
  budget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    totalReceiptsAmount: PropTypes.number.isRequired,
    totalAdjustmentsAmount: PropTypes.number, // New field
    attachmentUrls: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  monthId: PropTypes.string.isRequired,
  uploadReceipt: PropTypes.func.isRequired,
  uploadAdjustment: PropTypes.func.isRequired, // New prop
};

// ============================
// BudgetMonth Component
// ============================
const BudgetMonth = ({ setSelectedMonth, selectedMonth }) => {
  const { id: monthId, year, month } = selectedMonth;
  const [totalAmount, setTotalAmount] = useState("");
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [submitting, setSubmitting] = useState(false); // For form submission state
  const [showPopup, setShowPopup] = useState(false);
  const [budgetName, setBudgetName] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const adminDocRef = doc(db, "admin", "budget-categories");
        const adminDocSnap = await getDoc(adminDocRef);
        if (adminDocSnap.exists()) {
          setCategories(adminDocSnap.data().categories || []);
        } else {
          console.warn("No budget categories found.");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchBudgets = () => {
      const budgetItemsRef = collection(db, "budgets", monthId, "budgetItems");
      const q = query(budgetItemsRef);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
        setBudgets(data);
      });
      return unsubscribe;
    };

    fetchCategories();
    const unsubscribeBudgets = fetchBudgets();

    return () => unsubscribeBudgets();
  }, [monthId]);

  const submitBudget = async (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      alert("Please select a category.");
      return;
    }

    if (submitting) return; // Prevent multiple submissions

    setSubmitting(true);

    let attachmentUrls = [];
    try {
      if (attachmentFile) {
        const storageRefPath = `budgets/${attachmentFile.name}_${Date.now()}`;
        const storageRef = ref(storage, storageRefPath);
        const snapshot = await uploadBytes(storageRef, attachmentFile);
        const fileUrl = await getDownloadURL(snapshot.ref);
        attachmentUrls.push(fileUrl);
      }

      await addDoc(collection(db, "budgets", monthId, "budgetItems"), {
        name: budgetName,
        category: selectedCategory,
        totalAmount: Number(totalAmount),
        totalReceiptsAmount: 0, // Initialize to 0
        totalAdjustmentsAmount: 0,
        totalReceiptsAmount: 0, // Initialize to 0
        createdAt: serverTimestamp(),
        attachmentUrls,
      });

      setBudgetName("");
      setTotalAmount("");
      setAttachmentFile(null);
      setSelectedCategory("");
      setShowPopup(false);
    } catch (error) {
      console.error("Error submitting budget:", error);
      alert("Failed to submit budget.");
      setShowPopup(false);
    } finally {
      setSubmitting(false);
    }
  };

  const uploadReceipt = async (
    budgetId,
    receiptAmount,
    fileUrl,
    receiptName,
    noReceipt,
    receiptId = null
  ) => {
    if (!noReceipt && !fileUrl) {
      alert("Failed to upload file.");
      return;
    }

    const receiptAmountNumber = Number(receiptAmount);
    if (isNaN(receiptAmountNumber) || receiptAmountNumber <= 0) {
      alert("Please enter a valid receipt amount.");
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        alert("You must be logged in to upload receipts.");
        return;
      }
      const { uid, displayName, email } = user;

      const budgetItemDocRef = doc(
        db,
        "budgets",
        monthId,
        "budgetItems",
        budgetId
      );
      const budgetItemSnap = await getDoc(budgetItemDocRef);
      if (!budgetItemSnap.exists()) {
        alert("Budget item does not exist.");
        return;
      }
      const budgetData = budgetItemSnap.data();

      if (!receiptId) {
        // For new receipts, ensure total won't exceed the budget.
        if (
          (budgetData.totalReceiptsAmount || 0) + receiptAmountNumber >
          (budgetData.totalAmount || 0)
        ) {
          alert("Receipt amount exceeds the remaining budget.");
          return;
        }
        const receiptsCollectionRef = collection(budgetItemDocRef, "receipts");
        await addDoc(receiptsCollectionRef, {
          name: receiptName || "Unnamed Receipt",
          amount: receiptAmountNumber,
          uploadedAt: serverTimestamp(),
          fileUrl: noReceipt ? "" : fileUrl,
          uploadedBy: {
            uid,
            displayName: displayName || "Anonymous",
            email: email || "No Email",
          },
          noReceipt,
        });
        await updateDoc(budgetItemDocRef, {
          totalReceiptsAmount:
            (budgetData.totalReceiptsAmount || 0) + receiptAmountNumber,
        });
      } else {
        // When editing an existing receipt, update it without incrementing totalReceiptsAmount again.
        const receiptDocRef = doc(budgetItemDocRef, "receipts", receiptId);
        await updateDoc(receiptDocRef, {
          name: receiptName || "Unnamed Receipt",
          fileUrl: noReceipt ? "" : fileUrl,
          noReceipt,
        });
      }

      console.log("Receipt processed successfully for budget:", budgetId);
    } catch (error) {
      console.error("Error processing receipt:", error);
    }
  };

  const uploadAdjustment = async (
    budgetId,
    adjustmentAmount,
    description,
    fileUrl
  ) => {
    if (isNaN(adjustmentAmount) || adjustmentAmount === 0) {
      alert("Adjustment amount must be a non-zero number.");
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        alert("You must be logged in to register adjustments.");
        return;
      }

      const { uid, displayName, email } = user;

      const budgetItemDocRef = doc(
        db,
        "budgets",
        monthId,
        "budgetItems",
        budgetId
      );
      const budgetItemSnap = await getDoc(budgetItemDocRef);

      if (!budgetItemSnap.exists()) {
        alert("Budget item does not exist.");
        return;
      }

      // Add the adjustment to the 'adjustments' subcollection with registrar info
      const adjustmentsCollectionRef = collection(
        budgetItemDocRef,
        "adjustments"
      );
      await addDoc(adjustmentsCollectionRef, {
        amount: adjustmentAmount,
        description: description || "",
        registeredAt: serverTimestamp(),
        fileUrl: fileUrl || "",
        registeredBy: {
          uid: uid,
          displayName: displayName || email || "Anonymous",
          email: email || "No Email",
        },
      });

      // Update the totalAdjustmentsAmount in the budget item
      await updateDoc(budgetItemDocRef, {
        totalAdjustmentsAmount: increment(adjustmentAmount),
      });

      console.log("Adjustment added successfully to Firestore:", budgetId);
    } catch (error) {
      console.error("Error uploading adjustment to Firestore:", error);
      alert("Failed to register adjustment in Firestore.");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <button
        onClick={() => setSelectedMonth(null)}
        style={{ marginBottom: 20 }}
      >
        Back
      </button>
      <h2>
        Budgets for {month} {year}
      </h2>
      <button
        className="popup-create-button"
        onClick={() => setShowPopup(true)}
      >
        <i className="fa-solid fa-plus"></i> Submit A New Budget
      </button>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Create Budget Request</h3>

            <table className="budget-form-table">
              <tbody>
                <tr>
                  <td>
                    <label>Name</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={budgetName}
                      onChange={(e) => setBudgetName(e.target.value)}
                      required
                      placeholder="Enter budget name"
                      style={{ padding: "5px", width: "100%" }}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label>Category</label>
                  </td>
                  <td>
                    <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      required
                      style={{ padding: "5px", width: "100%" }}
                    >
                      <option value="">-- Select Category --</option>
                      {categories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label>Amount</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="Total budget amount (KES)"
                      value={totalAmount}
                      onChange={(e) => setTotalAmount(e.target.value)}
                      required
                      min="0"
                      style={{ padding: "5px", width: "100%" }}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label>Budget</label>
                  </td>
                  <td>
                    <input
                      type="file"
                      accept=".pdf,image/*"
                      onChange={(e) => setAttachmentFile(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <div className="popup-buttons">
              <button
                className="popup-create-button"
                onClick={submitBudget}
                disabled={submitting}
              >
                {submitting ? "Submitting..." : "Submit"}
              </button>
              <button
                type="button"
                className="popup-cancel-button"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <br />
      <br />
      <h3>Existing Budgets</h3>
      {budgets.length === 0 && <p>No budgets submitted for this month.</p>}
      {budgets.map((b) => (
        <BudgetCard
          key={b.id}
          budget={b}
          monthId={monthId}
          uploadReceipt={uploadReceipt}
          uploadAdjustment={uploadAdjustment}
        />
      ))}
    </div>
  );
};

// ============================
// Prop Types for BudgetMonth
// ============================
BudgetMonth.propTypes = {
  setSelectedMonth: PropTypes.func.isRequired,
  selectedMonth: PropTypes.shape({
    id: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    month: PropTypes.string.isRequired,
  }).isRequired,
};

export default BudgetMonth;
