// ProgressBar.jsx
import React from "react";
import PropTypes from "prop-types";
import "./Budget.css";

const ProgressBar = ({
  totalAmount,
  totalReceiptsAmount,
  totalAdjustmentsAmount,
  missingReceiptsCount,
}) => {
  const adjustedTotalAmount = totalAmount + totalAdjustmentsAmount;
  const percentage =
    adjustedTotalAmount > 0
      ? (totalReceiptsAmount / adjustedTotalAmount) * 100
      : 0;
  const safePercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <>
      <div className="progress-container">
        <div
          className="progress-bar"
          style={{
            width: `${safePercentage}%`,
            backgroundColor:
              safePercentage === 100
                ? "#2ecc71"
                : safePercentage > 40
                ? "#f39c12"
                : "#e74c3c",
          }}
        ></div>
      </div>
      <p className="progress-text">
        {safePercentage.toFixed(0)}% accounted for{" "}
        <span className="light">
          (KES {totalReceiptsAmount.toFixed(0)} / KES{" "}
          {adjustedTotalAmount.toFixed(0)})
        </span>
      </p>
      {missingReceiptsCount > 0 && (
        <p className="missing-receipts">
          {missingReceiptsCount} receipts missing
        </p>
      )}
    </>
  );
};

ProgressBar.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  totalReceiptsAmount: PropTypes.number.isRequired,
  totalAdjustmentsAmount: PropTypes.number.isRequired,
  missingReceiptsCount: PropTypes.number,
};

export default ProgressBar;
