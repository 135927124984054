// BudgetSummary.jsx
import React, { useState, useEffect } from "react";
import { db } from "../../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import PropTypes from "prop-types"; // Optional: for prop type validation
import ProgressBar from "./ProgressBar";

/**
 * BudgetSummary Component
 * Displays summary for each budget month, including total requested and total receipts amount.
 */
function BudgetSummary({ budgetDoc, setSelectedMonth }) {
  const [summary, setSummary] = useState({
    totalRequested: 0,
    totalReceiptsAmount: 0,
    totalAdjustmentsAmount: 0,
  });

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const budgetItemsRef = collection(
          db,
          "budgets",
          budgetDoc.id,
          "budgetItems"
        );
        const snapshot = await getDocs(budgetItemsRef);
        let totalRequested = 0;
        let totalReceiptsAmount = 0;
        let totalAdjustmentsAmount = 0; // Initialize adjustments total

        // snapshot.forEach((doc) => {
        //   const data = doc.data();
        //   totalRequested += data.totalAmount || 0;
        //   // Only include receipt amounts if a receipt has been uploaded
        //   if (data.receiptUploaded) {
        //     totalReceiptsAmount += data.totalReceiptsAmount || 0;
        //   }
        //   totalAdjustmentsAmount += data.totalAdjustmentsAmount || 0;
        // });

        snapshot.forEach((doc) => {
          const data = doc.data();
          totalRequested += data.totalAmount || 0;
          totalReceiptsAmount += data.totalReceiptsAmount || 0;
          totalAdjustmentsAmount += data.totalAdjustmentsAmount || 0; // Sum adjustments
        });

        setSummary({
          totalRequested,
          totalReceiptsAmount,
          totalAdjustmentsAmount,
        });
      } catch (error) {
        console.error("Error fetching budget summary:", error);
      }
    };

    fetchSummary();
  }, [budgetDoc.id]);

  const adjustedTotalAmount =
    summary.totalRequested + summary.totalAdjustmentsAmount;

  const remainingBalance = adjustedTotalAmount - summary.totalReceiptsAmount;

  return (
    <div
      className="budget-card"
      onClick={() =>
        setSelectedMonth({
          id: budgetDoc.id,
          year: budgetDoc.year,
          month: budgetDoc.month,
        })
      }
    >
      <h3>
        {budgetDoc.month} {budgetDoc.year}
      </h3>
      {remainingBalance > 0 && (
        <p>
          <strong>Remaining balance: </strong> KES {remainingBalance}
        </p>
      )}

      <ProgressBar
        totalAmount={summary.totalRequested}
        totalReceiptsAmount={summary.totalReceiptsAmount}
        totalAdjustmentsAmount={summary.totalAdjustmentsAmount || 0}
        missingReceiptsCount={summary.missingReceiptsCount || 0}
      />
    </div>
  );
}

// Optional: Prop Types for validation
BudgetSummary.propTypes = {
  budgetDoc: PropTypes.shape({
    id: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
  }).isRequired,
  setSelectedMonth: PropTypes.func.isRequired,
};

export default BudgetSummary;
