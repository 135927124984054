import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { sharedRoutes } from './SharedRoutes';
import { useAuth } from './AuthProvider'; // Import useAuth hook
import Layout from './container/Pages/School/container/SchoolLayout';

function AdminRoutes() {
  const { userData } = useAuth(); // Get user data from useAuth hook
  const isAdmin = userData && userData.role === 'Admin'; // Check if user has admin role

  console.log("isAdmin in AdminRoutes:", isAdmin); // Log isAdmin status in AdminRoutes

  return (
    <Routes>
    {sharedRoutes.map((route) => {
      // If the path is /school or /school/... then transform and wrap with Layout
      if (route.path.startsWith('/school')) {
        // e.g. "/school" => "school" or "/school/timetable" => "school/timetable"
        const adminPath = route.path.replace('/school', 'school');
        
        return (
          <Route
            key={route.path}
            // Remove leading slash so it matches relative to /admin/*
            path={adminPath.replace(/^\//, '')}
            element={
              <Layout>
                {React.createElement(route.component, { isAdmin })}
              </Layout>
            }
          />
        );
      } else {
        // Old logic for non-“/school” routes
        const trimmedPath = route.path === '/' ? '' : route.path.substring(1);
        return (
          <Route
            key={route.path}
            path={trimmedPath}
            element={React.createElement(route.component, { isAdmin })}
          />
        );
      }
    })}
  </Routes>
  
  );
}

export default AdminRoutes;
