import React, { useState } from "react";
import "./SupportStepForm.css"; // External CSS for specific class names
import { images } from "../../../constants";
import VippsRecurring from "./VippsRecurring";

const SupportStepForm = ({
  step: initialStep = 1,
  paymentType: initialPaymentType = "",
}) => {
  const [step, setStep] = useState(initialStep);
  const [data, setData] = useState({
    paymentType: initialPaymentType,
    region: "",
  });

  const handleOptionSelect = (key, value) => {
    setData({ ...data, [key]: value });
    setStep((prev) => prev + 1); // Automatically go to the next step
  };

  const handleReset = () => {
    setStep(1);
    setData({ paymentType: "", region: "" });
  };

  const handleStep3Click = () => {
    setTimeout(() => {
      setStep(4);
    }, 1000);
  };

  const renderProgressBar = () => {
    const progress = (step / 4) * 100;
    return (
      <div className="progress-bar-container">
        <div className="progress-bar-bg">
          <div
            className="progress-bar-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="progress-bar-text">Step {step} of 4</p>
      </div>
    );
  };

  return (
    <div className="support-form-container">
      {renderProgressBar()}

      {step === 1 && (
        <div className="step-content">
          <h3 className="step-title">Support Options</h3>
          <div className="option-container">
            <div
              className={`option-item full-width-bg ${
                data.paymentType === "one-time" ? "selected" : ""
              }`}
              onClick={() => handleOptionSelect("paymentType", "one-time")}
            >
              <span>One-Time Support</span>
            </div>
            <div
              className={`option-item full-width-bg ${
                data.paymentType === "monthly" ? "selected" : ""
              }`}
              onClick={() => handleOptionSelect("paymentType", "monthly")}
            >
              <span>Sponsor Monthly</span>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="step-content">
          <h3 className="step-title">Region Options</h3>
          <div className="option-container">
            <div
              className={`option-item full-width-bg ${
                data.region === "norwegian" ? "selected" : ""
              }`}
              onClick={() => handleOptionSelect("region", "norwegian")}
            >
              🇳🇴 Norwegian 🇳🇴
            </div>
            <div
              className={`option-item full-width-bg ${
                data.region === "international" ? "selected" : ""
              }`}
              onClick={() => handleOptionSelect("region", "international")}
            >
              🌎 International 🌍
            </div>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="step-content">
          <h3 className="step-title">Payment Solution</h3>
          <p className="summary-text">
            You have selected <strong>{data.paymentType}</strong> support for{" "}
            <strong>{data.region}</strong> region.
          </p>
          <div className="option-item">
            {data.paymentType === "monthly" && data.region === "norwegian" && (
              // <VippsRecurring handleStep3Click={handleStep3Click} />
              <>
                {/* <iframe
                  width="100%"
                  height="350"
                  src="https://nettbutikk.solidus.no/nettbutikk/the_watoto_youth_community_and_library_norway/sponsorship"
                ></iframe> */}
                <div className="flex-image-text donate">
                  <div style={{ alignItems: "left", width: "100%" }}>
                    <a
                      className="button-design paypal vipps"
                      id="vipps"
                      href="https://qr.vipps.no/mr/?m=984475&a=30000&e="
                      target="_blank"
                      onClick={handleStep3Click}
                    >
                      Månedlig med Vipps
                    </a>
                  </div>
                  <img src={images.vippsLogo} className="img-nozoom paypal" />
                </div>
                <br />
                <p className="payment-alternative-info">
                  <i class="fa-regular fa-circle-question"></i> Ønsker du et
                  delt fadderskap? Sett en egen sum med Vipps. <br />
                  <i class="fa-regular fa-circle-question"></i> Ønsker du å
                  betale med faktura? Send en e-post til{" "}
                  <a href="mailto:magnus@thewatotoprogram.org">
                    magnus@thewatotoprogram.org
                  </a>
                  .
                  <br />
                  <i class="fa-regular fa-circle-question"></i> For faste
                  overføringer i nettbank, se info under.
                </p>
              </>
            )}
            {data.paymentType === "one-time" && data.region === "norwegian" && (
              <div className="flex-image-text donate">
                <div>
                  <h3>Vipps</h3>

                  <a
                    className="button-design paypal"
                    id="vippsPc"
                    href={images.scanVipps}
                    target="_blank"
                    onClick={handleStep3Click}
                  >
                    Engangssum med Vipps
                  </a>
                  <a
                    className="button-design paypal"
                    id="vippsMobile"
                    href="https://qr.vipps.no/28/2/05/031/9z2ImrLtM"
                    target="_blank"
                    onClick={handleStep3Click}
                  >
                    Engangssum med Vipps
                  </a>
                  <p>Vippsnr: 821346 (The Watoto Program)</p>
                </div>
                <img src={images.vippsLogo} className="img-nozoom paypal" />
              </div>
            )}
            {data.paymentType === "one-time" &&
              data.region === "international" && (
                <div>
                  <div className="flex-image-text donate">
                    <div style={{ alignItems: "left", width: "100%" }}>
                      <h3>Stripe</h3>
                      <a
                        className="button-design paypal"
                        id="stripe"
                        href="https://buy.stripe.com/6oEaHA2pf1ina6Q5kl"
                        target="_blank"
                        onClick={handleStep3Click}
                      >
                        One-time through Stripe
                      </a>

                      <p>For one-time donations with VISA or Mastercard.</p>
                    </div>
                    <img
                      src={images.stripeLogo}
                      className="img-nozoom paypal"
                    />
                  </div>
                  <div className="flex-image-text donate">
                    <div style={{ alignItems: "left", width: "100%" }}>
                      <h3>PayPal</h3>
                      <a
                        className="button-design paypal"
                        href="https://www.paypal.com/donate/?hosted_button_id=F9WFK8JFMH49E"
                        target="_blank"
                        onClick={handleStep3Click}
                      >
                        Donate through PayPal
                      </a>
                      <p>For one-time donations with VISA or Mastercard.</p>
                    </div>
                    <img
                      src={images.paypalLogo}
                      className="img-nozoom paypal"
                    />
                  </div>
                </div>
              )}
            {data.paymentType === "monthly" &&
              data.region === "international" && (
                <div>
                  <div className="flex-image-text donate">
                    <div style={{ alignItems: "left", width: "100%" }}>
                      <h3>PayPal</h3>
                      <a
                        className="button-design paypal"
                        href="https://www.paypal.com/donate/?hosted_button_id=F9WFK8JFMH49E"
                        target="_blank"
                        onClick={handleStep3Click}
                      >
                        Monthly through PayPal
                      </a>
                      <p>
                        For monthly donations (unspecified amount) with VISA or
                        Mastercard.
                      </p>
                    </div>
                    <img
                      src={images.paypalLogo}
                      className="img-nozoom paypal"
                    />
                  </div>
                  <div className="flex-image-text donate">
                    <div style={{ alignItems: "left", width: "100%" }}>
                      <h3>Stripe</h3>
                      <a
                        className="button-design paypal"
                        id="stripe"
                        href="https://buy.stripe.com/28o8zs7Jz9OTdj24gl"
                        target="_blank"
                        onClick={handleStep3Click}
                      >
                        Monthly through Stripe (US$30)
                      </a>

                      <p>
                        For monthly donations of US$30 with VISA or Mastercard.
                      </p>
                    </div>
                    <img
                      src={images.stripeLogo}
                      className="img-nozoom paypal"
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="step-content">
          <h3 className="step-title">Follow the instructions</h3>
          <p className="confirmation-text">
            The platform of your choosing has opened in a new tab. Kindly follow
            the instructions.
          </p>
          <p>
            Please note that this page will <b>not update</b> once payment goes
            through.
          </p>

          <p className="confirmation-text">
            Thank you very much for your support!
          </p>
        </div>
      )}
      {(step === 3 || step === 4) && (
        <button className="reset-button" onClick={handleReset}>
          ↻ Reset
        </button>
      )}
    </div>
  );
};

export default SupportStepForm;
