// SCHOOL PICTURES
import schoolPhoto from '../assets/school-pics/classphoto.png';
import classPhoto_7_2025 from '../assets/school-pics/classphoto_grade7_2025.JPG'
import students from '../assets/school-pics/students.JPG'

// FEATURES
import redefining from '../assets/features/redefining.png'
import community from '../assets/features/community.png'
import together from '../assets/features/together.png'
import meaningful from '../assets/features/meaningful.png'
import fees from '../assets/features/fees.png'
import food from '../assets/features/food.png'
import materials from '../assets/features/materials.png'
import curriculum from '../assets/features/curriculum.png'

// PARTNERS
import opsahlGruppen from '../assets/partners/opsahlgruppen.png'
import skullerudSkole from '../assets/partners/skullerud-logo.png'
import wwqa from '../assets/partners/wwqa.png'
import youcanyole from '../assets/partners/youcanyole.png'
import sos from '../assets/partners/sos.png'

// TIMETABLE
import timetable_grade7 from '../assets/timetable/timetable_grade7.jpg'
import timetable_grade8 from '../assets/timetable/timetable_grade8.jpg'

// LOGO
import TWLlogo from '../assets/logo/logo-transparent.png'
import vipps from '../assets/logo/scanVipps.png'
import vippsLogo from '../assets/logo/vipps.png'
import paypal from '../assets/logo/paypal-transparent.png'
import donorbox from '../assets/logo/donorbox-logo.png'
import gofundme from '../assets/logo/GoFundMe.png'
import TWAlogo from '../assets/logo/twa_logo.png'

// STUDENTS
import brandonAndStudents from '../assets/school-pics/brandonAndStudents.jpg'
import studentsInClass from '../assets/school-pics/studentsInClass.png'
import girlsEating from '../assets/school-pics/girlsEating.png'
import noImage from '../assets/school-pics/no-image.webp'


// ADMISSION
import classroom from '../assets/admission/classroom.jpg'

// DONATE
import uniform from '../assets/donate/uniform.png'
import textbooks from '../assets/donate/textbooks.jpeg'
import eating from '../assets/donate/eating.jpg'
import deskChair from '../assets/donate/deskAndChair.jpg'
import activities from '../assets/donate/activities.jpg'
import deskImg from '../assets/donate/deskImg.png'
import earthImg from '../assets/donate/earthImg.png'
import hatImg from '../assets/donate/hatImg.png'
import lunchImg from '../assets/donate/lunchImg.png'
import materialsImg from '../assets/donate/materialsImg.png'
import uniformImg from '../assets/donate/uniformImg.png'
import rent from '../assets/donate/rent.jpg'
import general from '../assets/donate/general.jpg'
import books from '../assets/donate/materials.jpg'

// ABOUT
import klwf from '../assets/about/klwf.png'
import twlInside from '../assets/about/twl-inside.png'
import twlPc from '../assets/about/twl-pc.png'

export default {

    // SCHOOL PICTURES
    schoolPhoto, classPhoto_7_2025, students,
    
    // FEATURES
    redefining, community, together, meaningful, fees, food, materials, curriculum,

    // PARTNERS
    opsahlGruppen, skullerudSkole, wwqa, youcanyole, sos,

    // TIMETABLE
    timetable_grade7,  timetable_grade8,

    // LOGO
    TWLlogo, vipps, vippsLogo, paypal, donorbox, gofundme, TWAlogo,

    // STUDENTS
    brandonAndStudents, studentsInClass, girlsEating, noImage,

    // ADMISSION
    classroom, textbooks, eating, deskChair,

    // DONATE
    uniform, activities, deskImg, earthImg, hatImg, lunchImg, materialsImg, uniformImg, rent, general, books,

    // ABOUT
    klwf, twlInside, twlPc
}