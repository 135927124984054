// LoginPopup.js
import React, { useEffect, useState } from "react";
import { auth, sendPasswordReset } from "../../../firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./LoginPopup.css";
import PhoneAuth from "../PhoneAuth";
import { useAuth } from "../../../AuthProvider";
import { images } from "../../../constants";

const LoginPopup = ({
  isOpen,
  onClose,
  isAdmin,
  successRedirect, // navigate here on successful login (default: "/admin")
  failureRedirect, // navigate here on login failure (optional)
}) => {
  const { userData } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  const [activeOption, setActiveOption] = useState("donor");
  const [isImageLoading, setIsImageLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setIsProcessing(true);

    if (!email || typeof email !== "string" || email.trim() === "") {
      setError("Please provide a valid email address.");
      setIsProcessing(false);
      return;
    }

    try {
      if (isResetMode) {
        await sendPasswordReset(email.trim());
        setResetEmailSent(true);
        setError(
          "If an account exists for this email, a reset link has been sent."
        );
        setTimeout(() => {
          setError("");
          setIsResetMode(!isResetMode);
        }, 5000);
        setIsProcessing(false);
      } else {
        await signInWithEmailAndPassword(auth, email.trim(), password);
        setIsProcessing(false);

        setTimeout(() => {
          onClose();
          const target = successRedirect || "/";
          if (location.pathname === target) {
            window.location.reload();
          } else {
            navigate(target);
          }
        }, 1000);
      }
    } catch (err) {
      console.error(
        isResetMode ? "Password reset failed:" : "Login failed:",
        err.message
      );
      if (isResetMode && err.code === "auth/user-not-found") {
        setError("No account found with this email.");
      } else if (!isResetMode) {
        setError(
          "Failed to log in. Please check your credentials and try again."
        );
      } else {
        setError("Failed to send password reset email. Please try again.");
      }
      setIsProcessing(false);
      if (!isResetMode && failureRedirect) {
        navigate(failureRedirect);
      }
    }
  };

  const toggleResetMode = () => {
    setIsResetMode(!isResetMode);
    setError("");
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.error("Logout failed:", err.message);
    }
  };

  const handleClose = () => {
    onClose();
    if (userData) {
      window.location.assign("/sponsorship");
    } else {
      window.location.reload();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="login-popup">
          <div className="login-popup-header">
            {isImageLoading && (
              <div className="image-loading-spinner">Loading...</div>
            )}
            <img
              className="login-logo"
              src={images.logo}
              alt="Logo"
              onLoad={() => setIsImageLoading(false)}
              style={{ display: isImageLoading ? "none" : "block" }}
            />
            <br />
            <h2>
              {userData
                ? "No access with your account"
                : activeOption === "donor"
                ? "Log In"
                : "Intranet Login"}
            </h2>
            <button className="close-button" onClick={handleClose}>
              X
            </button>
          </div>
          {userData ? (
            <div className="full-width">
              <div className="feature-box blue" onClick={handleLogout}>
                Log Out
              </div>
            </div>
          ) : (
            <>
              <div className="login-popup-options">
                <div
                  className={`option ${
                    activeOption === "donor" ? "active" : ""
                  }`}
                  onClick={() => handleOptionClick("donor")}
                >
                  <p>
                    <i className="fa-solid fa-hand-holding-heart"></i> Sponsor
                  </p>
                </div>
                <div
                  className={`option ${
                    activeOption === "staff-admin" ? "active" : ""
                  }`}
                  onClick={() => handleOptionClick("staff-admin")}
                >
                  <p>
                    <i className="fa-solid fa-child"></i> Staff & Admin
                  </p>
                </div>
              </div>
              <br />
              {activeOption === "donor" && <PhoneAuth />}
              {activeOption === "staff-admin" && (
                <div className="full-width">
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>Email</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    {!isResetMode && (
                      <div>
                        <label>Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    )}
                    <button
                      type="submit"
                      className="login-popup-button"
                      disabled={isProcessing}
                    >
                      {isResetMode ? "Reset Password" : "Login"}
                      {isProcessing && (
                        <span className="loading-spinner"></span>
                      )}
                    </button>
                  </form>
                  <p className="reset-toggle" onClick={toggleResetMode}>
                    {isResetMode ? "Back to Login" : "Forgot password?"}
                  </p>
                  {resetEmailSent && <p>{error}</p>}
                </div>
              )}
              {error && !resetEmailSent && <p className="error">{error}</p>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
