// ============================
/* AdjustmentUploader Component */
// ============================
import React, { useState } from "react";
import PropTypes from "prop-types";
import { db, storage } from "../../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
} from "firebase/firestore";

const AdjustmentUploader = ({
  budgetId,
  uploadAdjustment,
  setShowAdjustmentUploader,
}) => {
  const [adjustmentAmount, setAdjustmentAmount] = useState("");
  const [adjustmentDescription, setAdjustmentDescription] = useState("");
  const [adjustmentFile, setAdjustmentFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    const amount = parseFloat(adjustmentAmount);
    if (isNaN(amount) || amount === 0) {
      alert("Please enter a valid adjustment amount (positive or negative).");
      return;
    }

    setUploading(true);

    try {
      let fileUrl = null;
      if (adjustmentFile) {
        const storageRefPath = `adjustments/${
          adjustmentFile.name
        }_${Date.now()}`;
        const storageRefObj = ref(storage, storageRefPath);
        const snapshot = await uploadBytes(storageRefObj, adjustmentFile);
        fileUrl = await getDownloadURL(snapshot.ref);
      }

      // Upload adjustment details to Firestore
      await uploadAdjustment(budgetId, amount, adjustmentDescription, fileUrl);

      alert("Adjustment registered successfully.");

      // Reset form fields
      setAdjustmentAmount("");
      setAdjustmentDescription("");
      setAdjustmentFile(null);
      setShowAdjustmentUploader(false);
    } catch (error) {
      console.error("Error uploading adjustment:", error);
      alert("Failed to register adjustment.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="adjustment-uploader">
      <h3>Register Over/Under Spending</h3>
      <div>
        <table className="budget-form-table">
          <tbody>
            <tr>
              <td>Amount</td>
              <td>
                <input
                  type="number"
                  placeholder="(use negative for underspending)"
                  value={adjustmentAmount}
                  onChange={(e) => setAdjustmentAmount(e.target.value)}
                  required
                  className="adjustment-input"
                  style={{ padding: "5px", width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                <input
                  type="text"
                  placeholder="(optional)"
                  value={adjustmentDescription}
                  onChange={(e) => setAdjustmentDescription(e.target.value)}
                  className="adjustment-input"
                  style={{ padding: "5px", width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <td>File</td>
              <td>
                <input
                  type="file"
                  accept=".pdf,image/*"
                  onChange={(e) => setAdjustmentFile(e.target.files[0])}
                  className="adjustment-file-input"
                  style={{ padding: "5px", width: "100%" }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="popup-buttons">
          <button
            className="popup-create-button"
            onClick={handleUpload}
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Upload"}
          </button>
          <button
            className="popup-cancel-button"
            onClick={() => setShowAdjustmentUploader(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

AdjustmentUploader.propTypes = {
  budgetId: PropTypes.string.isRequired,
  uploadAdjustment: PropTypes.func.isRequired,
  setShowAdjustmentUploader: PropTypes.func.isRequired,
};

export default AdjustmentUploader;
