import React, { useState, useCallback, useEffect } from "react";
import { images } from "../../constants";
import "./Concept.css";
import { Navbar, InfoBar, GoBack, SectionToggle } from "../../components";

const Concept = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="concept" id="page-container">
      <div className="bodyMargin left content">
        <GoBack />
        <h2>Concept</h2>
        <p>
          The Watoto Academy is a school that works to redefine education by
          providing a student's full needs for free, as well as significantly
          reducing the number of students per class.
        </p>

        <SectionToggle
          sections={[
            { id: "why", label: "Why The Watoto Academy" },
            { id: "approach", label: "Our Academic Approach" },
            { id: "uniqueness", label: "Uniqueness" },
          ]}
        >
          {(activeSection) => (
            <>
              {activeSection === "why" && (
                <div className="blueBackground">
                  {" "}
                  <div className="flex-image-text">
                    <div>
                      <h3>Why The Watoto Academy</h3>
                      <p>
                        In our present era, it is crucial that we do not remain
                        silent, even in the comfort we enjoy. We must force
                        ourselves to take action, no matter how small it may
                        seem. It is in us acting that a transformative change
                        happens. High rates of crime can cease to exist when
                        more children are provided with quality education.
                        Living standards will improve when there is access to
                        employment opportunities with the right papers at hand.
                        We can make the world a better place for one another by
                        assisting each other. Let's fight world hunger and
                        poverty together by providing these children with
                        quality education.{" "}
                      </p>
                    </div>
                    <img src={images.studentsInClass} className="img" alt="" />
                  </div>
                  <br />
                  <div className="flex-image-text">
                    <div>
                      <p>
                        It is about molding better characters from as early as
                        childhood, providing them with safe spaces to grow as
                        they continue to discover and understand more about
                        themselves so that they, too, will one day give others a
                        similar opportunity to grow. We are molding the leaders
                        of tomorrow who will serve in different industries that
                        we all wish to see grow and improve — leaders who will
                        champion equality and speak out to support those in
                        need.{" "}
                      </p>
                      <p>
                        We are a school of great minds. Minds that are eager and
                        engaged to change the world. A school for peacemakers
                        and global citizens.{" "}
                      </p>
                    </div>
                    <img src={images.students} className="img" alt="" />
                  </div>
                  {/* <p>
        Join us as we introduce to all, our new junior secondary school
        students, who we are adopting and will be providing them with free
        quality education at our new campus grounds as we strive to make them
        leaders of today and tomorrow, thinkers, doers and achievers. Support
        us as we continue to strive in touching and changing the lives of many
        of these children who come from very vulnerable backgrounds, wishing
        and praying that their future may be brighter and better for
        themselves, family and community.{" "}
      </p> */}
                </div>
              )}

              {activeSection === "approach" && (
                <div className="blueBackground">
                  <h3>Our Academic Approach</h3>
                  <p>
                    Our academic approach integrates a holistic learning
                    experience for the students. The subjects they are covering
                    not only allow them to read and write or become great
                    literature students, but also enable them to develop
                    foundational mathematical skills. They are introduced to
                    scientific concepts and learn about cultures, societies, and
                    the world in social studies. The curriculum emphasizes the
                    effective use of language and aims to enhance communication
                    skills through project-based learning, promoting critical
                    thinking and problem-solving skills among the students.{" "}
                  </p>
                  <p>
                    Our program is designed to suit the academic growth of the
                    child. We not only learn from books but also allocate a
                    significant amount of time to practical activities, allowing
                    children to learn by doing. Our class timetable includes 40
                    minutes of theoretical learning and another 40 minutes of
                    practical learning, enabling them to apply theoretical
                    knowledge in real-world situations. Additionally, we have
                    integrated technology into their learning by providing
                    digital literacy skills using educational software, online
                    resources, and interactive learning platforms.{" "}
                  </p>
                  <p>
                    To ensure that we provide children with a well-rounded
                    education, our assessment methods not only include
                    traditional examination methods but also a mix of project
                    presentations and continuous assessment of the child's daily
                    improvement. We also ensure timely feedback to the students
                    to help them understand their strengths and areas of
                    improvement.{" "}
                  </p>
                  <p>
                    We recognize the importance that parents play in their
                    children's wellbeing, and we have incorporated a system that
                    facilitates better and more effective parental involvement.
                    This is achieved by establishing strong communication
                    between parents and teachers, keeping them informed of their
                    child's progress. We are preparing these students to
                    transition to senior secondary by building a strong academic
                    foundation and developing essential skills that will help
                    them cultivate interpersonal skills, self-awareness, and
                    emotional regulation.{" "}
                  </p>
                  <br />
                </div>
              )}

              {activeSection === "uniqueness" && (
                <div className="blueBackground">
                  <h3>What Makes Us Unique?</h3>
                  <p>
                    Our school is offering a holistic curriculum with qualified
                    teachers who provide a competency-based approach to the
                    children, assessing them not only by their grades but also
                    by their knowledge in various activities. This approach
                    involves fostering their creativity, enabling them to
                    innovate, and helping them become the best version of
                    themselves.
                  </p>
                  <p>
                    The children are in a class of no more than 25 students.
                    They are provided with course textbooks, and by the end of
                    the year, they will be required to have spent at least 80
                    hours of volunteer work serving the community. We strongly
                    believe in serving one another, and the children are
                    encouraged to participate in activities that serve humanity
                    and the community.{" "}
                  </p>
                  <table className="concept-table">
                    <tbody>
                      <tr>
                        <td>
                          <th>
                            STUDENT EXPENSES <i class="fa-solid fa-coins"></i>
                          </th>
                        </td>
                        <td>
                          <th>Average public Kenyan school</th>
                        </td>
                        <td>
                          <th>The Watoto Academy</th>
                        </td>
                        <td className="whyTd">
                          <th>Why?</th>
                        </td>
                      </tr>
                      <tr>
                        <td>Yearly school fees</td>
                        <td>$400</td>
                        <td>0</td>
                        <td>
                          The greatest reason why only 15% of Kibera children
                          are able to attend grade 7 is that they come from
                          low-income families. Our students do not pay school
                          fees.
                        </td>
                      </tr>
                      <tr>
                        <td>School materials</td>
                        <td>$100</td>
                        <td>0</td>
                        <td>
                          Some go to class without exercise books because they
                          cannot afford it, and some lose valuable marks in
                          their maths exams because they cannot afford
                          constuction tools. We provide materials for free.
                        </td>
                      </tr>
                      <tr>
                        <td>School uniform</td>
                        <td>$200</td>
                        <td>0</td>
                        <td>
                          Many students cannot attend school because of lack of
                          funds for school uniform. We provide everything they
                          need to attend school.
                        </td>
                      </tr>
                      <tr>
                        <td>Educational trips</td>
                        <td>$50</td>
                        <td>0</td>
                        <td>
                          Our students will get educational school trips at no
                          cost.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <th>Total yearly cost</th>
                        </td>
                        <td>
                          <th>$750</th>
                        </td>
                        <td>
                          <th>$0</th>
                        </td>
                        <td>
                          Average yearly salary in Kibera is less than $500.
                          Most families cannot afford school fees.
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="concept-table">
                    <tbody>
                      <tr>
                        <td>
                          <th>
                            STANDARDS <i class="fa-solid fa-medal"></i>
                          </th>
                        </td>
                        <td>
                          <th>Average public Kenyan school</th>
                        </td>
                        <td>
                          <th>The Watoto Academy</th>
                        </td>
                        <td className="whyTd">
                          <th>Why?</th>
                        </td>
                      </tr>
                      <tr>
                        <td>Student number per class</td>
                        <td>90</td>
                        <td>25</td>
                        <td>
                          We believe that every child needs to be seen, valued
                          and appreciated, which is why we have a cap of 25
                          students per class.
                        </td>
                      </tr>
                      <tr>
                        <td>Student to Textbook ratio</td>
                        <td>1:5</td>
                        <td>1:2</td>
                        <td>
                          May students in schools have to share text books at a
                          challenging ratio. Our students have 1 book per 2
                          students to ensure adequate resources for studying.
                        </td>
                      </tr>
                      <tr>
                        <td>Computer to Student ratio</td>
                        <td>-</td>
                        <td>1:2</td>
                        <td>
                          We fully embody digital tools to ensure our students
                          excell in today's digital world.
                        </td>
                      </tr>
                      <tr>
                        <td>Free school meals</td>
                        <td>-</td>
                        <td>breakfast and lunch</td>
                        <td>
                          Our students get nutritious meals for breakfast and
                          lunch, ensuring that they have the energy to fully
                          focus on their studies.
                        </td>
                      </tr>
                      <tr>
                        <td>Sanitation and hygene</td>
                        <td>below satisfactory</td>
                        <td>excellent</td>
                        <td>
                          Our washrooms are cleaned twice a day, teaching the
                          children the importance of good hygiene and
                          santitation.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </SectionToggle>
      </div>
    </div>
  );
};

export default Concept;
