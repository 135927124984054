import React, { useState, useRef } from "react";
import { Navbar, Footer, Side, MyLink } from "../../../components";
import { images } from "../../../constants";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";

const PartnerSchool = ({ isAdmin }) => {
  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        {/* <Side /> */}
        <div className="main max-1000">
          <div className="article">
            <h2>Partner With Us: School Projects That Make a Difference</h2>
            <p>Let your school become a Watoto School!</p>

            <br />
            <h3>Annual Fundraising Project</h3>
            <p>
              Many schools in Norway organize yearly campaigns to support causes
              that improve the lives of children globally. We invite your school
              to collaborate with our ongoing work in Kenya focused on access to
              education and eliminating hunger—core goals aligned with the UN's
              Sustainable Development Goals.
            </p>
            <p>If your school chooses to partner with us, we offer:</p>
            <ul>
              <li>
                Introductory visit (in person or online) to present the project
              </li>
              <li>
                Closing visit with a personalized certificate of appreciation
              </li>
              <li>
                Access to communication channels with our team in Kenya
                (WhatsApp, email, Messenger)
              </li>
              <li>Tips and creative ideas for how students can fundraise</li>
            </ul>
            <p>
              For long-term commitments or larger fundraising efforts, we may
              even be able to arrange a school visit to Kenya for selected
              representatives.
            </p>
            <br />
            <h3>Elective: Community Contribution</h3>
            <p>
              Many lower secondary schools offer an elective focused on helping
              others. This subject promotes solidarity, equality, and the joy of
              making a difference.
            </p>
            <p>
              If your class would like to engage with a project connected to our
              work in Kenya, we can support you with:
            </p>
            <ul>
              <li>
                Guest visits or video calls to explain the project and its
                impact
              </li>
              <li>Guidance on how to organize the work and stay engaged</li>
              <li>A thank-you certificate for participating students</li>
            </ul>
            <br />
            <h3>Resources & Activity Ideas</h3>
            <p>
              Need inspiration? Here are ways your students can contribute and
              have fun:
            </p>
            <ul>
              <li>
                Organize a school restaurant night and invite family and friends
              </li>
              <li>
                Host a sponsored run—how many laps can they do in 30 minutes?
              </li>
              <li>Create a second-hand shop at school</li>
              <li>Collect prizes from local businesses for a charity raffle</li>
              <li>Sell handmade food, crafts, or baked goods</li>
              <li>Set up a school carnival with games and snacks</li>
              <li>Auction off creative classwork or handmade art</li>
              <li>Host a talent show or charity concert</li>
              <li>Set up a coffee or tea stand—Kenya produces both!</li>
            </ul>
            <p>
              These are just a few ideas—what matters most is that the
              activities are fun, meaningful, and inclusive.
            </p>
            <br />
            <h3>Ready to Collaborate?</h3>
            <p>
              We'd love to hear from you if your school is interested in
              building a partnership around a meaningful project.
            </p>
            <p>
              <strong>Contact us</strong>
              <br />
              Magnus Heide
              <br />
              Phone: +47 958 85 852
              <br />
              Email:{" "}
              <u>
                <a href="mailto:magnus@thewatotoprogram.org">
                  magnus@thewatotoprogram.org
                </a>
              </u>
            </p>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PartnerSchool;
