import React, { useState, useEffect, useCallback } from "react";

const SectionToggle = ({ sections, children }) => {
  const getInitialSection = useCallback(() => {
    const hash = window.location.hash.replace("#", "");
    return sections.some((s) => s.id === hash) ? hash : sections[0].id;
  }, [sections]);

  const [activeSection, setActiveSection] = useState(getInitialSection);

  const handleHashChange = useCallback(() => {
    const hash = window.location.hash.replace("#", "");
    setActiveSection((prev) => {
      // If the hash is valid, use it; else revert to the first section
      return sections.some((section) => section.id === hash)
        ? hash
        : sections[0].id;
    });
  }, [sections]);

  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);
    // Run once on mount in case there's already a hash
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  const toggleSection = (sectionId) => {
    window.location.hash = sectionId;
  };

  return (
    <div>
      <div className="switchBtns-container">
        {sections.map(({ id, label }) => (
          <h3
            key={id}
            className={`dropdown-button ${
              activeSection === id ? "active-dropdown" : ""
            }`}
            onClick={() => toggleSection(id)}
          >
            {label}
            <i
              className={`fa-solid fa-chevron-${
                activeSection === id ? "up" : "down"
              }`}
            />
          </h3>
        ))}
      </div>

      {children(activeSection)}
    </div>
  );
};

export default SectionToggle;
