// The main issue is that `serverTimestamp()` cannot be nested inside `arrayUnion(...)`.
// Instead, you can use Firestore's `Timestamp.now()` or simply store a normal Date.
//
// For example:
//
// 1) Import Timestamp from "firebase/firestore".
// 2) Use feedbackOn: Timestamp.now() when pushing feedback into the array.

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  Timestamp,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import { Navbar, Footer } from "../../../../components";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import Loading from "../../../../components/Loading/Loading";
import { useAuth } from "../../../../AuthProvider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Reports.css";

const Report = () => {
  const { isAdmin, userData } = useAuth();
  const { year, id } = useParams();
  const navigate = useNavigate();

  const [reportContent, setReportContent] = useState("");
  const [week, setWeek] = useState("");
  const [status, setStatus] = useState("draft");

  // Keep the legacy feedback for reading
  const [legacyFeedback, setLegacyFeedback] = useState("");
  const [legacyFeedbackBy, setLegacyFeedbackBy] = useState("");
  const [legacyFeedbackOn, setLegacyFeedbackOn] = useState("");

  // Array for unlimited feedback
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  // New feedback input
  const [newFeedback, setNewFeedback] = useState("");

  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(true);

  const [draftSaved, setDraftSaved] = useState(false);
  const [feedbackSaved, setFeedbackSaved] = useState(false);
  const [feedbackDraftSaved, setFeedbackDraftSaved] = useState(false);

  const [feedbackConfirmedBy, setFeedbackConfirmedBy] = useState("");
  const [feedbackConfirmedOn, setFeedbackConfirmedOn] = useState("");

  const [submittedAt, setSubmittedAt] = useState(null);
  const [submitterEmail, setSubmitterEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [showAdditionalFeedback, setShowAdditionalFeedback] = useState(false);

  const [reportInfo, setReportInfo] = useState("");
  const firstName = userName.split(" ")[0] ?? "";

  useEffect(() => {
    const fetchReport = async () => {
      try {
        if (!userData || !id || !year) {
          navigate("/intranet#reports");
          return;
        }
        const reportRef = doc(db, "reports", year, "reports", id);
        const reportSnap = await getDoc(reportRef);

        if (reportSnap.exists()) {
          const reportData = reportSnap.data();

          // Access check
          if (
            userData.role === "Admin" ||
            reportData.userId === userData.email
          ) {
            setHasAccess(true);
          } else {
            setHasAccess(false);
            setTimeout(() => navigate("/intranet#reports"), 2000);
            return;
          }

          setLegacyFeedback(reportData.feedback || "");
          setLegacyFeedbackBy(reportData.feedbackBy || "");
          if (reportData.feedbackOn?.toDate) {
            const dateObj = reportData.feedbackOn.toDate();
            const day = String(dateObj.getDate()).padStart(2, "0");
            const month = String(dateObj.getMonth() + 1).padStart(2, "0");
            const year = dateObj.getFullYear();
            const hours = String(dateObj.getHours()).padStart(2, "0");
            const minutes = String(dateObj.getMinutes()).padStart(2, "0");
            const seconds = String(dateObj.getSeconds()).padStart(2, "0");

            const formattedTimestamp = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
            setLegacyFeedbackOn(formattedTimestamp);
          } else {
            setLegacyFeedbackOn("");
          }

          // Additional feedback array
          setFeedbackHistory(reportData.feedbackHistory || []);

          setReportContent(reportData.content || "");
          setWeek(reportData.week || "");
          setStatus(reportData.status || "draft");

          setUserName(reportData.userName || "Unknown");
          setSubmitterEmail(reportData.userId || "");

          if (reportData.submittedAt?.toDate) {
            setSubmittedAt(reportData.submittedAt.toDate());
          }

          if (reportData.feedbackConfirmedBy) {
            setFeedbackConfirmedBy(reportData.feedbackConfirmedBy);
          }
          if (reportData.feedbackConfirmedOn?.toDate) {
            setFeedbackConfirmedOn(
              reportData.feedbackConfirmedOn.toDate().toLocaleString()
            );
          }
        } else {
          navigate("/intranet/reports");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
        navigate("/intranet#reports");
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [id, year, userData, isAdmin, navigate]);

  useEffect(() => {
    const fetchReportInformation = async () => {
      try {
        const emailToFetch =
          userData && userData.role === "Admin" && submitterEmail
            ? submitterEmail
            : userData.email;
        if (!emailToFetch) return;

        const docRef = doc(db, "staff", emailToFetch);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setReportInfo(docSnap.data().reportInformation || "");
        } else {
          setReportInfo(
            "Please include the required information in your report."
          );
        }
      } catch (error) {
        console.error("Error fetching report information:", error);
      }
    };

    fetchReportInformation();
  }, [userData, submitterEmail]);

  // --------------------- Handlers --------------------- //

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const reportRef = doc(db, "reports", year, "reports", id);
      await updateDoc(reportRef, {
        content: reportContent,
        status: "submitted",
        submittedAt: serverTimestamp(),
      });
      setStatus("submitted");
      setTimeout(() => navigate("/intranet#reports"), 1000);
    } catch (error) {
      console.error("Error submitting report:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmFeedback = async () => {
    try {
      const reportRef = doc(db, "reports", year, "reports", id);
      await updateDoc(reportRef, {
        status: "completed",
        feedbackConfirmedBy: userData.name,
        feedbackConfirmedOn: serverTimestamp(),
      });
      setStatus("completed");
      setTimeout(() => navigate("/intranet#reports"), 1000);
    } catch (error) {
      console.error("Error confirming feedback:", error);
    }
  };

  const handleSaveDraft = async () => {
    try {
      const reportRef = doc(db, "reports", year, "reports", id);
      await updateDoc(reportRef, {
        content: reportContent,
        status: "draft",
      });
      setDraftSaved(true);
      setTimeout(() => setDraftSaved(false), 2000);
    } catch (error) {
      console.error("Error saving draft:", error);
    }
  };

  const handleDeleteDraft = async () => {
    if (!window.confirm("Are you sure you want to delete this draft?")) return;
    setLoading(true);
    try {
      const reportRef = doc(db, "reports", year, "reports", id);
      await deleteDoc(reportRef);
      navigate("/intranet#reports");
    } catch (error) {
      console.error("Error deleting draft:", error);
    } finally {
      setLoading(false);
    }
  };

  // For the *first official feedback*, if needed
  const handleSaveFeedbackDraft = async () => {
    try {
      const reportRef = doc(db, "reports", year, "reports", id);
      await updateDoc(reportRef, {
        feedbackHistory: arrayUnion({
          text: newFeedback,
          feedbackBy: userData.name,
          feedbackOn: Timestamp.now(), // Use Timestamp.now() instead of serverTimestamp()
        }),
        status: "feedbackDraft",
      });
      setFeedbackDraftSaved(true);
      setTimeout(() => setFeedbackDraftSaved(false), 2000);
      setNewFeedback("");
    } catch (error) {
      console.error("Error saving feedback draft:", error);
    }
  };

  // For the *first official feedback*, if needed
  const handleSaveFeedback = async () => {
    try {
      const reportRef = doc(db, "reports", year, "reports", id);
      await updateDoc(reportRef, {
        feedbackHistory: arrayUnion({
          text: newFeedback,
          feedbackBy: userData.name,
          feedbackOn: Timestamp.now(), // Use Timestamp.now() here too
        }),
        status: "awaitingRead",
      });
      setFeedbackSaved(true);
      setTimeout(() => setFeedbackSaved(false), 2000);
      setNewFeedback("");
      navigate("/intranet#reports");
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  // For any additional feedback after initial feedback
  const handleSubmitAdditionalFeedback = async () => {
    try {
      const reportRef = doc(db, "reports", year, "reports", id);
      await updateDoc(reportRef, {
        feedbackHistory: arrayUnion({
          text: newFeedback,
          feedbackBy: userData.name,
          feedbackOn: Timestamp.now(),
        }),
        status: "awaitingRead",
      });
      setFeedbackSaved(true);
      setTimeout(() => setFeedbackSaved(false), 2000);
      setNewFeedback("");
      navigate("/intranet#reports");
    } catch (error) {
      console.error("Error submitting additional feedback:", error);
    }
  };

  const handleGoBack = () => {
    navigate("/intranet#reports");
  };

  // --------------------- Display Helpers --------------------- //

  const renderAllFeedbackBlocks = () => {
    const hasLegacy = legacyFeedback && legacyFeedback.trim() !== "";

    return (
      <>
        {feedbackHistory.length > 0 && (
          <>
            <br />
            {feedbackHistory
              .slice()
              .reverse() // create a shallow copy, then reverse so the newest feedback is shown first
              .map((item, index) => {
                const feedbackDate = item.feedbackOn?.toDate
                  ? item.feedbackOn.toDate().toLocaleString()
                  : "";
                return (
                  <div key={index} className="feedback-block">
                    <p>
                      <b>
                        <i className="fa-regular fa-comment"></i> Feedback
                        submitted by
                      </b>{" "}
                      {feedbackDate && `${item.feedbackBy} on ${feedbackDate}`}
                    </p>
                    <div
                      className="report-content"
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                    <br />
                    <br />
                  </div>
                );
              })}
          </>
        )}
        {hasLegacy && (
          <div className="feedback-block">
            <p>
              <b>
                <i className="fa-regular fa-comment"></i> Feedback submitted by{" "}
              </b>{" "}
              {legacyFeedbackOn && `${legacyFeedbackBy} on ${legacyFeedbackOn}`}
            </p>

            <div
              className="report-content"
              dangerouslySetInnerHTML={{ __html: legacyFeedback }}
            />
          </div>
        )}
      </>
    );
  };

  // --------------------- Renders --------------------- //

  const renderStaffView = () => (
    <>
      <div className="button-group">
        <button className="go-back-btn" onClick={handleGoBack}>
          Go Back
        </button>
        {status !== "submitted" && (
          <>
            {status === "draft" && (
              <>
                <button
                  className="delete-draft-btn"
                  onClick={handleDeleteDraft}
                >
                  Delete Draft
                </button>
                <button
                  className="orange-btn"
                  onClick={handleSaveDraft}
                  disabled={!reportContent || status === "submitted"}
                >
                  Save Draft
                </button>
                {loading ? (
                  <div className="loading-container">
                    <Loading />
                  </div>
                ) : (
                  <button
                    className="submit-report-btn"
                    onClick={handleSubmit}
                    disabled={!reportContent || status === "submitted"}
                  >
                    Submit Report
                  </button>
                )}
                {draftSaved && (
                  <span className="draft-saved-message">
                    Draft successfully saved!
                  </span>
                )}
                {feedbackSaved && (
                  <span className="draft-saved-message">
                    Feedback successfully saved!
                  </span>
                )}
              </>
            )}
            {status === "submitted" && "Awaiting feedback"}
            {status === "awaitingRead" && (
              <button
                className="submit-report-btn"
                onClick={handleConfirmFeedback}
              >
                Confirm Read Feedback
              </button>
            )}
          </>
        )}
      </div>
      <br />

      {reportInfo && status === "draft" && (
        <>
          <div className="report-info">
            <h3>
              <i className="fa-solid fa-clipboard-check"></i> Dear {firstName},
              kindly ensure this is included in your report:
            </h3>
            <div dangerouslySetInnerHTML={{ __html: reportInfo }} />
          </div>
          <br />
        </>
      )}

      {status === "completed" && feedbackConfirmedBy && feedbackConfirmedOn && (
        <>
          <p>
            <b>
              <i className="fa-regular fa-circle-check"></i> Feedback confirmed
              by
            </b>{" "}
            {feedbackConfirmedBy} on {feedbackConfirmedOn}
          </p>
        </>
      )}

      {/* Show old + additional feedback */}
      {renderAllFeedbackBlocks()}

      {status === "draft" ? (
        <>
          <ReactQuill
            theme="snow"
            value={reportContent}
            onChange={setReportContent}
            placeholder="Write your report here..."
            style={{ height: "450px" }}
          />
          <br />
          <br />
          <br />
        </>
      ) : (
        <>
          {submittedAt && (
            <>
              <p>
                <b>
                  <i className="fa-regular fa-folder-open"></i> Report submitted
                  by
                </b>{" "}
                {userName} on {submittedAt.toLocaleString()}
              </p>
            </>
          )}

          <div
            className="report-content"
            dangerouslySetInnerHTML={{ __html: reportContent }}
          />
        </>
      )}
    </>
  );

  const renderAdminView = () => (
    <>
      <div className="button-group">
        <button className="go-back-btn" onClick={handleGoBack}>
          Go Back
        </button>
        {(status === "completed" || status === "awaitingRead") && (
          <button
            className="orange-btn"
            onClick={() => setShowAdditionalFeedback(!showAdditionalFeedback)}
          >
            {showAdditionalFeedback
              ? "Cancel Additional Feedback"
              : "Submit Additional Feedback"}
          </button>
        )}
      </div>

      {/* Additional feedback form when status is completed or awaitingRead */}
      {showAdditionalFeedback && (
        <div className="sticky-container">
          <button
            className="submit-report-btn"
            onClick={handleSubmitAdditionalFeedback}
            disabled={!newFeedback.trim()}
          >
            Submit Additional Feedback
          </button>
          <br />
          <br />
          <ReactQuill
            theme="snow"
            value={newFeedback}
            onChange={setNewFeedback}
            placeholder="Write additional feedback here."
            style={{ height: "150px" }}
          />
          <br />
          <br />
        </div>
      )}

      {/*
        If status is something else (like "submitted"), admin can do
        the first official feedback or feedback draft
      */}
      {status !== "completed" &&
        status !== "draft" &&
        status !== "awaitingRead" && (
          <div className="sticky-container">
            {/* <button
              className="orange-btn"
              onClick={handleSaveFeedbackDraft}
              disabled={!newFeedback.trim()}
            >
              Save Draft
            </button>{" "} */}
            <button
              className="submit-report-btn"
              onClick={handleSaveFeedback}
              disabled={!newFeedback.trim()}
            >
              Submit Feedback
            </button>{" "}
            {feedbackDraftSaved && (
              <span className="draft-saved-message">
                Admin feedback draft successfully saved!
              </span>
            )}
            <br />
            <br />
            <ReactQuill
              theme="snow"
              value={newFeedback}
              onChange={setNewFeedback}
              placeholder="Write feedback on the report here."
              style={{ height: "150px" }}
            />
            <br />
            <br />
          </div>
        )}
      <br />

      {status === "completed" && feedbackConfirmedBy && feedbackConfirmedOn && (
        <p>
          <b>
            <i className="fa-regular fa-circle-check"></i> Feedback confirmed by
          </b>{" "}
          {feedbackConfirmedBy} on {feedbackConfirmedOn}
        </p>
      )}

      {renderAllFeedbackBlocks()}

      {status !== "draft" && (
        <p>
          <b>
            <i className="fa-regular fa-folder-open"></i> Report submitted by
          </b>{" "}
          {userName} {submittedAt && `on ${submittedAt.toLocaleString()}`}
        </p>
      )}
      {status === "draft" && <span className="orange">Saved as draft.</span>}
      <div
        className="report-content"
        dangerouslySetInnerHTML={{ __html: reportContent }}
      />
      {reportInfo && (
        <div className="report-info">
          <br />
          <h3>
            <i className="fa-solid fa-clipboard-check"></i> Report Requirements
            for {userName}:
          </h3>
          <div dangerouslySetInnerHTML={{ __html: reportInfo }} />
        </div>
      )}
      <br />
    </>
  );

  // --------------------- Main Render --------------------- //

  return (
    <>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="row2" id="flex">
        <div className="main">
          <div className="report-container article">
            {loading ? (
              <Loading />
            ) : !hasAccess ? (
              <div className="no-access-container">
                <h2>No Access</h2>
                <p>
                  You do not have permission to view this report. Redirecting...
                </p>
              </div>
            ) : (
              <div className="report-page">
                <h2>Report for {week}</h2>
                <p>Report by {userName}</p>
                <br />

                {isAdmin ? renderAdminView() : renderStaffView()}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Report;
