// GlobalFileUpload.js
import React, { useState } from "react";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const GlobalFileUpload = () => {
  const [uploadedFileURL, setUploadedFileURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setIsLoading(true);
    const storage = getStorage();
    const filePath = `global/uploads/${file.name}`;
    const fileRef = storageRef(storage, filePath);
    try {
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);
      setUploadedFileURL(downloadURL);
    } catch (error) {
      console.error("File upload error:", error);
    }
    setIsLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(uploadedFileURL).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <span className="global-file-upload">
      {isLoading ? (
        <p>Uploading...</p>
      ) : (
        <>
          <input
            id="file-upload-global"
            type="file"
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <label
            htmlFor="file-upload-global"
            className="file-upload-button section"
          >
            <i className="fa-regular fa-file"></i> Upload File
          </label>
          {isLoading && <p>Uploading...</p>}
          {uploadedFileURL && (
            <button className="move-button" onClick={handleCopy}>
              {copied ? "Copied" : "Get link"}
            </button>
          )}
        </>
      )}
    </span>
  );
};

export default GlobalFileUpload;
