import React from "react";
import { Link, useLocation } from "react-router-dom";

const MyLink = ({ to, ...props }) => {
  const location = useLocation();
  const isExternal = to.startsWith("http://") || to.startsWith("https://");

  if (isExternal) {
    return <a href={to} target="_blank" rel="noopener noreferrer" {...props} />;
  }

  const isAdminRoute = location.pathname.startsWith("/admin");
  const modifiedPath = isAdminRoute ? `/admin${to}` : to;

  return <Link to={modifiedPath} {...props} />;
};

export default MyLink;
