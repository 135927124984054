// Reports.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../../AuthProvider";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  serverTimestamp,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Loading from "../../../../components/Loading/Loading";
import ReactQuill from "react-quill"; // For editing report info
import "react-quill/dist/quill.snow.css";
import { db } from "../../../../firebase"; // Firestore config
import "./Reports.css";

const sortReportsByWeek = (reports) => {
  return reports.sort((a, b) => {
    const weekA = parseInt(a.week.replace("Week ", ""));
    const weekB = parseInt(b.week.replace("Week ", ""));
    return weekB - weekA;
  });
};

const canCreateReport = () => {
  const today = new Date().getDay();
  return today >= 4 || today === 0;
};

const Reports = ({ onAdminReportsToReview }) => {
  const { isAdmin, userData } = useAuth();
  const navigate = useNavigate();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [yearLoading, setYearLoading] = useState(false);
  const years = [2024, 2025];

  // Report Information Template state
  const [reportInfo, setReportInfo] = useState("");
  const [editingReportInfo, setEditingReportInfo] = useState(false);
  const [reportInfoSaved, setReportInfoSaved] = useState(false);

  // New states to handle staff-specific info
  const [staffUsers, setStaffUsers] = useState([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");

  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const startDayOfWeek = startDate.getDay();
    const dayOfYear =
      Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1;
    return Math.ceil((dayOfYear + (startDayOfWeek - 1)) / 7);
  };

  const getDaysUntilFriday9PM = (week, reports) => {
    const today = new Date();
    const currentDay = today.getDay();
    const currentTime = today.getHours() * 60 + today.getMinutes();
    const daysUntilFriday = (5 - currentDay + 7) % 7;
    const friday9PMTime = 21 * 60;
    const reportExists = reports.some(
      (report) =>
        report.week === `Week ${week}` && report.status === "submitted"
    );

    if (currentDay === 5 && currentTime > friday9PMTime && reportExists) {
      return 7;
    }
    if ((currentDay === 4 || currentDay === 5) && reportExists) {
      return 7 + daysUntilFriday;
    }
    return daysUntilFriday;
  };

  const currentWeek = getWeekNumber(new Date());
  const daysUntilFriday9PM = getDaysUntilFriday9PM(currentWeek, reports);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (!userData) return;
        setLoading(true);

        let reportsRef = collection(
          db,
          "reports",
          selectedYear.toString(),
          "reports"
        );

        let q = isAdmin
          ? query(reportsRef)
          : query(reportsRef, where("userId", "==", userData.email));

        const querySnapshot = await getDocs(q);
        const fetchedReports = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setReports(sortReportsByWeek(fetchedReports));
        setLoading(false);
        setYearLoading(false);

        if (isAdmin && typeof onAdminReportsToReview === "function") {
          const toReview = fetchedReports.filter(
            (report) => report.status === "submitted"
          ).length;
          onAdminReportsToReview(toReview);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
        setLoading(false);
        setYearLoading(false);
      }
    };

    fetchReports();
  }, [userData, isAdmin, onAdminReportsToReview, currentWeek, selectedYear]);

  // Inside your Reports.jsx file, modify the fetchStaffUsers useEffect as follows:
  useEffect(() => {
    const fetchStaffUsers = async () => {
      try {
        const usersRef = collection(db, "staff");
        const q = query(usersRef);
        const querySnapshot = await getDocs(q);
        // Filter users to only include those with "reports" in their intranet_access array
        const users = querySnapshot.docs
          .map((doc) => doc.data())
          .filter(
            (staff) =>
              staff.intranet_access && staff.intranet_access.includes("reports")
          );
        setStaffUsers(users);
        if (users.length > 0) {
          setSelectedUserEmail(users[0].email);
        }
      } catch (error) {
        console.error("Error fetching staff users:", error);
      }
    };
    if (isAdmin) fetchStaffUsers();
  }, [isAdmin]);

  // For admin: fetch report information for selected staff user
  useEffect(() => {
    const fetchReportInformation = async () => {
      try {
        if (!selectedUserEmail) return;
        const docRef = doc(db, "staff", selectedUserEmail);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setReportInfo(docSnap.data().reportInformation || "");
        } else {
          setReportInfo(`Enter report instructions for ${selectedUserEmail}`);
        }
      } catch (error) {
        console.error("Error fetching report information:", error);
      }
    };
    if (isAdmin) fetchReportInformation();
  }, [selectedUserEmail, isAdmin]);

  const handleSaveReportInfo = async () => {
    try {
      const docRef = doc(db, "staff", selectedUserEmail);
      await setDoc(docRef, { reportInformation: reportInfo }, { merge: true });
      setReportInfoSaved(true);
      setEditingReportInfo(false);
      setTimeout(() => setReportInfoSaved(false), 2000);
      alert("Report Instructions saved!");
    } catch (error) {
      console.error("Error saving report information:", error);
    }
  };

  const handleCreateReport = async (week) => {
    try {
      const newReportRef = await addDoc(
        collection(db, "reports", selectedYear.toString(), "reports"),
        {
          userId: userData.email,
          userName: userData.name,
          week: `Week ${week}`,
          content: "",
          status: "draft",
          submittedAt: null,
          feedback: "",
        }
      );
      navigate(`/reports/${selectedYear}/${newReportRef.id}`);
    } catch (error) {
      console.error("Error creating report:", error);
    }
  };

  const handleEditReport = (reportId) => {
    navigate(`/reports/${selectedYear}/${reportId}`);
  };

  const reportExistsForWeek = (week, status) => {
    return reports.some(
      (report) => report.week === `Week ${week}` && report.status === status
    );
  };

  const completedExistsForCurrentWeek = reportExistsForWeek(
    currentWeek,
    "completed"
  );

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    setYearLoading(true);
  };

  const renderYearSelector = () => (
    <div className="year-selector">
      <label htmlFor="year">Select Year: </label>
      <select id="year" value={selectedYear} onChange={handleYearChange}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );

  const renderStaffView = () => {
    const draftExistsForCurrentWeek = reportExistsForWeek(currentWeek, "draft");
    const submittedExistsForCurrentWeek =
      reportExistsForWeek(currentWeek, "submitted") ||
      reportExistsForWeek(currentWeek, "completed");
    const awaitingReadExistsForCurrentWeek = reportExistsForWeek(
      currentWeek,
      "awaitingRead"
    );
    const currentDay = new Date().getDay();
    const showOverdueMessage =
      !submittedExistsForCurrentWeek &&
      !awaitingReadExistsForCurrentWeek &&
      (currentDay === 6 || currentDay === 0);

    return (
      <>
        {renderYearSelector()}
        {selectedYear === new Date().getFullYear() && (
          <>
            <p>
              Reports are due every Friday. Next report is due{" "}
              <b>
                {daysUntilFriday9PM === 0 &&
                !reportExistsForWeek(currentWeek, "submitted")
                  ? "today"
                  : daysUntilFriday9PM === 1
                  ? "tomorrow"
                  : `in ${daysUntilFriday9PM} days`}
              </b>
              .
            </p>
            {submittedExistsForCurrentWeek && (
              <p className="report-submitted-p">
                ✅ You have submitted this week's report.
              </p>
            )}
          </>
        )}
        {selectedYear === new Date().getFullYear() &&
          showOverdueMessage &&
          !awaitingReadExistsForCurrentWeek &&
          !completedExistsForCurrentWeek && (
            <div>
              <p className="overdue-warning">
                ⚠️ You have not submitted the report for Week {currentWeek}.
                Please submit it latest by Sunday.
              </p>
            </div>
          )}
        <br />
        {selectedYear === new Date().getFullYear() &&
          !draftExistsForCurrentWeek &&
          !submittedExistsForCurrentWeek &&
          !awaitingReadExistsForCurrentWeek &&
          !completedExistsForCurrentWeek &&
          canCreateReport() && (
            <table className="report-table">
              <td>
                <h3>Week {currentWeek}</h3>
              </td>
              <td></td>
              <td>
                <button
                  type="button"
                  className="create-report-btn"
                  onClick={() => handleCreateReport(currentWeek)}
                >
                  Create Report
                </button>
              </td>
            </table>
          )}
        {reports &&
          reports
            .filter((report) => report.status !== "completed")
            .map((report) => (
              <div key={report.id} className="report-table">
                <h3>{report.week}</h3>
                {report.status === "draft" ? (
                  <>
                    <p>
                      <i className="fa-solid fa-user-pen"></i> Draft created
                    </p>
                    <button
                      type="button"
                      className="orange-btn"
                      onClick={() => handleEditReport(report.id)}
                    >
                      Edit Report
                    </button>
                  </>
                ) : report.status === "submitted" ||
                  report.status === "feedbackDraft" ? (
                  <>
                    <p>
                      <i className="fa-regular fa-clock"></i> Awaiting feedback
                    </p>
                    <button
                      type="button"
                      className="view-report-btn"
                      onClick={() => handleEditReport(report.id)}
                    >
                      View Report
                    </button>
                  </>
                ) : report.status === "awaitingRead" ? (
                  <>
                    <p>
                      <i className="fa-regular fa-bell"></i> Feedback available
                    </p>
                    <button
                      type="button"
                      className="submit-report-btn"
                      onClick={() => handleEditReport(report.id)}
                    >
                      Read Feedback
                    </button>
                  </>
                ) : report.status === "completed" ? (
                  <>
                    <p>
                      <i className="fa-solid fa-check"></i> Completed
                    </p>
                    <button
                      type="button"
                      className="view-report-btn"
                      onClick={() => handleEditReport(report.id)}
                    >
                      View
                    </button>
                  </>
                ) : (
                  "invalid"
                )}
              </div>
            ))}
        <br />
        <h3>Completed Reports</h3>
        {reports &&
          reports
            .filter((report) => report.status === "completed")
            .map((report) => (
              <table key={report.id} className="report-table">
                <td>
                  <h3>{report.week}</h3>
                </td>
                <td></td>
                <td>
                  <button
                    type="button"
                    className="view-report-btn"
                    onClick={() => handleEditReport(report.id)}
                  >
                    View Report
                  </button>
                </td>
              </table>
            ))}
      </>
    );
  };

  const renderAdminView = () => {
    return (
      <>
        {renderYearSelector()}
        <br />

        <div className="staff-selector">
          <button
            className="remark-button blue"
            onClick={() => setEditingReportInfo((prev) => !prev)}
          >
            {editingReportInfo
              ? "Close Staff Instructions"
              : "Edit Staff Instructions"}
          </button>
          {editingReportInfo && (
            <>
              <br />
              <br />
              <label htmlFor="staffSelect">Select Staff: </label>
              <select
                id="staffSelect"
                value={selectedUserEmail}
                onChange={(e) => setSelectedUserEmail(e.target.value)}
              >
                {staffUsers.map((staff) => (
                  <option key={staff.email} value={staff.email}>
                    {staff.name} ({staff.email})
                  </option>
                ))}
              </select>{" "}
              <button
                className="add-student-button"
                onClick={handleSaveReportInfo}
              >
                Update Instructions
              </button>
            </>
          )}
        </div>

        <br />
        <div className="report-info-admin">
          {editingReportInfo ? (
            <>
              <ReactQuill
                theme="snow"
                value={reportInfo}
                onChange={setReportInfo}
                style={{ height: "180px" }}
              />
              <br />
              <br />
              {reportInfoSaved && (
                <p className="draft-saved-message">Report information saved!</p>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <br />
        {reports &&
          reports.length > 0 &&
          reports.filter(
            (report) =>
              report.status !== "completed" && report.status !== "draft"
          ).length > 0 && <h3>Active Reports</h3>}

        {reports && reports.length > 0 ? (
          reports
            .filter((report) => report.status !== "completed")
            .filter((report) => report.status !== "draft")
            .map((report) => (
              <>
                <table key={report.id} className="report-table">
                  <td>
                    <h3>
                      {report.week} - {report.userName}
                    </h3>
                  </td>
                  {report.status === "submitted" ||
                  report.status === "feedbackDraft" ? (
                    <>
                      <td>
                        <i className="fa-solid fa-pencil"></i> Needs feedback
                      </td>
                      <td>
                        <button
                          type="button"
                          className="submit-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          Review Report
                        </button>
                      </td>
                    </>
                  ) : report.status === "awaitingRead" ? (
                    <>
                      <td>
                        <i className="fa-regular fa-clock"></i> Awaiting
                        confirmation
                      </td>
                      <td>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          View Report
                        </button>
                      </td>
                    </>
                  ) : (
                    "invalid"
                  )}
                </table>
              </>
            ))
        ) : (
          <p>You have no reports to go through - woho!</p>
        )}
        <br />
        <h3>Completed Reports</h3>
        {reports && reports.length > 0 ? (
          reports
            .filter((report) => report.status === "completed")
            .map((report) => (
              <table key={report.id} className="report-table">
                <td>
                  <h3>{report.userName}</h3>
                </td>
                <td>
                  <h3>{report.week}</h3>
                </td>
                <td>
                  <button
                    type="button"
                    className="view-report-btn"
                    onClick={() => handleEditReport(report.id)}
                  >
                    View Report
                  </button>
                </td>
              </table>
            ))
        ) : (
          <p>No reports available</p>
        )}
      </>
    );
  };

  const renderView = () => {
    if (loading) {
      return (
        <div>
          Loading
          <Loading />
        </div>
      );
    }
    if (yearLoading) {
      return <div>Loading reports for {selectedYear}...</div>;
    }
    return isAdmin ? renderAdminView() : renderStaffView();
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="row2" id="flex">
          <div className="main">
            <h2>Reports</h2>
            <div className="report-list">
              {loading ? <Loading /> : renderView()}
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
