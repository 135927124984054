import React, { useState, useRef, useEffect } from "react";
import MyLink from "../MyLink/MyLink";
import "./Section.css";
import LoadingIndicator from "../Loading/LoadingIndicator";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import { db } from "../../firebase";
import GlobalFileUpload from "./GlobalFileUpload";

const Section = ({
  articleId,
  header,
  coming,
  sectionTitle,
  day,
  month,
  year,
  readMore,
  customReadMoreLink,
  description,
  sectionDescription,
  img,
  instructor,
  places,
  openingHours,
  isAdmin,
  titlespan,
  sectionId,
  onSectionChange,
  projectId,
  isArticle,
  noDate,
  onUpdateArticle,
  onUpdateSection,
  onDeleteSection,
  isAbout,
  aboutId,
  canMoveUp,
  canMoveDown,
  moveUp,
  moveDown,
}) => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [currentImageURL, setCurrentImageURL] = useState(img);
  const [currentFileURL, setCurrentFileURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const sectionImageUploadRef = useRef(null);
  const sectionDescRef = useRef(null);

  const [updatedFields, setUpdatedFields] = useState({
    header,
    description,
    day,
    month,
    year,
  });

  const [updatedSectionFields, setUpdatedSectionFields] = useState({
    sectionId,
    sectionTitle,
    sectionDescription,
  });

  const [confirmationText, setConfirmationText] = useState("");
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    const clearText = () => {
      setErrorText("");
      setConfirmationText("");
    };
    const timer = setTimeout(clearText, 3000);
    return () => clearTimeout(timer);
  }, [errorText, confirmationText]);

  useEffect(() => {
    setChangedSections([]);
  }, [sectionId]);

  const updateArticleImage = async (id, imageUrl, newImagePath) => {
    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";
    const docId = isArticle ? articleId : isAbout ? aboutId : projectId;
    const docRef = doc(db, collection, docId);
    try {
      await updateDoc(docRef, { img: imageUrl, imgPath: newImagePath });
      setCurrentImageURL(imageUrl);
    } catch (error) {
      console.error("Error updating image: ", error);
    }
  };

  const uploadImage = async (file, id, sectionId) => {
    if (!file) return { error: "No file provided for upload" };
    setIsLoading(true);
    const storage = getStorage();
    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";
    const newImagePath = `${collection}/${id}/${file.name}`;
    const fileRef = storageRef(storage, newImagePath);
    try {
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);
      setIsLoading(false);
      return { downloadURL, newImagePath };
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorText("Error uploading file");
      setIsLoading(false);
      return { error };
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || (!articleId && !projectId && !aboutId)) {
      setErrorText("No file or article/wwd id");
      return;
    }
    if (file.size > 500 * 1024) {
      const fileSizeInKB = Math.round(file.size / 1024);
      setErrorText(`Image size exceeds 500kB limit. (${fileSizeInKB}kB)`);
      return;
    }
    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";
    const id = isArticle ? articleId : isAbout ? aboutId : projectId.toString();
    const docRef = doc(db, collection, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().imgPath) {
      const oldImagePath = docSnap.data().imgPath;
      const oldImageRef = storageRef(getStorage(), oldImagePath);
      deleteObject(oldImageRef).catch((error) => {
        if (error.code === "storage/object-not-found") {
          console.log("Old image file does not exist, no deletion necessary.");
        } else {
          console.error("Error removing previous image: ", error);
        }
      });
    }
    const { downloadURL, newImagePath } = await uploadImage(
      file,
      id,
      sectionId
    );
    if (downloadURL) {
      updateArticleImage(id, downloadURL, newImagePath);
      setCurrentImageURL(downloadURL);
      setIsLoading(false);
    }
  };

  const handleSectionImageUpload = async (event, sectionId) => {
    const file = event.target.files[0];
    if (!file) return;
    setIsLoading(true);
    const collection = isArticle
      ? "articles"
      : isAbout
      ? "aboutPages"
      : "whatWeDo";
    const docId = isArticle ? articleId : isAbout ? aboutId : projectId;
    const newImagePath = `${collection}/${docId}/sections/${sectionId}/${file.name}`;
    const fileRef = storageRef(getStorage(), newImagePath);
    try {
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);
      const docRef = doc(db, collection, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const sections = docSnap.data().sections || [];
        let oldImagePath = null;
        const updatedSections = sections.map((section) => {
          if (
            section.section_id &&
            sectionId &&
            section.section_id.toString() === sectionId.toString()
          ) {
            oldImagePath = section.imgPath;
            return {
              ...section,
              section_image: downloadURL,
              imgPath: newImagePath,
            };
          }
          return section;
        });
        if (oldImagePath && oldImagePath !== newImagePath) {
          const oldImageRef = storageRef(getStorage(), oldImagePath);
          await deleteObject(oldImageRef).catch((error) => {
            console.error("Error removing previous section image:", error);
          });
        }
        await updateDoc(docRef, { sections: updatedSections });
      }
      setCurrentImageURL(downloadURL);
    } catch (error) {
      console.error("Error during section image upload:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateArticle = async () => {
    setIsUpdating(true);
    await onUpdateArticle(updatedFields);
    setConfirmationText("Article updated successfully!");
    setIsUpdating(false);
    setChangedSections((prev) => prev.filter((id) => id !== sectionId));
  };

  const handleUpdateSection = async () => {
    setIsUpdating(true);
    await onUpdateSection(updatedSectionFields);
    setConfirmationText("Section updated successfully!");
    setIsUpdating(false);
    setChangedSections((prev) => prev.filter((id) => id !== sectionId));
  };

  const handleDeleteSection = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this section?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await onDeleteSection();
      setConfirmationText("Section deleted successfully!");
      setIsDeleting(false);
    }
  };

  const [changedSections, setChangedSections] = useState([]);
  const renderAdminView = () => {
    const [originalFields, setOriginalFields] = useState({
      header,
      description,
      day,
      month,
      year,
    });

    const handleFieldChange = (fieldName, value) => {
      if (
        ["header", "description", "day", "month", "year"].includes(fieldName)
      ) {
        setUpdatedFields((prev) => ({ ...prev, [fieldName]: value }));
        setChangedSections((prev) => {
          const isChanged = value !== originalFields[fieldName];
          return isChanged
            ? [...new Set([...prev, sectionId])]
            : prev.filter((id) => id !== sectionId);
        });
      } else if (["sectionTitle", "sectionDescription"].includes(fieldName)) {
        if (!sectionId)
          return console.error("sectionId is required for updating sections.");
        setUpdatedSectionFields((prev) => ({ ...prev, [fieldName]: value }));
        setChangedSections((prev) => {
          const isChanged = value !== originalFields[fieldName];
          return isChanged
            ? [...new Set([...prev, sectionId])]
            : prev.filter((id) => id !== sectionId);
        });
      } else {
        console.error(`Invalid field name: ${fieldName}`);
      }
    };

    useEffect(() => {
      setOriginalFields({ header, description, day, month, year });
    }, [header, description, day, month, year]);

    const quillModules = {
      toolbar: [
        ["bold", "italic", "underline"],
        ["link", "blockquote"],
        ["clean"],
      ],
    };

    const quillFormats = ["bold", "italic", "underline", "link", "blockquote"];

    return (
      <div
        className={`section ${isAdmin ? "admin-section" : "border"} ${
          changedSections.includes(sectionId) ? "unsaved" : ""
        }`}
      >
        {confirmationText && (
          <div className="confirmation-message">
            <i className="fa-regular fa-circle-check"></i> {confirmationText}
          </div>
        )}
        {errorText && (
          <div className="error-message-2">
            <i className="fa-regular fa-face-frown-open"></i> {errorText}
          </div>
        )}
        <div className="flex-image-text">
          <div>
            <div>
              {header ? (
                <div>
                  <input
                    type="text"
                    value={updatedFields.header}
                    className="h2 headerInput"
                    onChange={(e) =>
                      handleFieldChange("header", e.target.value)
                    }
                  />
                  {coming && <span className="coming">{coming}</span>}
                  <input
                    type="file"
                    id={`file-upload-article-${articleId}`}
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor={`file-upload-article-${articleId}`}
                    className="file-upload-button header"
                  >
                    <i className="fa-regular fa-image"></i> Upload{" "}
                    <span className="hideOnPhone">Image</span>
                  </label>
                  {!projectId && !aboutId && (
                    <div className="date dateInput">
                      <h5>
                        <i className="fa-regular fa-calendar"></i> Posted on{" "}
                        <select
                          value={updatedFields.month}
                          onChange={(e) =>
                            handleFieldChange("month", e.target.value)
                          }
                        >
                          {months.map((m, i) => (
                            <option key={i} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                        <select
                          value={updatedFields.day}
                          onChange={(e) =>
                            handleFieldChange("day", e.target.value)
                          }
                        >
                          {days.map((d) => (
                            <option key={d} value={d}>
                              {d}
                            </option>
                          ))}
                        </select>
                        <input
                          type="text"
                          className="year"
                          value={updatedFields.year}
                          onChange={(e) =>
                            handleFieldChange("year", e.target.value)
                          }
                        />
                      </h5>
                    </div>
                  )}
                  <ReactQuill
                    theme="snow"
                    value={updatedFields.description}
                    onChange={(value) =>
                      handleFieldChange("description", value)
                    }
                    placeholder="Write your description here..."
                    modules={quillModules}
                    formats={quillFormats}
                    className="quill-input"
                  />
                  <span
                    onClick={() => handleUpdateArticle(articleId)}
                    className="div-readmore status edit"
                  >
                    Update section{" "}
                    {isUpdating ? (
                      <span className="rolling-loading-animation small blue"></span>
                    ) : (
                      <i className="fa-regular fa-circle-check"></i>
                    )}
                  </span>{" "}
                  <GlobalFileUpload />
                </div>
              ) : (
                <div>
                  <div className="h3-file-upload">
                    <input
                      type="text"
                      value={updatedSectionFields.sectionTitle || ""}
                      onChange={(e) =>
                        handleFieldChange("sectionTitle", e.target.value)
                      }
                      className="h3 titleInput"
                      placeholder="Write a title"
                    />
                    <input
                      type="file"
                      id={`file-upload-${sectionId}`}
                      ref={sectionImageUploadRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleSectionImageUpload(e, sectionId)}
                    />
                    <label
                      htmlFor={`file-upload-${sectionId}`}
                      className="file-upload-button section"
                    >
                      <i className="fa-regular fa-image"></i> Upload Image
                    </label>

                    {isAdmin && (
                      <>
                        <ReactQuill
                          ref={sectionDescRef}
                          theme="snow"
                          value={updatedSectionFields.sectionDescription}
                          onChange={(value) =>
                            handleFieldChange("sectionDescription", value)
                          }
                          placeholder="Write section description here..."
                          modules={quillModules}
                          formats={quillFormats}
                          className="quill-input"
                        />
                        <span
                          onClick={handleUpdateSection}
                          className="div-readmore status edit"
                        >
                          Update section{" "}
                          {isUpdating ? (
                            <span className="rolling-loading-animation small blue"></span>
                          ) : (
                            <i className="fa-regular fa-circle-check"></i>
                          )}
                        </span>{" "}
                        {isAdmin && sectionId && (
                          <span className="move-buttons">
                            {canMoveUp && (
                              <button
                                onClick={moveUp}
                                className="move-button up"
                              >
                                <i className="fa-solid fa-arrow-up"></i> Up
                              </button>
                            )}
                            {canMoveDown && (
                              <button
                                onClick={moveDown}
                                className="move-button down"
                              >
                                <i className="fa-solid fa-arrow-down"></i> Down
                              </button>
                            )}
                          </span>
                        )}
                        <span
                          onClick={() => handleDeleteSection(sectionId)}
                          className="div-readmore status edit lessSize"
                        >
                          Delete section{" "}
                          {isDeleting ? (
                            <span className="rolling-loading-animation small"></span>
                          ) : (
                            <i className="fa-regular fa-circle-xmark"></i>
                          )}
                        </span>{" "}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            {readMore && (
              <div>
                <MyLink to={customReadMoreLink || `/newsletter/${articleId}`}>
                  <div className="div-readmore">
                    <p className="readmore">
                      {readMore} <i className="fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </MyLink>
              </div>
            )}
          </div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <img
              src={currentImageURL}
              className="img"
              alt={header || sectionTitle || "Image"}
            />
          )}
        </div>
        {!readMore && <br />}
      </div>
    );
  };

  const renderUserView = () => {
    return (
      <div className="section">
        <div className="flex-image-text">
          <div>
            <div className="flex-container-2 subheader">
              {header ? (
                <>
                  <div className="header-h2">
                    <h2>{header}</h2>
                  </div>
                  {!projectId && !noDate && !aboutId && (
                    <h5>
                      <div className="date">
                        <i className="fa-regular fa-calendar"></i>
                        Posted on {month} {day}, {year}
                      </div>
                    </h5>
                  )}
                  {coming && <span className="coming">{coming}</span>}
                  <div
                    className="section-description"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(description),
                    }}
                  />
                </>
              ) : null}
              {!header || isAdmin ? (
                <>
                  {sectionTitle ? (
                    <>
                      <h3 className="section-h3">{sectionTitle}</h3>
                      <span>{titlespan}</span>
                    </>
                  ) : null}
                  <div
                    className="section-sectionDescription"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(sectionDescription),
                    }}
                  />
                  {currentFileURL && (
                    <div className="file-link">
                      <MyLink to={currentFileURL}>View File</MyLink>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            {readMore && (
              <div>
                <MyLink to={customReadMoreLink || `/newsletter/${articleId}`}>
                  <div className="div-readmore">
                    <p className="readmore">
                      {readMore} <i className="fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </MyLink>
              </div>
            )}
          </div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <img
              src={currentImageURL}
              className="img"
              alt={header || sectionTitle || "Image"}
            />
          )}
        </div>
        {!readMore && <br />}
      </div>
    );
  };

  return isAdmin ? renderAdminView() : renderUserView();
};

export default Section;
