import React, { useState } from "react";
import "./Innsamling.css";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";

function JobPostForm() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    students: "",
    location: "",
    contactPerson: "",
    location: "",
    email: "",
    phone: "",
    consent1: false,
    consent2: false,
    deletionCode: "",
  });
  const [submissionStatus, setSubmissionStatus] = useState({
    success: false,
    message: "",
  });

  const generateDeletionCode = () => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setFormData({ ...formData, deletionCode: result });
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    let newValue = value;

    if (type === "checkbox") {
      newValue = checked;
    } else if (name === "students") {
      newValue = value ? parseInt(value, 10) : ""; // Convert the input value to a number for 'students'
    } else if (type === "text") {
      if (name !== "description" && value.length > 50) {
        newValue = value.slice(0, 50);
      }
      if (name === "description" && value.length > 100) return;
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure the formData includes deletionCode before submitting
    if (!formData.deletionCode) {
      alert("Please provide a deletion code.");
      return;
    }

    try {
      await addDoc(collection(db, "innsamlinger"), {
        ...formData,
        // status: "needs-approval",
        status: "approved",
      });
      setSubmissionStatus({
        success: true,
        message: "Jobb annonsert!",
      });
      // Reset the form data
      setFormData({
        title: "",
        description: "",
        students: "",
        location: "",
        contactPerson: "",
        email: "",
        phone: "",
        consent1: false,
        consent2: false,
        deletionCode: "",
      });

      setTimeout(() => {
        setSubmissionStatus({ success: false, message: "" });
      }, 5000);
    } catch (error) {
      console.error("Error writing document: ", error);
      setSubmissionStatus({
        success: false,
        message:
          "Kunne ikke opprette utlysning. Prøv på nytt eller ta kontakt.",
      });
    }
  };

  return (
    <div className="greyBorder">
      <form onSubmit={handleSubmit} className="job-post-form">
        <div className="step">
          <div className="information">
            <h3>
              <span className="steg">1</span> Jobbeskrivelse
            </h3>
          </div>
          <div className="form-group">
            <label htmlFor="title"></label>
            <div className="label-and-counter">
              Overskrift - Hva slags jobb er det?{" "}
              <span className="counter">{formData.title.length}/50</span>
            </div>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              maxLength="50"
              required
            />
          </div>

          <div className="form-group">
            <div className="label-and-counter">
              <label htmlFor="description">Beskrivelse</label>
              <span className="counter">{formData.description.length}/100</span>
            </div>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              maxLength="100"
            ></textarea>
          </div>

          <div className="form-group">
            <div className="label-and-counter">
              <label htmlFor="students">Antall elever</label>
            </div>
            <input
              type="number"
              id="students"
              name="students"
              value={formData.students}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <div className="label-and-counter">
              <label htmlFor="location">
                Hvor skal elevene jobbe? (Adresse)
              </label>
              <span className="counter">{formData.location.length}/100</span>
            </div>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
              maxLength="50"
            />
          </div>
        </div>
        <div className="step">
          <div className="information">
            <h3>
              <span className="steg">2</span> Kontaktinfo
            </h3>
          </div>

          <div className="form-group">
            <div className="label-and-counter">
              <label htmlFor="contactPerson">Kontaktperson</label>
              <span className="counter">
                {formData.contactPerson.length}/50
              </span>
            </div>
            <input
              type="text"
              id="contactPerson"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
              required
              maxLength="50"
            />
          </div>

          <div className="form-group">
            <div className="label-and-counter">
              <label htmlFor="email">E-post</label>
              <span className="counter">{formData.email.length}/50</span>
            </div>
            <input
              type="email" // Updated type for email validation
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              maxLength="50"
            />
          </div>

          <div className="form-group">
            <div className="label-and-counter">
              <label htmlFor="phone">Telefonnummer</label>
            </div>
            <input
              type="number"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              maxLength="15"
            />
          </div>
        </div>
        <div className="step">
          <div className="information">
            <h3>
              <span className="steg">3</span> Slettekode
            </h3>
          </div>
          <div className="form-group">
            <label htmlFor="deletionCode">
              Opprett en slettekode som du skriver inn under "ledige jobber" når
              du har funnet noen til utlysningen din for å slette den.
              <br />
              <br />
              Slettekoden bør ikke være et passord eller noe som er veldig
              enkelt å gjette.
              <br />
              <br />
              Klikk
              <button
                type="button"
                onClick={generateDeletionCode}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                her
              </button>{" "}
              for å generere en tilfeldig slettekode.
            </label>
            <br />
            <br />
            <input
              type="text"
              id="deletionCode"
              name="deletionCode"
              value={formData.deletionCode || ""}
              onChange={handleChange}
              placeholder="Skriv inn slettekoden her"
              required
            />
            <br />
            <br />
            <b>Du må ta vare på slettekoden for å kunne slette utlysningen!</b>
          </div>
        </div>
        <div className="step">
          <div className="information">
            <h3>
              <span className="steg">4</span> Samtykke
            </h3>
          </div>
          <div className="form-group">
            <input
              type="checkbox"
              id="consent1"
              name="consent1"
              value={formData.consent1 || false}
              onChange={(e) =>
                setFormData({ ...formData, consent1: e.target.checked })
              }
              required
            />
            <label htmlFor="consent1" className="consent">
              Jeg gir samtykke til at The Watoto Program og Skullerud skole
              bruker opplysningene i forbindelse med gjennomføring av
              innsamlingen. Opplysninger vil ikke bli gitt videre uten samtykke,
              og slettes innen 6 måneder etter gjennomføring av arrangementet.
            </label>
          </div>
          <div className="form-group">
            <input
              type="checkbox"
              id="consent2"
              name="consent2"
              value={formData.consent2 || false}
              onChange={(e) =>
                setFormData({ ...formData, consent2: e.target.checked })
              }
              required
            />
            <label htmlFor="consent2" className="consent">
              Jeg gir samtykke til at Skullerud skole kan kontakte meg eller min
              bedrift om å ansette elever i forbindelse med gjennomføring av
              innsamling senere år. Ingen tredjeparter vil bli gitt din
              kontaktinfo.
            </label>
          </div>
        </div>
        <button type="submit" className="submit-stilling-btn">
          Utlys stilling
        </button>
      </form>
      {submissionStatus.message && (
        <div
          className={
            submissionStatus.success ? "success-message" : "error-message"
          }
        >
          {submissionStatus.message}
        </div>
      )}
      {/* <p style={{ fontSize: "0.8em" }}>
        <i class="fa-solid fa-circle-info"></i> Det vil ta noe tid før
        stillingen blir synlig under "Utlyste stillinger" da vi gjennomgår alle
        henvendelser manuelt.
      </p> */}
      <br />
    </div>
  );
}

export default JobPostForm;
