// Update IncomeExpenses to recalculate using the passed-in sponsors prop:
import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const IncomeExpenses = ({ sponsors }) => {
  const [totalMonthlyIncomeKES, setTotalMonthlyIncomeKES] = useState(0);
  const [totalExpensesKES, setTotalExpensesKES] = useState(0);
  const [inputExpensesKES, setInputExpensesKES] = useState(0);
  const [percentageReached, setPercentageReached] = useState(0);

  // Hardcoded exchange rates
  const exchangeRates = {
    KES: 1,
    USD: 129.8701,
    NOK: 12.19512,
  };

  const db = getFirestore();
  const adminDocRef = doc(db, "admin", "income-expenses");

  // Recalculate income immediately when sponsors change
  useEffect(() => {
    if (sponsors && sponsors.length > 0) {
      const activeSponsorships = sponsors.filter(
        (s) => s.status === "Active" && s.includeInMonthlyIncome
      );
      let totalIncome = 0;
      activeSponsorships.forEach((sponsorship) => {
        const { commitmentAmount, commitmentPeriod, currency } = sponsorship;
        if (!commitmentAmount || !commitmentPeriod || !currency) return;
        const amount = parseFloat(commitmentAmount);
        const period = parseFloat(commitmentPeriod);
        const rate = exchangeRates[currency];
        if (isNaN(amount) || isNaN(period) || period === 0 || !rate) return;
        const monthlyAmountKES = (amount / period) * rate;
        totalIncome += monthlyAmountKES;
      });
      setTotalMonthlyIncomeKES(totalIncome);
    } else {
      setTotalMonthlyIncomeKES(0);
    }
  }, [sponsors]);

  // Fetch expenses from Firestore once on mount
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const docSnap = await getDoc(adminDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTotalExpensesKES(data.totalExpenses || 0);
          setInputExpensesKES(data.totalExpenses || 0);
        }
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };
    fetchExpenses();
  }, [db]);

  // Update percentage when income or expenses change
  useEffect(() => {
    if (totalExpensesKES > 0) {
      setPercentageReached((totalMonthlyIncomeKES / totalExpensesKES) * 100);
    } else {
      setPercentageReached(0);
    }
  }, [totalMonthlyIncomeKES, totalExpensesKES]);

  const handleUpdateExpenses = async () => {
    const newExpenses = parseFloat(inputExpensesKES) || 0;
    setTotalExpensesKES(newExpenses);
    try {
      await setDoc(
        adminDocRef,
        { totalExpenses: newExpenses },
        { merge: true }
      );
      alert("Expenses updated successfully!");
    } catch (error) {
      console.error("Error updating expenses:", error);
    }
  };

  return (
    <div>
      <p>
        Total Monthly Income: KES {totalMonthlyIncomeKES.toFixed(2)} | Total
        Expenses: KES{" "}
        <input
          type="number"
          value={inputExpensesKES}
          onChange={(e) => setInputExpensesKES(e.target.value)}
        />
        <button onClick={handleUpdateExpenses}>Update</button> | Percentage
        Reached: <b>{percentageReached.toFixed(2)}%</b>
      </p>
    </div>
  );
};

export default IncomeExpenses;
