import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Navbar, Footer, MyLink, Loading } from "../../../components";
import { images } from "../../../constants";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useProjects } from "./ProjectsContext";

const Projects = ({ isAdmin }) => {
  const { projects, projectsLoading, addProject } = useProjects();
  const [orderList, setOrderList] = useState([]);
  const [orderLoading, setOrderLoading] = useState(true);
  const [showOrderPopup, setShowOrderPopup] = useState(false);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderDocRef = doc(db, "settings", "projectsOrder");
        const orderDoc = await getDoc(orderDocRef);
        if (orderDoc.exists()) {
          setOrderList(orderDoc.data().order || []);
        } else {
          const initialOrder = projects.map((p) => p.url);
          setOrderList(initialOrder);
          await setDoc(orderDocRef, { order: initialOrder });
        }
      } catch (error) {
        console.error("Error fetching order list:", error);
      } finally {
        setOrderLoading(false);
      }
    };
    fetchOrder();
  }, [projects]);

  const updateOrderInFirebase = async (newOrder) => {
    try {
      const orderDocRef = doc(db, "settings", "projectsOrder");
      await setDoc(orderDocRef, { order: newOrder });
    } catch (error) {
      console.error("Error updating order list:", error);
    }
  };

  const moveItem = (index, direction) => {
    const newOrder = [...orderList];
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    [newOrder[index], newOrder[targetIndex]] = [
      newOrder[targetIndex],
      newOrder[index],
    ];
    setOrderList(newOrder);
    updateOrderInFirebase(newOrder);
  };

  const handleCreateProgram = () => {
    const url = prompt(
      "Enter the URL for the new program.\n \n(thewatotoprogram.org/theurl)"
    );
    if (url) {
      createNewProgram(url);
    }
  };

  const createNewProgram = async (url) => {
    const newProgramData = {
      title: "New Program Title",
      description: "Description of the new program.",
      status: "Draft",
      img: "",
      url: url,
    };

    try {
      await addProject(newProgramData);
      // Append new URL to order list
      const newOrder = [...orderList, url];
      setOrderList(newOrder);
      updateOrderInFirebase(newOrder);
    } catch (error) {
      console.error("Error creating new program:", error);
    }
  };

  const sortedProjects = projects.slice().sort((a, b) => {
    const indexA = orderList.indexOf(a.url);
    const indexB = orderList.indexOf(b.url);
    return indexA - indexB;
  });

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />

      {isAdmin && showOrderPopup && (
        <div
          className="modal-overlay"
          onClick={() => setShowOrderPopup(false)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "400px",
            }}
          >
            <h3>Manage Programs Order</h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {orderList.map((url, index) => (
                <li
                  key={url}
                  style={{
                    margin: "10px 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{url}</span>
                  <div>
                    <button
                      disabled={index === 0}
                      onClick={(e) => {
                        e.stopPropagation();
                        moveItem(index, "up");
                      }}
                    >
                      Up
                    </button>
                    <button
                      disabled={index === orderList.length - 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        moveItem(index, "down");
                      }}
                    >
                      Down
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <button onClick={() => setShowOrderPopup(false)}>Close</button>
          </div>
        </div>
      )}

      <div className="max1600">
        <div className="article programsTxt">
          <div className="flex-image-text">
            <div>
              <h2>Our Projects</h2>
              <div className="flex-container-2">
                <div className="openhrs">
                  <div>
                    <p>
                      <i className="fa-solid fa-school"></i> School opening
                      hours
                    </p>
                  </div>
                </div>
                <div className="flex-container-2">
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>{" "}
                    Mon - Fri: 8am - 4pm
                  </div>
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>{" "}
                    Sat: 8am - 12am
                  </div>
                </div>
              </div>
              <div className="flex-container-2">
                <div className="openhrs">
                  <div>
                    <p>
                      <i className="fa-solid fa-book-open-reader"></i> Library
                      opening hours
                    </p>
                  </div>
                </div>
                <div className="flex-container-2">
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>{" "}
                    Mon - Fri: 5pm - 9pm
                  </div>
                  <div className="when">
                    <i
                      className="fa-regular fa-calendar"
                      aria-hidden="true"
                    ></i>{" "}
                    Sat: 10am - 9pm
                  </div>
                </div>
              </div>
              <p>
                <i class="fa-regular fa-star"></i> We inspire, empower, and
                facilitate childhood growth in areas faced with poverty and
                oppression.
              </p>
              <p>
                We run a sponsorship school, a river cleanup and garbage
                collection program, and a free library, offering academic
                support, dance and computer classes, and chess activities
                regularly.
              </p>
              Stay updated on our projects in our newsletters:{" "}
              <MyLink to="/newsletters">
                <div className="div-readmore">
                  <p className="allNewsLetters">
                    All newsletters{" "}
                    <i
                      className="fa-solid fa-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
              </MyLink>
            </div>
            <img src={images.insideComputerRoom} className="img" alt="" />
          </div>
        </div>
        <div className="flex-container" id="flex">
          <div className="row2">
            {projectsLoading || orderLoading || orderList.length === 0 ? (
              <>
                <div className="loading-container">
                  <div className="loading-animation"></div>
                  <div className="loading-text">
                    <Loading />
                  </div>
                </div>
                <br />
                <br />
              </>
            ) : (
              <>
                {sortedProjects
                  .filter(
                    (program) => program.status === "Published" || isAdmin
                  )
                  .map((program) => (
                    <div className="column-2" key={program.id}>
                      {program.url === "thewatotoacademy" ? (
                        <MyLink to="/school">
                          <div className="program-card card" id={program.url}>
                            <div className="flex-container-2">
                              <h3>{program.title}</h3>
                            </div>
                            <img
                              src={program.img}
                              className="program-img"
                              alt={program.title}
                            />
                          </div>
                        </MyLink>
                      ) : (
                        <MyLink to={`/${program.url}`}>
                          <div className="program-card card" id={program.url}>
                            <div className="flex-container-2">
                              <h3>{program.title}</h3>
                              {isAdmin && program.status === "Draft" && (
                                <span className="status-draft projects">
                                  {program.status}{" "}
                                  <i className="fa fa-pencil-alt"></i>
                                </span>
                              )}
                            </div>
                            <img
                              src={program.img}
                              className="program-img"
                              alt={program.title}
                            />
                          </div>
                        </MyLink>
                      )}
                    </div>
                  ))}
                {isAdmin && (
                  <div className="column-2">
                    <div
                      className="program-card card"
                      onClick={handleCreateProgram}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex-container-2">
                        <h3>
                          <i className="fa-solid fa-plus"></i> Create New
                          Program
                        </h3>
                      </div>
                    </div>
                    <div
                      className="program-card card"
                      onClick={() => setShowOrderPopup(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex-container-2">
                        <h3>
                          <i className="fa-regular fa-pen-to-square"></i> Manage
                          Programs Order
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default Projects;
