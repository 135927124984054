import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import {
  Navbar,
  Footer,
  Side,
  Section,
  Slideshow,
  MyLink,
} from "../../components";
import "./Innsamling.css";
import Betaling from "./Betaling";
import Utlysning from "./Utlysning";
import Hvordan from "./Hvordan";
import Stillinger from "./Stillinger";

const Innsamling = ({ needsApproval, isAdmin }) => {
  const [activeTab, setActiveTab] = useState("howToDoIt");

  useEffect(() => {
    // This effect handles setting the active tab based on URL hash changes
    const handleHashChange = () => {
      const newHash = window.location.hash || "#hvordan";
      setActiveTab(newHash);
    };

    // Initialize the active tab on component mount and on hash changes
    handleHashChange(); // Call it here to set the tab based on the initial URL hash

    window.addEventListener("hashchange", handleHashChange);

    // Cleanup
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  const handleTabClick = (hashValue) => {
    // Update the URL hash, triggering the hashchange listener
    window.location.hash = hashValue;
  };

  return (
    <div>
      <Navbar />
      <div className="max1600">
        <div className="innsamling-header">
          <div className="left-content">
            <img src={images.logo} alt="" />
          </div>
          <div className="tabs-header">
            {" "}
            <div className="tabs-header">
              <div
                className={`tab-header ${
                  activeTab === "#hvordan" ? "active" : ""
                }`}
                onClick={() => handleTabClick("#hvordan")}
              >
                <h3>
                  Hvordan fungerer det?{" "}
                  <i class="fa-solid fa-circle-question"></i>
                </h3>
              </div>
              <div
                className={`tab-header ${
                  activeTab === "#jobber" ? "active" : ""
                }`}
                onClick={() => handleTabClick("#jobber")}
              >
                <h3>
                  {needsApproval && (
                    <i className="fa-solid fa-triangle-exclamation"></i>
                  )}
                  Se ledige jobber <i class="fa-solid fa-briefcase"></i>
                </h3>
              </div>
              <div
                className={`tab-header ${
                  activeTab === "#ansett" ? "active" : ""
                }`}
                onClick={() => handleTabClick("#ansett")}
              >
                <h3>
                  Utlys en jobb <i class="fa-solid fa-circle-plus"></i>
                </h3>
              </div>
              <div
                className={`tab-header ${
                  activeTab === "#betaling" ? "active" : ""
                }`}
                onClick={() => handleTabClick("#betaling")}
              >
                <h3>
                  Betaling <i class="fa-solid fa-coins"></i>
                </h3>
              </div>
            </div>
          </div>
          <div className="right-content">
            <img src={images.skullerudSkole} alt="" />
          </div>
        </div>
        <div className="innsamling">
          <div className="innsamling-utlysning">
            {activeTab === "#hvordan" && <Hvordan />}
            {activeTab === "#jobber" && <Stillinger isAdmin={isAdmin} />}
            {activeTab === "#ansett" && <Utlysning />}
            {activeTab === "#betaling" && <Betaling />}
          </div>
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Innsamling;
