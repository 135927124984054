import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  addDoc,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import Loading from "../../../../components/Loading/Loading";
import { useAuth } from "../../../../AuthProvider";
import "./AllStudents.css";

const AllStudents = () => {
  const { isAdmin } = useAuth();
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promotionClass, setPromotionClass] = useState({});
  const [showAddStudentPopup, setShowAddStudentPopup] = useState(false);
  const [newStudent, setNewStudent] = useState({
    name: "",
    birthday: "",
    current_class: "",
    gender: "",
  });
  const [filterClass, setFilterClass] = useState(
    localStorage.getItem("filterClass") || ""
  );
  const [editingStudent, setEditingStudent] = useState(null);
  const [editedData, setEditedData] = useState({
    name: "",
    birthday: "",
    gender: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("sortOrder") || "name"
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const db = getFirestore();

        // Fetch students
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const studentsData = studentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const studentsMap = Object.fromEntries(
          studentsData.map((student) => [student.id, student.name])
        );

        // Fetch classes
        const classesSnapshot = await getDocs(collection(db, "classes"));
        const classesData = classesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Classes fetched:", classesData);
        setClasses(classesData);

        const uniqueYearsSet = new Set();
        classesData.forEach((cls) => {
          if (cls.year && typeof cls.year === "number") {
            uniqueYearsSet.add(cls.year);
          }
        });
        const yearsArray = Array.from(uniqueYearsSet).sort((a, b) => a - b);
        setUniqueYears(yearsArray);

        const studentRemarks = {};

        for (const cls of classesData) {
          const remarks = cls.remarks || {};
          // Now cls.students is assumed to be an array of student IDs.
          const classStudentsMap = Object.fromEntries(
            cls.students.map((id) => [id, studentsMap[id]])
          );

          Object.entries(remarks).forEach(([date, dayRemarks]) => {
            Object.values(dayRemarks).forEach((remarkObject) => {
              const studentId = remarkObject.studentId;
              const studentName =
                studentsMap[studentId] || classStudentsMap[studentId];

              if (studentName) {
                remarkObject.remarks.forEach((remark) => {
                  studentRemarks[studentName] =
                    (studentRemarks[studentName] || 0) + 1;
                });
              } else {
                console.warn(
                  `Remark without valid studentId:`,
                  remarkObject,
                  `Class students map:`,
                  classStudentsMap
                );
              }
            });
          });
        }

        console.log("Final student remarks:", studentRemarks);

        // Process attendance data per student name
        const studentAttendance = {};

        for (const cls of classesData) {
          const lessons = cls.lessons || {};
          // Ensure we have an array of student IDs even if stored as objects
          const studentIds = cls.students.map((s) =>
            typeof s === "object" ? s.id : s
          );
          for (const date in lessons) {
            lessons[date]?.forEach((lesson) => {
              const attendance = lesson.attendance || {};
              Object.entries(attendance).forEach(([studentId, status]) => {
                if (studentIds.includes(studentId)) {
                  const studentName = studentsMap[studentId];
                  if (!studentAttendance[studentName]) {
                    studentAttendance[studentName] = {
                      Present: 0,
                      "Not Present": 0,
                    };
                  }
                  if (status === "Present") {
                    studentAttendance[studentName].Present++;
                  } else if (status === "Not Present") {
                    studentAttendance[studentName]["Not Present"]++;
                  }
                }
              });
            });
          }
        }

        console.log("Final studentAttendance by Name:", studentAttendance);

        const sponsorshipsSnapshot = await getDocs(
          collection(db, "sponsorships")
        );
        const sponsorshipsData = sponsorshipsSnapshot.docs.map((doc) =>
          doc.data()
        );

        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = Object.fromEntries(
          usersSnapshot.docs.map((doc) => [doc.id, doc.data()])
        );

        const studentsWithDetails = studentsData.map((student) => {
          const sponsorship = sponsorshipsData.find(
            (s) => s.studentName === student.name
          );
          let sponsor = "";
          let sponsorshipDuration = "";
          if (sponsorship && sponsorship.status !== "Canceled") {
            sponsor =
              usersData[sponsorship.sponsorId]?.name ||
              usersData[sponsorship.sponsorId]?.email ||
              "";
            sponsorshipDuration = calculateSponsorshipDuration(
              sponsorship.startDate
            );
          }

          const attendance = studentAttendance[student.name] || {
            Present: 0,
            "Not Present": 0,
          };

          const totalRemarks = studentRemarks[student.name] || 0;

          return {
            ...student,
            sponsor,
            sponsorshipDuration,
            attendance,
            totalRemarks,
          };
        });

        setStudents(studentsWithDetails);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("filterClass", filterClass);
  }, [filterClass]);

  useEffect(() => {
    if (!students.length) return;

    let sortedStudents = [...students];

    switch (sortOrder) {
      case "name":
        sortedStudents.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "birthday":
        sortedStudents.sort(
          (a, b) =>
            new Date(a.birthday?.seconds * 1000 || 0) -
            new Date(b.birthday?.seconds * 1000 || 0)
        );
        break;
      case "attendance":
        sortedStudents.sort(
          (a, b) => (a.attendance?.Present || 0) - (b.attendance?.Present || 0)
        );
        break;
      case "remarks":
        sortedStudents.sort(
          (a, b) => (b.totalRemarks || 0) - (a.totalRemarks || 0)
        );
        break;
      case "noSponsor":
        sortedStudents.sort((a, b) => {
          if (!a.sponsor && b.sponsor) return -1;
          if (a.sponsor && !b.sponsor) return 1;
          return a.name.localeCompare(b.name);
        });
        break;
      default:
        break;
    }

    setStudents(sortedStudents);
    localStorage.setItem("sortOrder", sortOrder);
  }, [sortOrder]);

  const REQUIRED_FIELDS = [
    "birthday",
    "current_class",
    "current_year",
    "description",
    "gender",
    "grade",
    "image",
    "image2",
    "message",
    "name",
  ];

  const getMissingFields = (student) => {
    return REQUIRED_FIELDS.filter(
      (field) => !student[field] || student[field] === null
    );
  };

  const handleSort = (order) => {
    setSortOrder(order);
    let sortedStudents = [...students];

    switch (order) {
      case "name":
        sortedStudents.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "birthday":
        sortedStudents.sort(
          (a, b) =>
            new Date(a.birthday?.seconds * 1000 || 0) -
            new Date(b.birthday?.seconds * 1000 || 0)
        );
        break;
      case "attendance":
        sortedStudents.sort(
          (a, b) => (a.attendance?.Present || 0) - (b.attendance?.Present || 0)
        );
        break;
      case "remarks":
        sortedStudents.sort(
          (a, b) => (b.totalRemarks || 0) - (a.totalRemarks || 0)
        );
        break;
      case "noSponsor":
        sortedStudents.sort((a, b) => {
          if (!a.sponsor && b.sponsor) return -1;
          if (a.sponsor && !b.sponsor) return 1;
          return a.name.localeCompare(b.name);
        });
        break;
      default:
        break;
    }

    setStudents(sortedStudents);
  };

  const handleEditStudent = (student) => {
    setEditingStudent(student);
    setEditedData({
      name: student.name || "",
      birthday: student.birthday || "",
      gender: student.gender || "",
    });
  };

  const handleSaveStudent = async () => {
    if (!editingStudent) return;

    const db = getFirestore();
    const storage = getStorage();
    const studentRef = doc(db, "students", editingStudent.id);

    try {
      let imageUrl = editingStudent.image2 || "";

      if (imageFile) {
        const imageRef = ref(storage, `student_images/${editingStudent.id}`);
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      const birthday =
        editedData.birthday && editedData.birthday !== editingStudent.birthday
          ? Timestamp.fromDate(new Date(editedData.birthday))
          : editingStudent.birthday;

      await updateDoc(studentRef, {
        ...editedData,
        image2: imageUrl,
        birthday,
      });

      setStudents((prev) =>
        prev.map((student) =>
          student.id === editingStudent.id
            ? { ...student, ...editedData, image2: imageUrl }
            : student
        )
      );

      setEditingStudent(null);
      setImageFile(null);
      alert("Student updated successfully!");
    } catch (error) {
      console.error("Error updating student:", error);
      alert("Failed to update student. Please try again.");
    }
  };

  const handleAddStudent = async () => {
    if (!isAdmin) return;

    const { name, birthday, current_class, gender } = newStudent;

    if (!name || !birthday || !current_class || !gender) {
      alert("Please fill out all fields.");
      return;
    }

    const selectedClass = classes.find((cls) => cls.id === current_class);

    if (!selectedClass) {
      alert("Invalid class selected.");
      return;
    }

    const newStudentData = {
      name,
      birthday: new Date(birthday),
      current_class,
      current_year: selectedClass.year,
      grade: selectedClass.grade,
      gender,
      history: [
        {
          grade: selectedClass.grade,
          year: selectedClass.year,
        },
      ],
      ready_for_sponsor: false,
      message: "",
      image: "",
      description: "",
    };

    try {
      const db = getFirestore();

      // Add the student to the "students" collection
      const studentRef = await addDoc(
        collection(db, "students"),
        newStudentData
      );
      const studentId = studentRef.id;

      // Update the "students" field in the selected class with just the ID
      const classRef = doc(db, "classes", current_class);
      const classDoc = await getDoc(classRef);

      if (classDoc.exists()) {
        const classData = classDoc.data();
        const updatedStudents = [...(classData.students || []), studentId];

        await updateDoc(classRef, { students: updatedStudents });
      }

      setStudents((prev) => [...prev, { id: studentId, ...newStudentData }]);
      alert("Student added successfully!");
      setShowAddStudentPopup(false);
      setNewStudent({ name: "", birthday: "", current_class: "", gender: "" });
    } catch (error) {
      console.error("Error adding student:", error);
      alert("Failed to add student. Please try again.");
    }
  };

  const handlePromotionChange = (studentId, field, value) => {
    setPromotionClass((prev) => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        [field]: value,
      },
    }));
  };

  const filteredStudents = students.filter((student) => {
    if (!filterClass) return true;
    if (filterClass === "exmatriculated") return student.current_class === null;
    return student.current_class === filterClass;
  });

  const updateHistory = (history, newClass) => {
    if (!history || !Array.isArray(history)) {
      return [
        {
          grade: newClass?.grade || "Exmatriculated",
          year: newClass?.year || new Date().getFullYear(),
        },
      ];
    }

    const existingRecordIndex = history.findIndex(
      (entry) =>
        entry.grade === newClass?.grade || entry.year === newClass?.year
    );

    if (existingRecordIndex !== -1) {
      history[existingRecordIndex] = {
        grade: newClass?.grade || history[existingRecordIndex].grade,
        year: newClass?.year || history[existingRecordIndex].year,
      };
    } else {
      history.push({
        grade: newClass?.grade || "Exmatriculated",
        year: newClass?.year || new Date().getFullYear(),
      });
    }

    return history;
  };

  const calculateSponsorshipDuration = (startDate) => {
    if (!startDate) return "Unknown duration";

    const start = new Date(startDate);
    if (isNaN(start)) return "Invalid startDate format";

    const now = new Date();
    const diffInMonths =
      (now.getFullYear() - start.getFullYear()) * 12 +
      (now.getMonth() - start.getMonth());

    const years = Math.floor(diffInMonths / 12);
    const months = diffInMonths % 12;

    const yearLabel = years === 1 ? "year" : "years";
    const monthLabel = months === 1 ? "month" : "months";

    if (years > 0 && months > 0)
      return `${years} ${yearLabel} and ${months} ${monthLabel}`;
    if (years > 0) return `${years} ${yearLabel}`;
    if (months > 0) return `${months} ${monthLabel}`;

    return "Less than a month";
  };

  const handlePromoteStudent = async (studentId) => {
    if (!isAdmin) return;

    const db = getFirestore();
    try {
      const studentRef = doc(db, "students", studentId);
      const student = students.find((s) => s.id === studentId);
      const selectedData = promotionClass[studentId];

      if (!selectedData || !selectedData.classId || !selectedData.year) {
        alert("Please select both class and year for promotion.");
        return;
      }

      const isDuplicate = student.history?.some(
        (entry) =>
          entry.grade ===
            classes.find((cls) => cls.id === selectedData.classId)?.grade &&
          entry.year === selectedData.year
      );

      if (isDuplicate) {
        alert(
          `This promotion is invalid because Grade ${
            classes.find((cls) => cls.id === selectedData.classId)?.grade
          } - ${selectedData.year} already exists in the student's history.`
        );
        return;
      }

      let newGrade, newYear, updatedHistory, newCurrentClass;

      if (selectedData.classId === "exmatriculated") {
        newGrade = "Exmatriculated";
        newYear = new Date().getFullYear();
        newCurrentClass = null;
        updatedHistory = updateHistory(student.history || [], null);
      } else {
        const selectedClass = classes.find(
          (cls) => cls.id === selectedData.classId
        );

        if (!selectedClass) {
          alert("Please select a valid class for promotion.");
          return;
        }

        newGrade = selectedClass.grade;
        newYear = selectedData.year;
        newCurrentClass = selectedData.classId;
        updatedHistory = updateHistory(student.history || [], selectedClass);
      }

      await updateDoc(studentRef, {
        grade: newGrade,
        current_year: newYear,
        current_class: newCurrentClass,
        history: updatedHistory,
      });

      setStudents((prevStudents) =>
        prevStudents.map((s) =>
          s.id === studentId
            ? {
                ...s,
                grade: newGrade,
                current_year: newYear,
                current_class: newCurrentClass,
                history: updatedHistory,
              }
            : s
        )
      );

      alert(
        `${student.name} ${
          selectedData.classId === "exmatriculated"
            ? "has been exmatriculated."
            : `promoted to Grade ${newGrade} - ${newYear} successfully!`
        }`
      );

      setPromotionClass((prev) => ({ ...prev, [studentId]: {} }));
    } catch (error) {
      console.error("Error promoting student:", error);
      alert("Failed to promote student. Please try again.");
    }
  };

  return (
    <>
      <Locationscroll />
      <div className="all-students-page">
        <h2>All Students</h2>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div>
              <label>Filter by Active Class: </label>
              <select
                value={filterClass}
                onChange={(e) => setFilterClass(e.target.value)}
              >
                <option value="">All Classes</option>
                {classes
                  .filter((cls) => cls.status !== "archived")
                  .map((cls) => (
                    <option key={cls.id} value={cls.id}>
                      Grade {cls.grade} - {cls.year}
                    </option>
                  ))}
                <option value="exmatriculated">Exmatriculated</option>
              </select>{" "}
              {isAdmin && (
                <button
                  className="add-student-button"
                  onClick={() => setShowAddStudentPopup(true)}
                  disabled={!isAdmin}
                >
                  Add Student
                </button>
              )}
            </div>
            {showAddStudentPopup && (
              <div className="editstudent-popup-overlay">
                <div className="popup-content">
                  <h3>Add Student</h3>
                  <form>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="studentName">Name:</label>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="studentName"
                              value={newStudent.name}
                              onChange={(e) =>
                                setNewStudent({
                                  ...newStudent,
                                  name: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="studentBirthday">Birthday:</label>
                          </td>
                          <td>
                            <input
                              type="date"
                              id="studentBirthday"
                              value={newStudent.birthday}
                              onChange={(e) =>
                                setNewStudent({
                                  ...newStudent,
                                  birthday: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="studentGender">Gender:</label>
                          </td>
                          <td>
                            <select
                              id="studentGender"
                              value={newStudent.gender}
                              onChange={(e) =>
                                setNewStudent({
                                  ...newStudent,
                                  gender: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Gender</option>
                              <option value="Boy">Boy</option>
                              <option value="Girl">Girl</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="studentClass">Current Class:</label>
                          </td>
                          <td>
                            <select
                              id="studentClass"
                              value={newStudent.current_class}
                              onChange={(e) =>
                                setNewStudent({
                                  ...newStudent,
                                  current_class: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Class</option>
                              {classes.map((cls) => (
                                <option
                                  key={cls.id}
                                  value={cls.id}
                                  disabled={cls.status === "archived"}
                                >
                                  Grade {cls.grade} - {cls.year}{" "}
                                  {cls.status === "archived"
                                    ? "(archived)"
                                    : ""}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="popup-buttons">
                      <button
                        type="button"
                        className="popup-create-button"
                        onClick={handleAddStudent}
                      >
                        Add Student
                      </button>
                      <button
                        type="button"
                        className="popup-cancel-button"
                        onClick={() => {
                          setShowAddStudentPopup(false);
                          setNewStudent({
                            name: "",
                            birthday: "",
                            current_class: "",
                            gender: "",
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <br />
            <div className="sort-buttons">
              <button onClick={() => handleSort("name")}>Sort by Name</button>
              <button onClick={() => handleSort("birthday")}>
                Sort by Birthday
              </button>
              <button onClick={() => handleSort("attendance")}>
                Sort by Attendance
              </button>
              <button onClick={() => handleSort("remarks")}>
                Sort by Remarks
              </button>
              {isAdmin && (
                <button onClick={() => handleSort("noSponsor")}>
                  Sort by No Sponsor
                </button>
              )}
            </div>
            <table className="students-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Attendance & Remarks</th>
                  {isAdmin && <th>Sponsorship (admins only)</th>}
                  <th>History</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.length > 0 ? (
                  filteredStudents.map((student) => (
                    <tr key={student.id}>
                      <td>
                        {student.image2 ? (
                          <img
                            src={student.image2}
                            alt="Student"
                            className="allstudents-student-image"
                          />
                        ) : (
                          "No Image"
                        )}{" "}
                      </td>
                      <td>
                        <p>
                          {student.name}{" "}
                          {isAdmin && (
                            <>
                              {getMissingFields(student).length > 0 && (
                                <span title="The student has missing fields">
                                  {" "}
                                  <i
                                    className="fa-solid fa-triangle-exclamation"
                                    style={{
                                      color: "#d9534f",
                                      marginLeft: "0.1em",
                                    }}
                                  ></i>
                                </span>
                              )}
                            </>
                          )}
                        </p>
                        <p>
                          {" "}
                          {(() => {
                            if (student.birthday && student.birthday.seconds) {
                              const birthdayDate = new Date(
                                student.birthday.seconds * 1000
                              );
                              const today = new Date();
                              let age =
                                today.getFullYear() -
                                birthdayDate.getFullYear();

                              if (
                                today.getMonth() < birthdayDate.getMonth() ||
                                (today.getMonth() === birthdayDate.getMonth() &&
                                  today.getDate() < birthdayDate.getDate())
                              ) {
                                age--;
                              }

                              return `${birthdayDate.toLocaleDateString()} (${age} years)`;
                            } else {
                              return "No birthday provided";
                            }
                          })()}
                        </p>
                        {isAdmin && (
                          <>
                            <p>
                              <span onClick={() => handleEditStudent(student)}>
                                <u>Edit</u>
                              </span>
                            </p>
                          </>
                        )}
                      </td>

                      <td>
                        <span>✅ {student.attendance?.Present || 0}</span>{" "}
                        <span className="margin-left-small">
                          ❌ {student.attendance?.["Not Present"] || 0}
                        </span>
                        <br />
                        <br />
                        <strong>Score:</strong>{" "}
                        {(() => {
                          const present = student.attendance?.Present || 0;
                          const notPresent =
                            student.attendance?.["Not Present"] || 0;
                          const total = present + notPresent;

                          if (total === 0) {
                            return "No attendance recorded";
                          }

                          const attendancePercentage = (
                            (present / total) *
                            100
                          ).toFixed(2);

                          return <span>{attendancePercentage}%</span>;
                        })()}
                        <br />
                        {student.totalRemarks > 0 && (
                          <p style={{ color: "#d9534f" }}>
                            🛑{" "}
                            <b style={{ color: "#d9534f" }}>
                              {student.totalRemarks}
                            </b>{" "}
                            remarks
                          </p>
                        )}
                      </td>

                      {isAdmin && (
                        <td>
                          {student.sponsor}
                          {student.sponsorshipDuration && (
                            <div>
                              <small>{student.sponsorshipDuration}</small>
                            </div>
                          )}
                          {!student.sponsor && (
                            <span>
                              Display on sponsorship page:
                              <input
                                type="checkbox"
                                checked={student.ready_for_sponsor || false}
                                onChange={async (e) => {
                                  const isChecked = e.target.checked;
                                  const db = getFirestore();
                                  const studentRef = doc(
                                    db,
                                    "students",
                                    student.id
                                  );
                                  try {
                                    await updateDoc(studentRef, {
                                      ready_for_sponsor: isChecked,
                                    });

                                    setStudents((prev) =>
                                      prev.map((s) =>
                                        s.id === student.id
                                          ? {
                                              ...s,
                                              ready_for_sponsor: isChecked,
                                            }
                                          : s
                                      )
                                    );

                                    alert(
                                      `Student ${student.name} is now ${
                                        isChecked ? "VISIBLE" : "NOT VISIBLE"
                                      } on the sponsorship page.`
                                    );
                                  } catch (error) {
                                    console.error(
                                      "Error updating ready_for_sponsor field:",
                                      error
                                    );
                                    alert(
                                      "Failed to update field. Please try again."
                                    );
                                  }
                                }}
                              />
                              {getMissingFields(student).length > 0 && (
                                <p title="The student has missing fields">
                                  <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                                  The student has missing fields
                                </p>
                              )}
                            </span>
                          )}
                        </td>
                      )}
                      <td>
                        {student.history && student.history.length > 0 ? (
                          <table className="student-history">
                            <tbody>
                              {student.history.map((entry, index) => (
                                <tr key={index}>
                                  {student.current_class &&
                                  entry.grade === student.grade ? (
                                    <>
                                      <td>⌛</td>
                                      <td>
                                        Grade {entry.grade}, Year {entry.year}
                                      </td>
                                    </>
                                  ) : entry.grade === "Exmatriculated" ? (
                                    <>
                                      <td>❌</td>
                                      <td>Exmatriculated</td>
                                    </>
                                  ) : (
                                    <>
                                      <td>✅</td>
                                      <td>
                                        Grade {entry.grade}, Year {entry.year}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          "No history available"
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={isAdmin ? 6 : 5}
                      style={{ textAlign: "center", height: "50px" }}
                    >
                      This class does not have any active students.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}

        {editingStudent && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h3>Edit Student</h3>

              {(() => {
                const missingFields = getMissingFields(editingStudent);
                if (missingFields.length > 0) {
                  return (
                    <div className="warning-div">
                      {missingFields.map((field) => (
                        <p key={field}>
                          <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                          The student does not have {field}.
                        </p>
                      ))}

                      <br />
                    </div>
                  );
                }
                return null;
              })()}
              <p>
                Click{" "}
                <a
                  href={`/sponsorship/${editingStudent.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>HERE</u>
                </a>{" "}
                to update additional fields.
              </p>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Name:</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={editedData.name}
                        onChange={(e) =>
                          setEditedData({ ...editedData, name: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Birthday:</label>
                    </td>
                    <td>
                      <input
                        type="date"
                        value={
                          editedData.birthday &&
                          (editedData.birthday.seconds
                            ? new Date(editedData.birthday.seconds * 1000)
                                .toISOString()
                                .substr(0, 10)
                            : new Date(editedData.birthday)
                                .toISOString()
                                .substr(0, 10))
                        }
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            birthday: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Gender:</label>
                    </td>
                    <td>
                      <select
                        value={editedData.gender}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            gender: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Gender</option>
                        <option value="Boy">Boy</option>
                        <option value="Girl">Girl</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Image:</label>
                    </td>
                    <td>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImageFile(e.target.files[0])}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="popup-buttons">
                        <button
                          type="button"
                          className="popup-create-button"
                          onClick={handleSaveStudent}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="popup-cancel-button"
                          onClick={() => setEditingStudent(null)}
                        >
                          Cancel
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <h3>Promote student</h3>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Promote:</label>
                    </td>
                    <td>
                      <select
                        value={promotionClass[editingStudent.id]?.classId || ""}
                        onChange={(e) =>
                          handlePromotionChange(
                            editingStudent.id,
                            "classId",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Class</option>
                        {classes.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            Grade {cls.grade}
                          </option>
                        ))}
                        <option value="exmatriculated">Exmatriculated</option>
                      </select>
                    </td>
                    <td>
                      <select
                        value={promotionClass[editingStudent.id]?.year || ""}
                        onChange={(e) =>
                          handlePromotionChange(
                            editingStudent.id,
                            "year",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Year</option>
                        {uniqueYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button
                        onClick={() => handlePromoteStudent(editingStudent.id)}
                        disabled={
                          !promotionClass[editingStudent.id]?.classId ||
                          !promotionClass[editingStudent.id]?.year
                        }
                      >
                        Promote
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllStudents;
