// AboutContext.jsx
import React, { createContext, useState, useContext, useEffect } from "react";
import { collection, getDocs, setDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const AboutContext = createContext();
export const useAbout = () => useContext(AboutContext);

export const AboutProvider = ({ children }) => {
  const [aboutItems, setAboutItems] = useState([]);
  const [aboutLoading, setAboutLoading] = useState(true);

  useEffect(() => {
    fetchAbout();
  }, []);

  const fetchAbout = async () => {
    setAboutLoading(true);
    const aboutCol = collection(db, "aboutPages"); // Name your collection
    const aboutSnapshot = await getDocs(aboutCol);
    const aboutList = aboutSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAboutItems(aboutList);
    setAboutLoading(false);
  };

  const addAbout = async (aboutData) => {
    try {
      // Use the 'url' as a doc ID or any other unique field you like
      await setDoc(doc(db, "aboutPages", aboutData.url), aboutData);
      await fetchAbout();
    } catch (error) {
      console.error("Error adding new about entry:", error);
    }
  };

  const deleteAbout = async (aboutId) => {
    try {
      await deleteDoc(doc(db, "aboutPages", aboutId));
      await fetchAbout();
    } catch (error) {
      console.error("Error deleting about entry:", error);
    }
  };

  return (
    <AboutContext.Provider
      value={{
        aboutItems,
        aboutLoading,
        addAbout,
        deleteAbout,
      }}
    >
      {children}
    </AboutContext.Provider>
  );
};
