import React, { useState } from "react";
// import { articles } from "../../container/Articles/Articles";
import Loading from "../Loading/Loading";
import { useArticles } from "../../container/Pages/Articles/ArticlesContext";

function SelectArticle() {
  const { articles, articlesLoading } = useArticles();
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (newValue) {
      const element = document.querySelector(`[id="${newValue}"]`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  if (articlesLoading) {
    return (
      <div className="loading-container">
        <div className="loading-animation"></div>
        <div className="loading-text">Loading newsletters...</div>
      </div>
    );
  }

  return (
    <select value={selectedValue} onChange={handleChange} className="select">
      <option value="">Select newsletter</option>
      {articles
        .filter((article) => article.status === "Published")
        .map((article) => (
          <option key={article.id} value={article.id}>
            {article.month} {article.year} - {article.title}
          </option>
        ))}
    </select>
  );
}

export default SelectArticle;
