import React, { createContext, useState, useEffect } from "react";
import { useAuth } from "../../../../AuthProvider";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../../../firebase";

export const PageCountsContext = createContext();

// ... other imports remain unchanged

export const PageCountsProvider = ({ children }) => {
  const { isAdmin, userData } = useAuth();
  const [pageCounts, setPageCounts] = useState({});

  useEffect(() => {
    if (!userData || !userData.email) return;
    const currentYear = new Date().getFullYear();
    const reportsPath = `reports/${currentYear}/reports`;

    const fetchCounts = async () => {
      try {
        let economicsQuery, reportsQuery;
        if (isAdmin) {
          economicsQuery = collection(db, "economics");
          reportsQuery = collection(db, reportsPath);
        } else {
          economicsQuery = query(
            collection(db, "economics"),
            where("userId", "==", userData.email)
          );
          reportsQuery = query(
            collection(db, reportsPath),
            where("userId", "==", userData.email)
          );
        }

        const [economicsSnapshot, reportsSnapshot] = await Promise.all([
          getDocs(economicsQuery),
          getDocs(reportsQuery),
        ]);

        const economicsData = economicsSnapshot.docs.map((doc) => doc.data());
        const reportsData = reportsSnapshot.docs.map((doc) => doc.data());
        const newPageCounts = {};

        // Economics count
        if (isAdmin) {
          const economicsCount = economicsData.filter((request) =>
            ["Awaiting approval", "Pending review", "Receipt rejected"].includes(
              request.status
            )
          ).length;
          newPageCounts["economics"] = economicsCount;
        } else {
          const economicsCount = economicsData.filter((request) =>
            ["Pending review", "Receipt rejected"].includes(request.status) ||
            (["Approved", "Partially approved"].includes(request.status) &&
              !request.receiptUrl)
          ).length;
          newPageCounts["economics"] = economicsCount;
        }

        // Reports count
        const currentWeek = getWeekNumber(new Date());
        const draftForCurrentWeek = reportsData.some(
          (report) =>
            report.week === `Week ${currentWeek}` && report.status === "draft"
        );
        const submittedForCurrentWeek = reportsData.some(
          (report) =>
            report.week === `Week ${currentWeek}` &&
            (report.status === "submitted" || report.status === "awaitingRead")
        );
        // New: Check for completed reports for the current week
        const completedForCurrentWeek = reportsData.some(
          (report) =>
            report.week === `Week ${currentWeek}` && report.status === "completed"
        );

        if (isAdmin) {
          const toReview = reportsData.filter(
            (report) =>
              report.status === "submitted" || report.status === "feedbackDraft"
          ).length;
          newPageCounts["reports"] = toReview;
        } else {
          const toCreate =
            canCreateReport() &&
            !draftForCurrentWeek &&
            !submittedForCurrentWeek &&
            !completedForCurrentWeek
              ? 1
              : 0;
          const toConfirm = reportsData.filter(
            (report) => report.status === "awaitingRead"
          ).length;
          const draftsCount = draftForCurrentWeek ? 1 : 0;
          newPageCounts["reports"] = toCreate + toConfirm + draftsCount;
        }

        setPageCounts((prev) => ({ ...prev, ...newPageCounts }));
      } catch (error) {
        console.error("Error fetching page counts:", error);
      }
    };

    fetchCounts();

    let unsubscribeClasses;
    if (isAdmin) {
      const classesQuery = query(
        collection(db, "classes"),
        where("year", "==", currentYear)
      );
      unsubscribeClasses = onSnapshot(classesQuery, (snapshot) => {
        let attendanceCount = 0;
        const toKenyanDate = (date) => {
          const localString = date.toLocaleString("en-US", {
            timeZone: "Africa/Nairobi",
          });
          return new Date(localString);
        };
        const toKenyanDateKey = (date) => {
          const kenyan = toKenyanDate(date);
          const yyyy = kenyan.getFullYear();
          const mm = String(kenyan.getMonth() + 1).padStart(2, "0");
          const dd = String(kenyan.getDate()).padStart(2, "0");
          return `${yyyy}-${mm}-${dd}`;
        };
        const today = new Date();
        const todayKey = toKenyanDateKey(today);
        snapshot.docs.forEach((docSnap) => {
          const cls = docSnap.data();
          if (cls.lessons) {
            Object.keys(cls.lessons).forEach((dayKey) => {
              if (dayKey < todayKey && cls.lessons[dayKey].length > 0) {
                const confirmedDays = cls.confirmedDays || {};
                if (!confirmedDays[dayKey]) {
                  attendanceCount++;
                }
              }
            });
          }
        });
        setPageCounts((prev) => ({
          ...prev,
          studentattendance: attendanceCount,
        }));
      });
    } else {
      // Remove attendance count for non-admins
      setPageCounts((prev) => {
        const updated = { ...prev };
        delete updated.studentattendance;
        return updated;
      });
    }

    return () => {
      if (unsubscribeClasses) unsubscribeClasses();
    };
  }, [isAdmin, userData]);

  const canCreateReport = () => {
    const today = new Date().getDay();
    return today >= 4 || today === 0;
  };

  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const startDayOfWeek = startDate.getDay();
    const dayOfYear =
      Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1;
    return Math.ceil((dayOfYear + (startDayOfWeek - 1)) / 7);
  };

  return (
    <PageCountsContext.Provider value={pageCounts}>
      {children}
    </PageCountsContext.Provider>
  );
};

export default PageCountsProvider;

