// Layout.jsx
import React from "react";
import { Navbar, Partners, Footer } from "../../../../components";
import SchoolNavbar from "../components/Navbar/SchoolNavbar";

const Layout = ({ children }) => {
  return (
    <div className="school-parent">
      <Navbar />
      <SchoolNavbar />
      {children}
      <Partners />
      <Footer />
    </div>
  );
};

export default Layout;
