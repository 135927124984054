import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../../firebase";
import { useAuth } from "../../../AuthProvider";
import {
  Navbar,
  Footer,
  MyLink,
  Loading,
  LoadingIndicator,
} from "../../../components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useNavigate, useParams } from "react-router-dom";
import SponsorForm from "../../../components/SponsorForm/SponsorForm";
import SupportStepForm from "./SupportStepForm";
import "../Intranet/Intranet.css";
import SponsorshipIncludes from "./SponsorshipIncludes";
import LoginPopup from "../../Auth/LoginPopup/LoginPopup";

const Sponsorship = () => {
  const { isAdmin, isSponsor, userData } = useAuth();
  const [selectedClassName, setSelectedClassName] = useState(
    localStorage.getItem("selectedClassName") || ""
  );
  const [availableClasses, setAvailableClasses] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [newStudentName, setNewStudentName] = useState("");
  const [newStudentBirthday, setNewStudentBirthday] = useState("");
  const [newStudentImage, setNewStudentImage] = useState(null);
  const [newStudentImage2, setNewStudentImage2] = useState(null);
  const [newStudentGender, setNewStudentGender] = useState();
  const [newStudentDescription, setNewStudentDescription] = useState("");
  const [newStudentMessage, setNewStudentMessage] = useState("");
  const [loadingStudents, setLoadingStudents] = useState(true);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [students, setStudents] = useState([]);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const { student } = useParams();
  const [activeOption, setActiveOption] = useState("norwegian");
  const [sponsorStatus, setSponsorStatus] = useState(false);
  const [sponsoredStudents, setSponsoredStudents] = useState(new Set());
  const [studentMessages, setStudentMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const pdfRef = useRef();

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const studentCollection = collection(db, "students");
        const studentSnapshot = await getDocs(studentCollection);
        const classNames = studentSnapshot.docs
          .map((doc) => doc.data().name)
          .sort((a, b) => b.localeCompare(a)); // Sort descending by year

        setAvailableClasses(classNames);

        // Auto-select class based on LocalStorage or fallback to the newest class with students
        let initialClass = localStorage.getItem("selectedClassName");
        if (!initialClass) {
          for (const className of classNames) {
            const classDoc = studentSnapshot.docs.find(
              (doc) => doc.data().name === className
            );
            if (classDoc && classDoc.data().students_list?.length > 0) {
              initialClass = className;
              break;
            }
          }
        }

        if (initialClass) {
          setSelectedClassName(initialClass);
          localStorage.setItem("selectedClassName", initialClass);
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    const fetchStudentMessages = async () => {
      setLoadingMessages(true); // Start loading
      try {
        const studentsCollection = collection(db, "students");
        const studentsSnapshot = await getDocs(studentsCollection);

        // Filter students with messages
        const messages = studentsSnapshot.docs
          .map((doc) => doc.data())
          .filter((student) => student.message?.trim());

        setStudentMessages(messages);

        // Set a random starting message
        if (messages.length > 0) {
          const randomIndex = Math.floor(Math.random() * messages.length);
          setCurrentMessageIndex(randomIndex);
        }
      } catch (error) {
        console.error("Error fetching student messages:", error);
      }
      setLoadingMessages(false); // End loading
    };

    fetchClasses();
    fetchStudentMessages();
  }, []);

  useEffect(() => {
    const fetchStudentsForSponsorship = async () => {
      setLoadingStudents(true);
      try {
        const studentsCollection = collection(db, "students");
        const studentsSnapshot = await getDocs(studentsCollection);
        const sponsorshipsCollection = collection(db, "sponsorships");
        const sponsorshipsSnapshot = await getDocs(sponsorshipsCollection);

        // Build the set of sponsored student names,
        // but only include sponsorships that are not canceled.
        const sponsoredNames = new Set();
        sponsorshipsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.studentName && data.status !== "Canceled") {
            sponsoredNames.add(data.studentName);
          }
        });
        setSponsoredStudents(sponsoredNames);

        const studentData = [];
        studentsSnapshot.forEach((doc) => {
          const student = { id: doc.id, ...doc.data() };
          if (student.current_class) studentData.push(student);
        });
        setStudentsList(studentData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoadingStudents(false);
    };

    fetchStudentsForSponsorship();
  }, []);

  useEffect(() => {
    if (studentsList.length > 0 && student) {
      const foundStudent = studentsList.find((s) => s.id === student);
      if (foundStudent) {
        setSelectedStudent(foundStudent);
        setSponsorStatus(foundStudent.sponsor === "yes");
      }
    }
  }, [studentsList, student]);

  useEffect(() => {
    if (selectedStudent) {
      setNewStudentName(selectedStudent.name);
      setNewStudentBirthday(
        selectedStudent.birthday.toDate().toISOString().split("T")[0]
      );
      setNewStudentGender(selectedStudent.gender);
      setNewStudentDescription(selectedStudent.description);
      setNewStudentMessage(selectedStudent.message);
    }
  }, [selectedStudent]);

  useEffect(() => {
    const fetchSponsoredStudents = async () => {
      try {
        const sponsorshipsCollection = collection(db, "sponsorships");
        const sponsorshipsSnapshot = await getDocs(sponsorshipsCollection);
        const sponsoredNamesSet = new Set();
        sponsorshipsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.studentName) {
            sponsoredNamesSet.add(data.studentName);
          }
        });
        setSponsoredStudents(sponsoredNamesSet);
      } catch (error) {
        console.error("Error fetching sponsorships:", error);
      }
    };

    fetchSponsoredStudents();
  }, []);

  useEffect(() => {
    const fetchSponsorshipStatus = async (student) => {
      try {
        const sponsorshipsCollection = collection(db, "sponsorships");
        const q = query(
          sponsorshipsCollection,
          where("studentName", "==", student.name)
        );
        const querySnapshot = await getDocs(q);
        const isSponsored = !querySnapshot.empty;
        setSponsorStatus(isSponsored);

        if (!isAdmin && isSponsored) {
          // Redirect non-admin users away from sponsored students
          console.log("Access restricted to sponsored students.");
          navigate("/sponsorship");
        } else {
          setSelectedStudent(student);
        }
      } catch (error) {
        console.error("Error checking sponsorship status:", error);
      }
    };

    if (studentsList.length > 0 && student) {
      const foundStudent = studentsList.find((s) => s.id === student);
      if (foundStudent) {
        fetchSponsorshipStatus(foundStudent); // Pass `foundStudent` as an argument
      }
    }
  }, [studentsList, student, sponsoredStudents, isAdmin]);

  useEffect(() => {
    const fetchStudentById = async (id) => {
      try {
        const studentDoc = await getDoc(doc(db, "students", id));
        if (studentDoc.exists()) {
          const studentData = { id: studentDoc.id, ...studentDoc.data() };
          setSelectedStudent(studentData);
          setSponsorStatus(studentData.sponsor === "yes");
        } else {
          console.error("No such student!");
          setSelectedStudent(null);
        }
      } catch (error) {
        console.error("Error fetching student by ID:", error);
      }
    };

    if (student) {
      if (isAdmin) {
        // Fetch the student directly if the admin is accessing via a link
        fetchStudentById(student);
      } else {
        const foundStudent = studentsList.find((s) => s.id === student);
        if (foundStudent) {
          setSelectedStudent(foundStudent);
          setSponsorStatus(foundStudent.sponsor === "yes");
        }
      }
    }
  }, [studentsList, student, isAdmin]);

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const filteredStudentsList = studentsList.filter((student) => {
    const shouldInclude =
      student.current_class &&
      (student.ready_for_sponsor === undefined || student.ready_for_sponsor) &&
      (isAdmin || !sponsoredStudents.has(student.name));
    return shouldInclude;
  });

  const nonSponsoredStudents = shuffleArray(
    filteredStudentsList.filter(
      (student) => !sponsoredStudents.has(student.name)
    )
  );

  const sponsoredStudentsList = filteredStudentsList.filter((student) =>
    sponsoredStudents.has(student.name)
  );

  const handleDownloadPDF = async () => {
    setIsLoadingDownload(true);
    console.log("PDF Reference at click:", pdfRef.current);

    if (!pdfRef.current) {
      alert("PDF reference is not ready. Try again.");
      return;
    }

    try {
      // Convert Firestore Storage images to Base64
      const replaceImagesWithBase64 = async (element) => {
        const images = element.querySelectorAll("img");
        const imagePromises = Array.from(images).map(async (img) => {
          if (img.src.startsWith("data:image")) return; // Skip if already Base64

          try {
            const response = await fetch(img.src, { mode: "cors" });
            const blob = await response.blob();
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                img.src = reader.result; // Replace img src with Base64
                resolve();
              };
              reader.readAsDataURL(blob);
            });
          } catch (error) {
            console.warn("Failed to load image:", img.src, error);
          }
        });

        await Promise.all(imagePromises);
      };

      // Wait for all images to be converted to Base64
      await replaceImagesWithBase64(pdfRef.current);

      const canvas = await html2canvas(pdfRef.current, {
        scale: 2,
        useCORS: true, // Ensures images from Firestore can be loaded
        logging: false,
      });

      const imgData = canvas.toDataURL("image/png");

      const extraBottomPadding = 50;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width + 40, canvas.height + 40 + extraBottomPadding], // Add padding
      });

      const padding = 20; // Adjust padding
      pdf.addImage(
        imgData,
        "PNG",
        padding,
        padding,
        canvas.width,
        canvas.height
      );

      // Use student name in the filename
      const fileName = selectedStudent?.name
        ? `${selectedStudent.name.replace(/\s+/g, " ")}.pdf`
        : "student_info.pdf";

      pdf.save(fileName);
      setIsLoadingDownload(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setIsLoadingDownload(false);
    }
  };

  const handleNextMessage = () => {
    if (studentMessages.length > 0) {
      setCurrentMessageIndex(
        (prevIndex) => (prevIndex + 1) % studentMessages.length
      );
    }
  };

  const handlePreviousMessage = () => {
    if (studentMessages.length > 0) {
      setCurrentMessageIndex(
        (prevIndex) =>
          (prevIndex - 1 + studentMessages.length) % studentMessages.length
      );
    }
  };

  const handleReadMore = (student) => {
    const isSponsored = sponsoredStudents.has(student.name);
    if (isSponsored && isAdmin) {
      navigate(`/admin/sponsorship/${student.id}`);
    } else if (!isSponsored) {
      navigate(`/sponsorship/${student.id}`);
    }
  };

  const handleGoBack = () => {
    setSelectedStudent(null);
    isAdmin ? navigate("/admin/sponsorship") : navigate("/sponsorship");
  };

  const navigateToStudent = (student) => {
    if (isAdmin) {
      navigate(`/admin/sponsorship/${student.id}#student`);
    } else {
      navigate(`/sponsorship/${student.id}#student`);
    }
  };

  const handleStudentUpdate = async () => {
    try {
      const storage = getStorage();

      // Handle image uploads
      let imageUrl = selectedStudent?.image || "";
      if (newStudentImage) {
        const storageRef = ref(
          storage,
          `student_images/${newStudentImage.name}`
        );
        await uploadBytes(storageRef, newStudentImage);
        imageUrl = await getDownloadURL(storageRef);
      }

      let imageUrl2 = selectedStudent?.image2 || "";
      if (newStudentImage2) {
        const storageRef2 = ref(
          storage,
          `student_images/${newStudentImage2.name}`
        );
        await uploadBytes(storageRef2, newStudentImage2);
        imageUrl2 = await getDownloadURL(storageRef2);
      }

      // Prepare updated student object
      const updatedStudent = {
        name: newStudentName || selectedStudent.name || "Unknown",
        image: imageUrl,
        birthday: newStudentBirthday
          ? new Date(newStudentBirthday)
          : selectedStudent.birthday || null,
        gender: newStudentGender || selectedStudent.gender || "Unknown",
        description: newStudentDescription || selectedStudent.description || "",
        image2: imageUrl2,
        message: newStudentMessage, // Allow empty messages explicitly
      };

      // Update the student document in Firestore
      const studentDocRef = doc(db, "students", selectedStudent.id); // Use the student's ID
      const studentDocSnapshot = await getDoc(studentDocRef);

      if (studentDocSnapshot.exists()) {
        await setDoc(studentDocRef, updatedStudent, { merge: true });
        console.log("Student updated successfully");
        alert("Student updated successfully.");
        setEdit(false);

        // Fetch updated student data immediately
        const updatedDoc = await getDoc(studentDocRef);
        if (updatedDoc.exists()) {
          setSelectedStudent({ id: updatedDoc.id, ...updatedDoc.data() });
        }
      } else {
        console.error("Student not found in Firestore.");
        alert("Student not found. Please refresh and try again.");
      }
    } catch (error) {
      console.error("Error updating student:", error);
      alert("Failed to update student. Please try again.");
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setNewStudentImage(file);
  };

  const handleImageUpload2 = async (e) => {
    const file = e.target.files[0];
    setNewStudentImage2(file);
  };

  const calculateAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday.toDate());
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const firstName =
    selectedStudent && selectedStudent.name
      ? selectedStudent.name.split(" ")[0]
      : "";
  const pronoun =
    selectedStudent && selectedStudent.gender === "Boy" ? "his" : "her";
  const pronoun_2 =
    selectedStudent && selectedStudent.gender === "Boy" ? "him" : "her";
  const gender =
    selectedStudent && selectedStudent.gender === "Boy" ? "he" : "she";

  const paragraphs =
    selectedStudent && selectedStudent.description
      ? selectedStudent.description.split("\n")
      : [];

  // Extract the first paragraph and remaining paragraphs
  const firstParagraph = paragraphs.length > 0 ? paragraphs[0] : "";
  const remainingParagraphs = paragraphs.slice(1);

  const [nonSponsoredOrder, setNonSponsoredOrder] = useState([]);
  const previewOrderRef = useRef(null);

  useEffect(() => {
    const filtered = studentsList.filter(
      (student) =>
        student.current_class &&
        (student.ready_for_sponsor === undefined ||
          student.ready_for_sponsor) &&
        !sponsoredStudents.has(student.name)
    );
    setNonSponsoredOrder(shuffleArray(filtered));
  }, [studentsList, sponsoredStudents]);

  useEffect(() => {
    if (!previewOrderRef.current && studentsList.length > 0) {
      const filteredPreview = studentsList.filter(
        (student) =>
          student.ready_for_sponsor === true &&
          student.image2 &&
          !sponsoredStudents.has(student.name)
      );
      previewOrderRef.current = shuffleArray(filteredPreview);
    }
  }, [studentsList, sponsoredStudents]);

  const handleNextStudent = () => {
    if (nonSponsoredOrder.length === 0) return;
    if (!selectedStudent) {
      const firstStudent = nonSponsoredOrder[0];
      setSelectedStudent(firstStudent);
      navigateToStudent(firstStudent);
    } else {
      const currentIndex = nonSponsoredOrder.findIndex(
        (student) => student.id === selectedStudent.id
      );
      if (currentIndex !== -1) {
        const nextIndex = (currentIndex + 1) % nonSponsoredOrder.length;
        const nextStudent = nonSponsoredOrder[nextIndex];
        setSelectedStudent(nextStudent);
        navigateToStudent(nextStudent);
      }
    }
  };

  const handlePreviousStudent = () => {
    if (nonSponsoredOrder.length === 0) return;
    if (!selectedStudent) {
      const firstStudent = nonSponsoredOrder[0];
      setSelectedStudent(firstStudent);
      navigateToStudent(firstStudent);
    } else {
      const currentIndex = nonSponsoredOrder.findIndex(
        (student) => student.id === selectedStudent.id
      );
      if (currentIndex !== -1) {
        const previousIndex =
          (currentIndex - 1 + nonSponsoredOrder.length) %
          nonSponsoredOrder.length;
        const previousStudent = nonSponsoredOrder[previousIndex];
        setSelectedStudent(previousStudent);
        navigateToStudent(previousStudent);
      }
    }
  };

  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isRandomChildVisible, setIsRandomChildVisible] = useState(false);

  const toggleVisibility = () => {
    setIsInfoVisible(!isInfoVisible);
    setIsRandomChildVisible(false);
  };

  const toggleRandomChild = () => {
    setIsRandomChildVisible(!isRandomChildVisible);
    setIsInfoVisible(false);
  };

  return (
    <>
      <div>
        <Locationscroll />
        <Navbar isAdmin={isAdmin} />
        <div className="row" id="flex">
          {/* <SponsorSide /> */}
          <div className="main max-1000">
            <div className="article">
              {(!selectedStudent || !userData) && (
                <>
                  <div>
                    <div>
                      <h2>Support A Child With Education</h2>
                      <p>
                        <i class="fa-solid fa-heart"></i> For as little as US $1
                        per day, or $30 a month, sponsor a child in the Kibera
                        slums - offering life and hope, quality education,
                        medical assistance and better living standards for them
                        and their families.
                      </p>
                      <p>
                        <i class="fa-solid fa-users"></i> We also offer shared
                        sponsorships where multiple sponsors can support one
                        child. Kindly register with your preferred amount and
                        note that you want to sponsor a child in the comment
                        section.
                      </p>
                      <SponsorshipIncludes />
                      <div>
                        <div
                          className="feature-box blue sponsorshipInfo"
                          onClick={toggleVisibility}
                        >
                          <i class="fa-regular fa-circle-question"></i> How does
                          a sponsorship work?
                        </div>
                        <div
                          className="feature-box green sponsorshipInfo"
                          onClick={toggleRandomChild}
                        >
                          <i class="fa-solid fa-child"></i> Sponsor a random
                          child
                        </div>
                        <br />

                        {isInfoVisible && (
                          <div
                            className="hideable-content"
                            onClick={toggleVisibility}
                          >
                            <h3>
                              What does it mean to sponsor a child with us?
                            </h3>

                            <p>
                              We are committed to ensuring that our programs are
                              not only meaningful, impactful, and long-term but
                              also accessible to all.
                            </p>
                            <p>
                              - Just $30 or 300 Norwegian kroner per month can
                              transform a child’s life forever. Your
                              contribution will not only support their education
                              but also cover their nutritional, medical, and
                              basic material needs.
                            </p>
                            <p>
                              - The children who are approved to receive support
                              have lost one or both parents and come from
                              families so poor that they would not have the
                              opportunity to escape poverty.
                            </p>

                            <h3>
                              What is unique from just giving to the
                              organization directly?
                            </h3>
                            <p>
                              - Through letters and updates, you will be able to
                              build a relationship with your sponsored child and
                              see the difference you make in your child's life.
                              You can engage as much or little as you want with
                              your child. You will receive three letters a year
                              from your sponsored child, as well as a biannual
                              newsletter with updates on our education program.
                            </p>
                            <p>
                              A portion of the monthly sum goes to cover the
                              child's most essential needs, such as a mattress
                              to sleep on, or an iron sheet if the roof has
                              holes.
                            </p>

                            <h3>What do I have to do?</h3>
                            <p>
                              - As a sponsor, you commit to paying a fixed
                              monthly amount of $30 or 300 Norwegian kroner per
                              child. This amount can be paid monthly, quarterly,
                              semi-annually, or annually. You can pay using the
                              payment option below, through online banking, or
                              by setting up a direct debit with your bank. You
                              are a sponsor for as long as you wish, and you can
                              terminate your commitment at any time.
                            </p>
                            <h3>How long does the sponsorship last?</h3>
                            <p>
                              - We have set the limit for the duration of
                              sponsorship until they are 18 years old. When the
                              child completes lower secondary school, it is
                              possible to help the child continue to upper
                              secondary school and further to higher education.
                            </p>
                            <div
                              className="feature-box blue closeInfo"
                              onClick={toggleVisibility}
                            >
                              Close
                            </div>
                          </div>
                        )}
                        {isRandomChildVisible && (
                          <>
                            <div className="green-border">
                              <SupportStepForm />
                            </div>

                            <br />
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <br />
                  <div>
                    <div className="message-navigation">
                      <h3>What Our Students Say</h3>
                      {studentMessages.length > 0 && (
                        <>
                          <button onClick={handlePreviousMessage}>
                            <i className="fa-solid fa-arrow-left"></i> Previous
                          </button>
                          <button onClick={handleNextMessage}>
                            Next <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </>
                      )}
                    </div>
                    {loadingMessages ? (
                      <>
                        <br />
                        <LoadingIndicator />
                        <br />
                      </>
                    ) : studentMessages.length > 0 ? (
                      <>
                        <div className="student-message-container">
                          <span className="student-message">
                            "
                            <i>
                              {studentMessages[currentMessageIndex].message}
                            </i>
                            "
                          </span>
                        </div>
                      </>
                    ) : (
                      <p>No messages from students at the moment.</p>
                    )}
                    <h3 id="studentslist">
                      Students Who Need A Sponsor{" "}
                      <i class="fa-solid fa-hand-holding-heart"></i>
                    </h3>
                  </div>
                </>
              )}

              {!isSponsor && !isAdmin && (
                <div className="students-preview-container">
                  <div className="students-preview">
                    {(previewOrderRef.current || [])
                      .slice(0, window.innerWidth <= 768 ? 2 : 4)
                      .map((student) => (
                        <div
                          key={student.id}
                          className="student-card"
                          onClick={togglePopup}
                        >
                          <img
                            src={student.image2}
                            alt={student.name}
                            className="student-card-image"
                          />
                          <p className="student-card-name">
                            {student.name.split(" ")[0]} (
                            {calculateAge(student.birthday)} years)
                          </p>
                          <div
                            className="feature-box blue goToStudent fullWidth"
                            onClick={() => handleReadMore(student)}
                          >
                            Read {student.name.split(" ")[0]}'s story{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {loadingStudents ? (
                <>
                  <br />
                  <br />
                  <LoadingIndicator />
                  <br />
                </>
              ) : (
                <>
                  {!isAdmin && (
                    <>
                      {!isSponsor && (
                        <>
                          <div className="sensitive">
                            <br />
                            <h3>
                              Meet our children{" "}
                              <i class="fa-solid fa-children"></i>
                            </h3>

                            <p>
                              Please
                              <div
                                onClick={togglePopup}
                                className="feature-box blue"
                              >
                                log in{" "}
                              </div>
                              <LoginPopup
                                isOpen={isPopupOpen}
                                onClose={togglePopup}
                                successRedirect={
                                  selectedStudent
                                    ? `/sponsorship/#${selectedStudent.id}`
                                    : "/sponsorship#studentslist"
                                }
                                failureRedirect="/"
                              />
                              to read our children's stories.
                            </p>
                            <p>
                              Our children come from very vulnerable
                              backgrounds. They have decided to share their
                              stories to the outside world, to give themselves,
                              and other children like themselves, an opportunity
                              at a better future.
                            </p>
                            <br />
                            <p>
                              At the moment, only residents of certain countries
                              are able to access their stories. Kindly get in
                              touch with us at{" "}
                              <u>
                                <a href="mailto:magnus@thewatotoprogram.org">
                                  magnus@thewatotoprogram.org
                                </a>
                              </u>{" "}
                              if you want to log in with a different phone
                              number than the ones available.
                            </p>
                            <p>
                              We ask that you respect our children's privacy and
                              refrain from sharing their stories publicly in
                              social media.
                            </p>
                            <br />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {isSponsor || isAdmin ? (
                    <>
                      {!loadingStudents ? (
                        <>
                          {!selectedStudent ? (
                            <>
                              <div className="students-container">
                                {previewOrderRef.current &&
                                previewOrderRef.current.length > 0 ? (
                                  previewOrderRef.current.map((student) => (
                                    <div
                                      key={student.id}
                                      className="student-box"
                                    >
                                      <div className="image-container">
                                        <img
                                          src={student.image}
                                          alt={student.name}
                                          className="img student-image"
                                        />
                                      </div>
                                      <div className="text-container student-overview">
                                        <p className="student-name">
                                          <b>
                                            {student.name}{" "}
                                            {student.ready_for_sponsor}
                                          </b>
                                        </p>
                                        <p>Gender: {student.gender}</p>
                                        <p>
                                          Born:{" "}
                                          {student.birthday
                                            ? new Date(
                                                student.birthday.toDate()
                                              ).toLocaleDateString()
                                            : "Unknown"}{" "}
                                          ({calculateAge(student.birthday)}{" "}
                                          years)
                                        </p>
                                        {student.grade &&
                                          student.current_year && (
                                            <p>Class: Grade {student.grade}</p>
                                          )}
                                        <div
                                          className="feature-box blue goToStudent fullWidth"
                                          onClick={() =>
                                            handleReadMore(student)
                                          }
                                        >
                                          Read {student.name.split(" ")[0]}'s
                                          story{" "}
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p>No available students at the moment.</p>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="sponsor-navigation-container"
                                id="student"
                              >
                                <div className="sponsor-info-container">
                                  <div
                                    className="feature-box"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={handleGoBack}
                                  >
                                    <i className="fa-solid fa-left-long"></i>{" "}
                                    <u className="goback">Go back</u>
                                  </div>

                                  {userData &&
                                    (userData.role === "Sponsor" ||
                                      userData.role === "Admin") && (
                                      <>
                                        {selectedStudent && sponsorStatus ? (
                                          <>
                                            <div className="feature-box green">
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {firstName} has a sponsor.
                                            </div>
                                            <span className="smallTxt">
                                              Page visible only because you are
                                              admin. <br />
                                              Users cannot see this page.
                                            </span>
                                          </>
                                        ) : (
                                          <a
                                            className="button start-sponsorship"
                                            href="#sponsor-child"
                                          >
                                            <i className="fa-solid fa-handshake-angle"></i>{" "}
                                            Sponsor {firstName}
                                          </a>
                                        )}
                                      </>
                                    )}
                                </div>
                                {filteredStudentsList.length > 1 && (
                                  <div
                                    className="change-child-container"
                                    id="/"
                                  >
                                    <div
                                      className="feature-box blue"
                                      onClick={handlePreviousStudent}
                                    >
                                      <i className="fa-solid fa-left-long"></i>{" "}
                                      Previous child
                                    </div>
                                    <div
                                      className="feature-box blue"
                                      onClick={handleNextStudent}
                                    >
                                      Next child{" "}
                                      <i className="fa-solid fa-right-long"></i>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div
                                className="student-box"
                                style={{
                                  maxWidth: "100%",
                                  position: "relative",
                                }}
                              >
                                {isAdmin && (
                                  <>
                                    <button
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "10px",
                                      }}
                                      onClick={() => setEdit(!edit)}
                                    >
                                      {edit ? "Cancel" : "Edit"}
                                    </button>
                                    <button
                                      style={{
                                        position: "absolute",
                                        right: "55px",
                                        top: "10px",
                                      }}
                                      onClick={handleDownloadPDF}
                                    >
                                      {isLoadingDownload
                                        ? "Downloading..."
                                        : "Download PDF"}
                                    </button>
                                  </>
                                )}
                                <span ref={pdfRef}>
                                  <div className="student-container-child">
                                    <div className="student-details-child">
                                      <p>
                                        {edit && (
                                          <>
                                            <button
                                              onClick={() =>
                                                handleStudentUpdate()
                                              }
                                            >
                                              SAVE CHANGES
                                            </button>
                                            <br />
                                            <br />
                                          </>
                                        )}
                                        <h3>
                                          {edit ? (
                                            <>
                                              Name:{" "}
                                              <input
                                                type="text"
                                                value={newStudentName}
                                                onChange={(e) =>
                                                  setNewStudentName(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </>
                                          ) : (
                                            selectedStudent.name ||
                                            "No name provided"
                                          )}
                                        </h3>{" "}
                                      </p>
                                      <p>
                                        Gender:{" "}
                                        {edit ? (
                                          <select
                                            value={newStudentGender}
                                            onChange={(e) =>
                                              setNewStudentGender(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="" disabled>
                                              Select
                                            </option>
                                            <option value="Boy">Boy</option>
                                            <option value="Girl">Girl</option>
                                          </select>
                                        ) : (
                                          selectedStudent.gender ||
                                          "No gender provided"
                                        )}
                                      </p>

                                      <p>
                                        Born:{" "}
                                        {edit ? (
                                          <input
                                            type="date"
                                            value={newStudentBirthday}
                                            onChange={(e) =>
                                              setNewStudentBirthday(
                                                e.target.value
                                              )
                                            }
                                          />
                                        ) : selectedStudent.birthday ? (
                                          <>
                                            {selectedStudent.birthday
                                              .toDate()
                                              .toLocaleDateString()}{" "}
                                            (
                                            {calculateAge(
                                              selectedStudent.birthday
                                            )}{" "}
                                            years)
                                          </>
                                        ) : (
                                          "No birthday provided"
                                        )}
                                      </p>
                                      {selectedStudent.grade &&
                                        selectedStudent.current_year && (
                                          <p>
                                            Class: Grade {selectedStudent.grade}
                                            , {selectedStudent.current_year}
                                          </p>
                                        )}

                                      <br />
                                      <div className="computer-only">
                                        <>
                                          <h3>About {firstName}</h3>
                                          {edit ? (
                                            <textarea
                                              value={newStudentDescription}
                                              onChange={(e) =>
                                                setNewStudentDescription(
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100%",
                                                height: "500px",
                                                border:
                                                  "0.05em solid rgb(237, 237, 237)",
                                              }}
                                            />
                                          ) : (
                                            <p>
                                              {firstParagraph ||
                                                "No description provided"}
                                            </p>
                                          )}
                                        </>
                                      </div>
                                    </div>

                                    <div className="image-container-child">
                                      {edit ? (
                                        <>
                                          <input
                                            type="file"
                                            onChange={handleImageUpload}
                                          />
                                          <img
                                            src={
                                              newStudentImage
                                                ? URL.createObjectURL(
                                                    newStudentImage
                                                  )
                                                : selectedStudent.image
                                            }
                                            alt="New student"
                                            className="img student-image-child"
                                          />
                                        </>
                                      ) : (
                                        <img
                                          src={selectedStudent.image}
                                          alt={selectedStudent.name}
                                          className="img student-image-child"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <br />
                                  <div className="mobile-only">
                                    <p>{firstParagraph}</p>
                                  </div>
                                  <div className="student-container-child">
                                    <div className="student-details-child">
                                      {!edit && (
                                        <p>
                                          {remainingParagraphs.map(
                                            (paragraph, index) => (
                                              <React.Fragment key={index}>
                                                <p>{paragraph}</p>
                                                <div
                                                  style={{ height: "5px" }}
                                                />
                                              </React.Fragment>
                                            )
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="student-container-child">
                                    <div className="student-details-child">
                                      <div className="computer-only">
                                        <br />
                                        <h3>{firstName} writes</h3>
                                        {edit ? (
                                          <textarea
                                            value={newStudentMessage}
                                            onChange={(e) =>
                                              setNewStudentMessage(
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                              border:
                                                "0.05em solid rgb(237, 237, 237)",
                                            }}
                                          />
                                        ) : (
                                          <i>
                                            {selectedStudent.message ||
                                              "No message provided"}
                                          </i>
                                        )}
                                      </div>
                                    </div>

                                    <br />

                                    <div className="image-container-child">
                                      {edit ? (
                                        <>
                                          <input
                                            type="file"
                                            onChange={handleImageUpload2}
                                          />
                                          <img
                                            src={
                                              newStudentImage2
                                                ? URL.createObjectURL(
                                                    newStudentImage2
                                                  )
                                                : selectedStudent.image2
                                            }
                                            alt="New student"
                                            className="img student-image-child"
                                          />
                                        </>
                                      ) : (
                                        <img
                                          src={selectedStudent.image2}
                                          alt={selectedStudent.name}
                                          className="img student-image-child"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="mobile-only">
                                    {selectedStudent.message && (
                                      <>
                                        <br />
                                        <h3>{firstName} writes</h3>
                                        {edit ? (
                                          <textarea
                                            value={newStudentMessage}
                                            onChange={(e) =>
                                              setNewStudentMessage(
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100px",
                                              border:
                                                "0.05em solid rgb(237, 237, 237)",
                                            }}
                                          />
                                        ) : (
                                          <i>{selectedStudent.message}</i>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <br />
                                </span>

                                <div className="student-details-child">
                                  <p>
                                    {firstName} needs your help and support. You
                                    can sponsor {firstName} to have a better and
                                    brighter future for {pronoun_2}
                                    self and {pronoun} future generations to
                                    come. Your support will enable us to
                                    continue providing {pronoun_2} with access
                                    to quality education and assist with{" "}
                                    {pronoun} home situation, gifting{" "}
                                    {pronoun_2} with better living standards.
                                  </p>
                                  <br />
                                  {selectedStudent && sponsorStatus ? (
                                    ""
                                  ) : (
                                    <a
                                      className="button start-sponsorship"
                                      style={{ padding: "10px" }}
                                      href="#sponsor-child"
                                    >
                                      START A SPONSORSHIP NOW
                                    </a>
                                  )}

                                  <br />
                                  <br />
                                </div>
                              </div>
                              <div className="change-child-container">
                                <div
                                  className="feature-box blue"
                                  onClick={handlePreviousStudent}
                                >
                                  <i className="fa-solid fa-left-long"></i>{" "}
                                  Previous child
                                </div>
                                <div
                                  className="feature-box blue"
                                  onClick={handleNextStudent}
                                >
                                  Next child{" "}
                                  <i className="fa-solid fa-right-long"></i>
                                </div>
                              </div>

                              <br />
                              {selectedStudent && sponsorStatus ? (
                                ""
                              ) : (
                                <>
                                  <h3 id="sponsor-child">
                                    Fill out the form to sponsor {firstName}
                                  </h3>
                                  <div className="green-border">
                                    <SupportStepForm
                                      step={2}
                                      paymentType="monthly"
                                    />
                                  </div>
                                  {/* <div className="article sponsoroptions">
                                    <div
                                      className={`option ${
                                        activeOption === "norwegian"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        setActiveOption("norwegian")
                                      }
                                    >
                                      <p>🇳🇴 Norwegian</p>
                                    </div>

                                    <div
                                      className={`option ${
                                        activeOption === "other" ? "active" : ""
                                      }`}
                                      onClick={() => setActiveOption("other")}
                                    >
                                      {" "}
                                      <p>🌍 International</p>
                                    </div>
                                  </div>
                                  <br />

                                  {activeOption === "norwegian" ? (
                                    <>
                                      <SponsorForm firstName={firstName} />
                                    </>
                                  ) : (
                                    <>
                                      <h3>
                                        To sponsor a child costs $30 per month.
                                      </h3>
                                      <p>
                                        To sponsor {firstName} specifically, add
                                        his name under "Write us a comment".{" "}
                                        <br /> For random child, leave the field
                                        blank.
                                      </p>
                                      <br />
                                      <iframe
                                        src="https://donorbox.org/embed/donate-to-the-watoto-library?default_interval=m&amount=30"
                                        name="donorbox"
                                        allowpaymentrequest="allowpaymentrequest"
                                        seamless="seamless"
                                        frameborder="0"
                                        scrolling="no"
                                        height="900px"
                                        width="100%"
                                        style={{
                                          maxWidth: "500px",
                                          minWidth: "250px",
                                          maxHeight: "none!important",
                                          minHeight: "650px",
                                        }}
                                        allow="payment"
                                      ></iframe>
                                    </>
                                  )} */}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <LoadingIndicator />
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <br />

            {isAdmin && !loadingStudents && (
              <>
                <br />
                <br />
                <div className="article">
                  <h2>Students with Sponsor</h2>
                  <i>(visible to Admins only)</i>
                  <br />
                  <br />
                  <div className="students-container">
                    {sponsoredStudentsList.length > 0 ? (
                      sponsoredStudentsList.map((student) => (
                        <div key={student.id} className="student-box">
                          <div className="image-container">
                            <img
                              src={student.image}
                              alt={student.name}
                              className="img student-image"
                            />
                          </div>
                          <div className="text-container student-overview">
                            <p className="student-name">
                              <b>{student.name}</b>
                            </p>
                            <p>Gender: {student.gender}</p>
                            <p>
                              Born:{" "}
                              {student.birthday
                                ? new Date(
                                    student.birthday.toDate()
                                  ).toLocaleDateString()
                                : "Unknown"}
                            </p>
                            <div
                              className="feature-box green goToStudent fullWidth"
                              onClick={() => handleReadMore(student)}
                            >
                              <i className="fa-regular fa-circle-check"></i>{" "}
                              {student.name.split(" ")[0]} has a sponsor
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No students with sponsors at the moment.</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Sponsorship;
