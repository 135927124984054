import React, { useState, useEffect } from "react";
import MyLink from "../MyLink/MyLink";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase"; // Adjust the path to your Firebase config file
import { signOut, onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../../AuthProvider";
import LoginPopup from "../../container/Auth/LoginPopup/LoginPopup";

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { setUserData } = useAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsubscribe; // Cleanup subscription
  }, []);

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
      navigate("/");
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  return (
    <div className="footer">
      <LoginPopup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        successRedirect={`/intranet`}
        failureRedirect="/"
      />
      <div className="container-footer">
        <div className="container-row">
          <div className="footer-col">
            <MyLink to="/">
              <h4>
                <a style={{ color: "white" }}>
                  <span className="notranslate">The Watoto Program</span>
                </a>
              </h4>
            </MyLink>
            <ul>
              <MyLink to="/">
                <li>
                  <a>Home</a>
                </li>
              </MyLink>
              <MyLink to="/newsletters">
                <li>
                  <a>Newsletters</a>
                </li>
              </MyLink>
              <MyLink to="/projects">
                <li>
                  <a>Our projects</a>
                </li>
              </MyLink>
            </ul>
          </div>
          <div className="footer-col">
            <MyLink to="/about">
              <h4>
                <a style={{ color: "white" }}>About</a>
              </h4>
            </MyLink>
            <ul>
              <MyLink to="/about">
                <li>
                  <a>About Us</a>
                </li>
              </MyLink>
              <MyLink to="/about/team">
                <li>
                  <a>Our Team</a>
                </li>
              </MyLink>
              <MyLink to="/about/documents">
                <li>
                  <a>Files & Documents</a>
                </li>
              </MyLink>
            </ul>
          </div>
          <div className="footer-col">
            <MyLink to="/donate">
              <h4>
                <a style={{ color: "white" }}>Engage</a>
              </h4>
            </MyLink>
            <ul>
              <MyLink to="/support">
                <li>
                  <a>Support our Work</a>
                </li>
              </MyLink>
              <MyLink to="/sponsorship">
                <li>
                  <a>Become a Sponsor</a>
                </li>
              </MyLink>
              <MyLink to="/contact#contact">
                <li>
                  <a>Contact Us</a>
                </li>
              </MyLink>
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <a
                href="https://www.facebook.com/thewatotoprogram"
                target="_blank"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/thewatotoprogram"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/the-watoto-program"
                target="_blank"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCBiw_orAp_qbgRQc-_zUZ7w"
                target="_blank"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <ul>
              <li>
                <p>@thewatotoprogram</p>
                {currentUser ? (
                  <li onClick={handleLogout}>
                    <p className="adminLoginLink">Intranet logout</p>
                  </li>
                ) : (
                  <li onClick={togglePopup}>
                    <p className="adminLoginLink">Intranet login</p>
                  </li>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-end">
        <div className="footer-item">
          © {new Date().getFullYear()} The Watoto Program
        </div>
        <div className="footer-item center">
          <span className="item-block">KBR/CBO/5/4/2023(439) (Kenya)</span>
          <span className="separator">|</span>
          <span className="item-block">Org. nr. 931 464 817 (Norway)</span>
        </div>
        <div className="footer-item">
          Developed and designed by Magnus Heide
        </div>
      </div>
    </div>
  );
};

export default Footer;
