import React, { useState } from "react";
import BudgetMonth from "./BudgetMonth";

function BudgetContainer({ selectedMonth, setSelectedMonth }) {
  const [activePage, setActivePage] = useState("BudgetMonth");

  return (
    <div>
      {activePage === "BudgetMonth" && (
        <BudgetMonth
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      )}
    </div>
  );
}

export default BudgetContainer;
