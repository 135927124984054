import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../AuthProvider";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../../../firebase";

const Overview = () => {
  const {
    userData,
    simulateUserAccess,
    stopSimulation,
    simulatedUser,
    isAdmin,
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [availableEmails, setAvailableEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");

  // 🔹 State for Admin Alert
  const [alertMessage, setAlertMessage] = useState("");
  const [alertActive, setAlertActive] = useState(false);
  const [editingAlert, setEditingAlert] = useState(false);

  // Fetch emails for simulation
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const staffCollection = collection(db, "staff");
        const querySnapshot = await getDocs(staffCollection);
        const emails = querySnapshot.docs.map((doc) => ({
          email: doc.id,
          name: doc.data().name || "Unknown",
        }));
        setAvailableEmails(emails);
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };

    fetchEmails();
  }, []);

  const handleSimulateAccess = () => {
    if (selectedEmail) {
      simulateUserAccess(selectedEmail);
    }
  };

  useEffect(() => {
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  // 🔹 Real-Time Listener for Alert Changes
  useEffect(() => {
    const alertRef = doc(db, "admin", "intranet-alerts");

    const unsubscribe = onSnapshot(alertRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setAlertMessage(data.message || "");
        setAlertActive(data.active || false); // Update alert state in real-time
      }
    });

    return () => unsubscribe(); // Unsubscribe when component unmounts
  }, []);

  // 🔹 Save Alert to Firestore
  const saveAlert = async () => {
    try {
      await setDoc(doc(db, "admin", "intranet-alerts"), {
        message: alertMessage,
        active: alertActive, // Save active state
      });
      setEditingAlert(false);
    } catch (error) {
      console.error("Error updating alert:", error);
    }
  };

  // 🔹 Toggle Alert Status (Updates Firestore Immediately)
  const toggleAlert = async () => {
    const newState = !alertActive;
    setAlertActive(newState); // Update UI instantly
    try {
      await setDoc(doc(db, "admin", "intranet-alerts"), {
        message: alertMessage,
        active: newState, // Save active state
      });
    } catch (error) {
      console.error("Error updating alert:", error);
    }
  };

  return (
    <div className="row2" id="flex">
      <div className="main">
        <h2>The Watoto Program - Intranet</h2>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <p>
              Logged in as: {userData?.name} | {userData?.description_1}{" "}
              {userData?.description_2 ? `& ${userData.description_2}` : ""}
            </p>
          </div>
        )}
        {userData?.email === "magnus@thewatotoprogram.org" || simulatedUser ? (
          <div>
            <br />
            <br />
            <div>
              Simulate user access:{" "}
              <select
                value={selectedEmail}
                onChange={(e) => setSelectedEmail(e.target.value)}
              >
                <option value="" disabled>
                  Select a user
                </option>
                {availableEmails.map((user) => (
                  <option key={user.email} value={user.email}>
                    {user.name} ({user.email})
                  </option>
                ))}
              </select>
              <button onClick={handleSimulateAccess}>Simulate Access</button>
              <button onClick={stopSimulation}>Stop Simulation</button>
            </div>
          </div>
        ) : null}

        {isAdmin && (
          <div className="admin-alert-settings">
            <br />
            <br />
            <h3>Alert Settings</h3>
            <label>
              <input
                type="checkbox"
                checked={alertActive}
                onChange={toggleAlert}
              />
              Show Alert to Users
            </label>
            {editingAlert ? (
              <>
                <textarea
                  value={alertMessage}
                  onChange={(e) => setAlertMessage(e.target.value)}
                  className="alert-textarea"
                />
                <button onClick={saveAlert} className="save-alert">
                  Save Alert
                </button>
              </>
            ) : (
              <>
                {" "}
                <button
                  onClick={() => setEditingAlert(true)}
                  className="edit-alert"
                >
                  Edit Alert
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
