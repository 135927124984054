// App.js
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import { sharedRoutes } from "./SharedRoutes";
import AdminRoutes from "./AdminRoutes";
import SponsorRoutes from "./SponsorRoutes";
import { Fullscreen } from "./components";
import { ProtectedRoute } from "./container";
import { useAuth } from "./AuthProvider";
import Loading from "./components/Loading/Loading";

function App() {
  Fullscreen();

  const location = useLocation();
  const { instance } = useMsal();
  const { isAdmin, isSponsor, isStaff, loadingUser } = useAuth();
  const [msalInitialized, setMsalInitialized] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize();
        const response = await instance.handleRedirectPromise();
        if (response) {
          console.log("Login/Token acquisition response:", response);
          instance.setActiveAccount(response.account);
        }
        setMsalInitialized(true);
      } catch (error) {
        console.error("Error initializing MSAL:", error);
      }
    };
    initializeMsal();
  }, [instance]);

  if (!msalInitialized) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Routes>
        {sharedRoutes.map((route) => {
          const element = React.createElement(route.component, {
            isAdmin,
            isSponsor,
            isStaff,
          });
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.layout ? (
                  <route.layout>{element}</route.layout>
                ) : (
                  element
                )
              }
            />
          );
        })}

        <Route path="*" element={<Navigate to="/" replace />} />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              {loadingUser ? null : isAdmin ? (
                <AdminRoutes />
              ) : (
                <Navigate to="/" replace />
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path="/sponsor/*"
          element={
            <ProtectedRoute>
              {loadingUser ? null : isSponsor ? (
                <SponsorRoutes />
              ) : (
                <Navigate to="/" replace />
              )}
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
