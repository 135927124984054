import React from "react";
import { MyLink } from "../../../../../components";
import "./GoBack.css";

const GoBack = () => {
  return (
    <MyLink to="/school">
      <div className="goback">
        <u className="goback-button">
          <i className="fa-solid fa-arrow-left"></i> Back
        </u>
      </div>
    </MyLink>
  );
};

export default GoBack;
