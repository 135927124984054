import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SchoolNavbar.css";
import { images } from "../../../../../constants";
import { MyLink } from "../../../../../components";

const SchoolNavbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div className="school-navbar">
      <div className="school-navbar-main">
        <div className="school-navbar-mobile" onClick={toggleNav}>
          <div>
            <MyLink to="/school" onClick={(e) => e.stopPropagation()}>
              <div className="school-navbar-logo">
                <b>The Watoto Academy</b>
                <br />
                <span>Our Sponsorship School</span>
              </div>
            </MyLink>
          </div>

          <div className="school-navbar-toggle">
            {!isNavVisible ? (
              <i className="fa-solid fa-bars"></i>
            ) : (
              <i className="fa-solid fa-x"></i>
            )}
          </div>
        </div>

        <div className={`school-navbar-items ${isNavVisible ? "show" : ""}`}>
          <MyLink to="/school">Home</MyLink>
          <MyLink to="/school/timetable">Timetable</MyLink>
          <MyLink to="/school/concept">Concept</MyLink>
          <MyLink to="/school/community">Students & Staff</MyLink>
          <MyLink to="/school/admission">Admission</MyLink>
        </div>

        <div className="school-navbar-main school-donate">
          <MyLink to="https://www.thewatotolibrary.org" target="_blank">
            <div>
              <img src={images.TWLlogo} className="school-navbar-img" />
            </div>
          </MyLink>
          <MyLink to="/sponsorship">
            <button className="school-apply-button">Sponsor A Child</button>
          </MyLink>
        </div>
      </div>
    </div>
  );
};

export default SchoolNavbar;
