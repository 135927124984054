import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../../../firebase";
import { Navbar, Footer, MyLink } from "../../../components";
import LoadingIndicator from "../../../components/Loading/LoadingIndicator";
import { images } from "../../../constants";
import "./Documents.css";

const Documents = ({ isAdmin }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedCategory, setEditedCategory] = useState("");
  const [editedIcon, setEditedIcon] = useState("");
  const [editingDocId, setEditingDocId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "documents"));
      const docs = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setDocuments(docs);

      // Build sorted unique categories based on categoryOrder
      const catMap = {};
      docs.forEach((d) => {
        if (!catMap[d.category]) {
          catMap[d.category] = {
            order: d.categoryOrder ?? 9999,
            icon: d.categoryIcon || "",
          };
        }
      });
      const sortedCats = Object.keys(catMap).sort(
        (a, b) => catMap[a].order - catMap[b].order
      );
      setCategories(sortedCats);
      setLoading(false);
    };
    fetchDocuments();
  }, []);

  const handleUpload = async () => {
    if (!file) return;
    try {
      const fileRef = ref(storage, `documents/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileURL = await getDownloadURL(fileRef);
      const finalCategory = selectedCategory || category;
      // Determine categoryOrder and icon if category exists
      let categoryOrder = categories.indexOf(finalCategory);
      let categoryIcon = "";
      const existingDoc = documents.find((d) => d.category === finalCategory);
      if (existingDoc) {
        categoryOrder = existingDoc.categoryOrder ?? categoryOrder;
        categoryIcon = existingDoc.categoryIcon || "";
      } else {
        categoryOrder = categories.length;
      }
      await addDoc(collection(db, "documents"), {
        title,
        category: finalCategory,
        fileURL,
        description,
        createdAt: new Date(),
        order: Date.now(), // document order within category
        categoryOrder,
        categoryIcon,
      });
      window.location.reload();
    } catch (error) {
      alert("Error uploading document: ", error);
    }
  };

  const handleDelete = async (id, fileURL) => {
    if (!window.confirm("Are you sure you want to delete this document?"))
      return;
    try {
      await deleteDoc(doc(db, "documents", id));
      const fileRef = ref(storage, fileURL);
      await deleteObject(fileRef);
      setDocuments(documents.filter((docItem) => docItem.id !== id));
    } catch (error) {
      alert("Error deleting document: ", error);
    }
  };

  // Group documents by category and sort by document order
  const groupedDocuments = documents.reduce((acc, docItem) => {
    if (!acc[docItem.category]) {
      acc[docItem.category] = [];
    }
    acc[docItem.category].push(docItem);
    return acc;
  }, {});
  Object.keys(groupedDocuments).forEach((cat) => {
    groupedDocuments[cat].sort((a, b) => a.order - b.order);
  });

  const handleCategoryRename = async (oldCategory, newCategory, newIcon) => {
    const docsToUpdate = documents.filter(
      (docItem) => docItem.category === oldCategory
    );
    for (const docItem of docsToUpdate) {
      const docRef = doc(db, "documents", docItem.id);
      await updateDoc(docRef, { category: newCategory, categoryIcon: newIcon });
    }
    const updatedDocuments = documents.map((docItem) =>
      docItem.category === oldCategory
        ? { ...docItem, category: newCategory, categoryIcon: newIcon }
        : docItem
    );
    setDocuments(updatedDocuments);
    const catMap = {};
    updatedDocuments.forEach((d) => {
      if (!catMap[d.category]) {
        catMap[d.category] = {
          order: d.categoryOrder ?? 9999,
          icon: d.categoryIcon || "",
        };
      }
    });
    const sortedCats = Object.keys(catMap).sort(
      (a, b) => catMap[a].order - catMap[b].order
    );
    setCategories(sortedCats);
  };

  const handleEditClick = (cat) => {
    setEditingCategory(cat);
    setEditedCategory(cat);
    const currentIcon =
      groupedDocuments[cat] && groupedDocuments[cat].length > 0
        ? groupedDocuments[cat][0].categoryIcon || ""
        : "";
    setEditedIcon(currentIcon);
  };

  const handleSaveCategory = async (oldCategory) => {
    await handleCategoryRename(oldCategory, editedCategory, editedIcon);
    setEditingCategory(null);
    setEditedCategory("");
    setEditedIcon("");
  };

  const handleEditDocClick = (docItem) => {
    setEditingDocId(docItem.id);
    setEditedTitle(docItem.title);
    setEditedDescription(docItem.description);
  };

  const handleSaveDoc = async (docItem) => {
    const docRef = doc(db, "documents", docItem.id);
    await updateDoc(docRef, {
      title: editedTitle,
      description: editedDescription,
    });
    const updatedDocuments = documents.map((d) =>
      d.id === docItem.id
        ? { ...d, title: editedTitle, description: editedDescription }
        : d
    );
    setDocuments(updatedDocuments);
    setEditingDocId(null);
    setEditedTitle("");
    setEditedDescription("");
  };

  // Document sorting functions with default order values if missing
  const moveDocumentUp = async (docItem, docsInCategory) => {
    const index = docsInCategory.findIndex((d) => d.id === docItem.id);
    if (index === 0) return;
    const prevDoc = docsInCategory[index - 1];
    const currentOrder =
      docItem.order !== undefined && docItem.order !== null
        ? docItem.order
        : index;
    const prevOrder =
      prevDoc.order !== undefined && prevDoc.order !== null
        ? prevDoc.order
        : index - 1;
    try {
      const docRef1 = doc(db, "documents", docItem.id);
      const docRef2 = doc(db, "documents", prevDoc.id);
      await updateDoc(docRef1, { order: prevOrder });
      await updateDoc(docRef2, { order: currentOrder });
      const updatedDocuments = documents.map((d) => {
        if (d.id === docItem.id) return { ...d, order: prevOrder };
        if (d.id === prevDoc.id) return { ...d, order: currentOrder };
        return d;
      });
      setDocuments(updatedDocuments);
    } catch (err) {
      alert(err);
    }
  };

  const moveDocumentDown = async (docItem, docsInCategory) => {
    const index = docsInCategory.findIndex((d) => d.id === docItem.id);
    if (index === docsInCategory.length - 1) return;
    const nextDoc = docsInCategory[index + 1];
    const currentOrder =
      docItem.order !== undefined && docItem.order !== null
        ? docItem.order
        : index;
    const nextOrder =
      nextDoc.order !== undefined && nextDoc.order !== null
        ? nextDoc.order
        : index + 1;
    try {
      const docRef1 = doc(db, "documents", docItem.id);
      const docRef2 = doc(db, "documents", nextDoc.id);
      await updateDoc(docRef1, { order: nextOrder });
      await updateDoc(docRef2, { order: currentOrder });
      const updatedDocuments = documents.map((d) => {
        if (d.id === docItem.id) return { ...d, order: nextOrder };
        if (d.id === nextDoc.id) return { ...d, order: currentOrder };
        return d;
      });
      setDocuments(updatedDocuments);
    } catch (err) {
      alert(err);
    }
  };

  // Category ordering functions (update categoryOrder in firebase)
  const updateCategoryOrderInFirebase = async (categoryName, order) => {
    const docsToUpdate = documents.filter((d) => d.category === categoryName);
    for (const d of docsToUpdate) {
      const docRef = doc(db, "documents", d.id);
      await updateDoc(docRef, { categoryOrder: order });
    }
  };

  const moveCategoryUp = async (index) => {
    if (index === 0) return;
    const newCategories = [...categories];
    [newCategories[index - 1], newCategories[index]] = [
      newCategories[index],
      newCategories[index - 1],
    ];
    await updateCategoryOrderInFirebase(newCategories[index - 1], index - 1);
    await updateCategoryOrderInFirebase(newCategories[index], index);
    setCategories(newCategories);
  };

  const moveCategoryDown = async (index) => {
    if (index === categories.length - 1) return;
    const newCategories = [...categories];
    [newCategories[index], newCategories[index + 1]] = [
      newCategories[index + 1],
      newCategories[index],
    ];
    await updateCategoryOrderInFirebase(newCategories[index], index);
    await updateCategoryOrderInFirebase(newCategories[index + 1], index + 1);
    setCategories(newCategories);
  };

  return (
    <>
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <div className="main max-1000">
          <MyLink to="/about">
            <div className="div-back">
              <p className="allNewsLetters">
                <i className="fa-solid fa-arrow-left"></i> Back to About
              </p>
            </div>
          </MyLink>
          <div className="article">
            <h2>Files & Documents</h2>
            <p>
              Here you will find documents related to our organization,
              projects, and activities.
            </p>

            <br />
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {isAdmin && editMode && (
                  <>
                    {showUploadPopup && (
                      <div className="modal">
                        <div className="modal-content">
                          <span
                            className="close"
                            onClick={() => setShowUploadPopup(false)}
                            style={{ cursor: "pointer" }}
                          >
                            &times;
                          </span>
                          <div className="upload-section">
                            <h2>Add New Document</h2>
                            <table>
                              <tbody>
                                <tr>
                                  <td>File Name</td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Title"
                                      onChange={(e) => setTitle(e.target.value)}
                                    />
                                  </td>
                                </tr>
                                <tr rowSpan="2">
                                  <td rowSpan="2">Category</td>
                                  <td>
                                    <select
                                      onChange={(e) =>
                                        setSelectedCategory(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        Select an existing category
                                      </option>
                                      {categories.map((cat, index) => (
                                        <option key={index} value={cat}>
                                          {cat}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Or create new category"
                                      onChange={(e) =>
                                        setCategory(e.target.value)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Upload Document</td>
                                  <td>
                                    <input
                                      type="file"
                                      onChange={(e) =>
                                        setFile(e.target.files[0])
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Description</td>
                                  <td>
                                    <input
                                      placeholder="Write here"
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                    ></input>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <br />
                            <button
                              className="popup-create-button"
                              onClick={() => {
                                handleUpload();
                                setShowUploadPopup(false);
                              }}
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="document-categories-container">
                  {categories.map((cat, index) => {
                    const docs = (groupedDocuments[cat] || []).slice();
                    docs.sort((a, b) => a.order - b.order);
                    return (
                      <div key={cat} className="document-category">
                        {editingCategory === cat ? (
                          <div>
                            <input
                              type="text"
                              placeholder="Enter icon class (e.g. fa-upload)"
                              value={editedIcon}
                              onChange={(e) => setEditedIcon(e.target.value)}
                            />
                            <input
                              type="text"
                              value={editedCategory}
                              onChange={(e) =>
                                setEditedCategory(e.target.value)
                              }
                            />
                            <button onClick={() => handleSaveCategory(cat)}>
                              Save
                            </button>
                            <button onClick={() => setEditingCategory(null)}>
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <h3 className="documents-header">
                            {groupedDocuments[cat]?.[0]?.categoryIcon && (
                              <i
                                className={`fa-solid ${groupedDocuments[cat][0].categoryIcon}`}
                                style={{ marginRight: "8px" }}
                              ></i>
                            )}
                            {cat}{" "}
                            {editMode && (
                              <>
                                <i
                                  className="fa fa-pencil"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleEditClick(cat)}
                                ></i>{" "}
                                <span
                                  onClick={() => moveCategoryUp(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fa-solid fa-arrow-up"></i>
                                </span>{" "}
                                <span
                                  onClick={() => moveCategoryDown(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fa-solid fa-arrow-down"></i>
                                </span>
                              </>
                            )}
                          </h3>
                        )}

                        {docs.map((doc) => (
                          <div key={doc.id} className="document-card">
                            {editingDocId === doc.id ? (
                              <div className="document-edit-row">
                                <input
                                  type="text"
                                  value={editedTitle}
                                  onChange={(e) =>
                                    setEditedTitle(e.target.value)
                                  }
                                />
                                <input
                                  type="text"
                                  value={editedDescription}
                                  onChange={(e) =>
                                    setEditedDescription(e.target.value)
                                  }
                                />
                                <button onClick={() => handleSaveDoc(doc)}>
                                  Save
                                </button>
                                <button onClick={() => setEditingDocId(null)}>
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div className="document-view-row">
                                <div className="document-title">
                                  <u>
                                    <a
                                      href={doc.fileURL}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {doc.title}
                                    </a>
                                  </u>
                                </div>
                                <div className="document-description">
                                  {doc.description}
                                </div>
                                <div className="document-actions">
                                  {editMode && (
                                    <>
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "8px",
                                        }}
                                        onClick={() => handleEditDocClick(doc)}
                                      ></i>
                                      <i
                                        className="fa-solid fa-arrow-up"
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "4px",
                                        }}
                                        onClick={() =>
                                          moveDocumentUp(doc, docs)
                                        }
                                      ></i>
                                      <i
                                        className="fa-solid fa-arrow-down"
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "8px",
                                        }}
                                        onClick={() =>
                                          moveDocumentDown(doc, docs)
                                        }
                                      ></i>
                                      <span
                                        onClick={() =>
                                          handleDelete(doc.id, doc.fileURL)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa-solid fa-square-minus"></i>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>

          {isAdmin && (
            <>
              <br />
              <div className="article">
                <b>
                  Admin Controls{" "}
                  <button
                    className="remark-button add blue"
                    onClick={() => setEditMode(!editMode)}
                  >
                    <i class="fa-solid fa-pen-to-square"></i>{" "}
                    {editMode ? "Exit Edit Mode" : "Enter Edit Mode"}
                  </button>{" "}
                  {isAdmin && editMode && (
                    <button
                      className="remark-button add"
                      onClick={() => setShowUploadPopup(true)}
                    >
                      <i class="fa-solid fa-plus"></i> Add Document
                    </button>
                  )}
                </b>
              </div>
            </>
          )}
          <br />

          <img src={images.cover} className="img noMarginLeft" alt="" />

          <br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Documents;
