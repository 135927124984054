import React, { useState, useCallback, useEffect } from "react";
import "./Timetable.css";
import { images } from "../../constants";
import { Navbar, InfoBar, GoBack, SectionToggle } from "../../components";

const Timetable = () => {
  return (
    <div className="subjects" id="page-container">
      <div className="bodyMargin left content">
        <GoBack />
        <h2>Timetable</h2>
        <p>
          The Watoto Academy follows the new CBC curriculum (Competency Based
          Curriculum) set out by the Kenya Ministry of Education.
        </p>

        <SectionToggle
          sections={[
            { id: "grade_7", label: "Grade 7" },
            { id: "grade_8", label: "Grade 8" },
            ,
          ]}
        >
          {(activeSection) => (
            <>
              {activeSection === "grade_7" && (
                <div className="blueBackground">
                  <h3>Timetable Grade 7 - 2025</h3>
                  <img src={images.timetable_grade7} className="timetable" />
                  <p>
                    ASM = Morning assembly | ECA = Extra-curricular activities
                  </p>
                </div>
              )}

              {activeSection === "grade_8" && (
                <div className="blueBackground">
                  <h3>Timetable Grade 8 - 2025</h3>
                  <img src={images.timetable_grade8} className="timetable" />
                  <p>
                    ASM = Morning assembly | ECA = Extra-curricular activities
                  </p>
                </div>
              )}
            </>
          )}
        </SectionToggle>
      </div>
    </div>
  );
};

export default Timetable;
