import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
  query,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useAuth } from "../../AuthProvider";
import "./Partners.css";

const Partners = () => {
  const { isAdmin } = useAuth();

  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  // Admin popup toggles
  const [showEditPopup, setShowEditPopup] = useState(false);

  // Edit state
  const [editPartnerId, setEditPartnerId] = useState(null);
  const [partnerName, setPartnerName] = useState("");
  const [partnerWebsite, setPartnerWebsite] = useState("");
  const [partnerFile, setPartnerFile] = useState(null);
  const [partnerOrder, setPartnerOrder] = useState(0);

  // Add state
  const [newName, setNewName] = useState("");
  const [newWebsite, setNewWebsite] = useState("");
  const [newFile, setNewFile] = useState(null);
  const [newOrder, setNewOrder] = useState(0);

  // Loading / saving indicators
  const [saving, setSaving] = useState(false);

  // Ref + flags for sizing logic
  const containerRef = useRef(null);
  const [fitsOnScreen, setFitsOnScreen] = useState(true); // If true, no marquee
  const [scrollDistance, setScrollDistance] = useState(0); // how far to animate when in marquee mode

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        setLoading(true);
        const qRef = query(collection(db, "partners"), orderBy("order", "asc"));
        const snapshot = await getDocs(qRef);
        const items = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setPartners(items);
      } catch (error) {
        console.error("Error fetching partners:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPartners();
  }, []);

  /**
   * After partners change (or window resizes), measure whether the total content
   * fits inside the container. If it overflows, we enable marquee mode (duplicate
   * the items). If it fits, we show them once and space them out to fill the width.
   */
  useEffect(() => {
    const checkSize = () => {
      if (!containerRef.current) return;
      const el = containerRef.current;
      const contentWidth = el.scrollWidth;
      const containerWidth = el.offsetWidth;

      if (contentWidth <= containerWidth) {
        // Everything fits on screen
        setFitsOnScreen(true);
        setScrollDistance(0);
      } else {
        // Overflows => let's do marquee
        setFitsOnScreen(false);

        // We'll clone the content, so total "two sets" width is el.scrollWidth * 2
        // The actual needed distance is half of that minus container width
        // Or simpler approach: measure after we actually have two sets in the DOM
        // For this example, let's just guess: distance = contentWidth - containerWidth
        setScrollDistance(contentWidth - containerWidth);
      }
    };

    checkSize();
    window.addEventListener("resize", checkSize);
    return () => window.removeEventListener("resize", checkSize);
  }, [partners]);

  // ------------------- Sorting Logic -------------------
  const moveUp = (index) => {
    if (index < 1) return;
    swapPositions(index, index - 1);
  };
  const moveDown = (index) => {
    if (index >= partners.length - 1) return;
    swapPositions(index, index + 1);
  };
  const swapPositions = async (fromIndex, toIndex) => {
    const updated = [...partners];
    [updated[fromIndex], updated[toIndex]] = [
      updated[toIndex],
      updated[fromIndex],
    ];

    // Reassign order
    for (let i = 0; i < updated.length; i++) {
      updated[i].order = i;
    }
    setPartners(updated);

    // Update Firestore
    try {
      for (const p of updated) {
        const docRef = doc(db, "partners", p.id);
        await updateDoc(docRef, { order: p.order });
      }
    } catch (err) {
      console.error("Error reordering partners:", err);
    }
  };

  // ------------------- Editing Existing Partner -------------------
  const handleEditClick = (partner) => {
    setEditPartnerId(partner.id);
    setPartnerName(partner.name || "");
    setPartnerWebsite(partner.website || "");
    setPartnerFile(null);
    setPartnerOrder(typeof partner.order === "number" ? partner.order : 0);
  };

  const handleSaveEdit = async () => {
    if (!editPartnerId) return;
    if (!partnerName.trim() || !partnerWebsite.trim()) {
      alert("Name and website are required.");
      return;
    }
    setSaving(true);

    let logoUrl = "";
    if (partnerFile) {
      try {
        const storageRef = ref(
          storage,
          `partners/${Date.now()}_${partnerFile.name}`
        );
        await uploadBytes(storageRef, partnerFile);
        logoUrl = await getDownloadURL(storageRef);
      } catch (err) {
        console.error("Error uploading image:", err);
        alert("Failed to upload new logo file.");
        setSaving(false);
        return;
      }
    }

    try {
      const docRef = doc(db, "partners", editPartnerId);
      await updateDoc(docRef, {
        name: partnerName,
        website: partnerWebsite,
        ...(logoUrl && { logoUrl }),
        order: partnerOrder,
      });

      setPartners((prev) =>
        prev.map((p) =>
          p.id === editPartnerId
            ? {
                ...p,
                name: partnerName,
                website: partnerWebsite,
                ...(logoUrl && { logoUrl }),
                order: partnerOrder,
              }
            : p
        )
      );
      resetEditForm();
    } catch (err) {
      console.error("Error updating partner:", err);
      alert("Error updating partner. Check console for details.");
    } finally {
      setSaving(false);
    }
  };

  const resetEditForm = () => {
    setEditPartnerId(null);
    setPartnerName("");
    setPartnerWebsite("");
    setPartnerFile(null);
    setPartnerOrder(0);
  };

  // ------------------- Adding New Partner -------------------
  const handleAddPartner = async () => {
    if (!newName.trim() || !newWebsite.trim()) {
      alert("Name and website are required.");
      return;
    }
    setSaving(true);

    let logoUrl = "";
    if (newFile) {
      try {
        const storageRef = ref(
          storage,
          `partners/${Date.now()}_${newFile.name}`
        );
        await uploadBytes(storageRef, newFile);
        logoUrl = await getDownloadURL(storageRef);
      } catch (err) {
        console.error("Error uploading image:", err);
        alert("Failed to upload logo file.");
        setSaving(false);
        return;
      }
    }

    try {
      const docRef = await addDoc(collection(db, "partners"), {
        name: newName,
        website: newWebsite,
        logoUrl,
        order: newOrder,
      });
      setPartners((prev) => [
        ...prev,
        {
          id: docRef.id,
          name: newName,
          website: newWebsite,
          logoUrl,
          order: newOrder,
        },
      ]);
      resetNewPartnerForm();
    } catch (err) {
      console.error("Error adding partner:", err);
      alert("Error adding partner. Check console for details.");
    } finally {
      setSaving(false);
    }
  };

  const resetNewPartnerForm = () => {
    setNewName("");
    setNewWebsite("");
    setNewFile(null);
    setNewOrder(partners.length);
  };

  // ------------------- Deleting -------------------
  const handleDeletePartner = async (id) => {
    if (!window.confirm("Are you sure you want to delete this partner?"))
      return;
    try {
      await deleteDoc(doc(db, "partners", id));
      setPartners((prev) => prev.filter((p) => p.id !== id));
    } catch (err) {
      console.error("Error deleting partner:", err);
    }
  };

  // ------------------- Rendering -------------------
  return (
    <div className="partners-section">
      <h2>Our Partners</h2>
      {loading && <p>Loading partners...</p>}

      {isAdmin && (
        <span
          className="edit-partners-btn"
          onClick={() => setShowEditPopup(!showEditPopup)}
        >
          {showEditPopup ? "Close Editor" : "Edit"}
        </span>
      )}

      {fitsOnScreen ? (
        <div className="partners-list no-scroll" ref={containerRef}>
          {partners.map((p) => (
            <div key={p.id} className="partner-item">
              <a href={p.website} target="_blank" rel="noreferrer">
                <img src={p.logoUrl} alt={p.name} />
              </a>
            </div>
          ))}
        </div>
      ) : (
        /* MARQUEE container with duplication */
        <div
          className="marquee-container"
          ref={containerRef}
          style={
            scrollDistance > 0
              ? { "--scroll-dist": `${scrollDistance}px` }
              : undefined
          }
        >
          <div className="marquee-content">
            {partners.map((p) => (
              <div key={"first-" + p.id} className="partner-item">
                <a href={p.website} target="_blank" rel="noreferrer">
                  <img src={p.logoUrl} alt={p.name} />
                </a>
              </div>
            ))}
            {partners.map((p) => (
              <div key={"second-" + p.id} className="partner-item">
                <a href={p.website} target="_blank" rel="noreferrer">
                  <img src={p.logoUrl} alt={p.name} />
                </a>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Admin popup for editing/sorting partners */}
      {isAdmin && showEditPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Edit Partners</h2>

            <div className="partners-editor-list">
              {partners.map((p, idx) => (
                <div key={p.id} className="partner-edit-row">
                  <span className="partner-edit-text">{p.name}</span>
                  <div className="partner-edit-actions">
                    <button onClick={() => moveUp(idx)}>▲</button>
                    <button onClick={() => moveDown(idx)}>▼</button>
                    <button onClick={() => handleEditClick(p)}>Edit</button>
                    <button onClick={() => handleDeletePartner(p.id)}>
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Editing form */}
            {editPartnerId && (
              <div className="edit-partner-form">
                <h4>Editing: {partnerName}</h4>
                <p>
                  Name:{" "}
                  <input
                    type="text"
                    value={partnerName}
                    onChange={(e) => setPartnerName(e.target.value)}
                  />
                </p>
                <p>
                  Website:{" "}
                  <input
                    type="text"
                    value={partnerWebsite}
                    onChange={(e) => setPartnerWebsite(e.target.value)}
                  />
                </p>
                <p>
                  Logo:
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setPartnerFile(e.target.files[0])}
                  />
                </p>
                <br />
                <button onClick={handleSaveEdit} disabled={saving}>
                  {saving ? "Saving..." : "Save Changes"}
                </button>{" "}
                <button onClick={resetEditForm}>Cancel</button>
              </div>
            )}

            {/* Adding new partner */}
            {!editPartnerId && (
              <div className="add-partner-form">
                <h4>Add New Partner</h4>
                <p>
                  Name:{" "}
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </p>
                <p>
                  Website:{" "}
                  <input
                    type="text"
                    value={newWebsite}
                    onChange={(e) => setNewWebsite(e.target.value)}
                  />
                </p>
                <p>
                  Logo:{" "}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setNewFile(e.target.files[0])}
                  />
                </p>
                <br />
                <button onClick={handleAddPartner} disabled={saving}>
                  {saving ? "Saving..." : "Add Partner"}
                </button>{" "}
                <button onClick={resetNewPartnerForm}>Reset</button>
              </div>
            )}

            <button
              className="close-popup-btn"
              onClick={() => setShowEditPopup(false)}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Partners;
